<template>
  <!-- page-wrapper Start-->
  <div class="page-wrapper">
    <div class="container-fluid">
      <!-- sign up page start-->
      <div class="authentication-main">
        <div class="row">
          <div class="col-sm-12 p-0">
            <div class="auth-innerright">
              <div class="authentication-box rehber-basvuru-box">
                <div class="text-center">
                  <img alt="" src="../assets/images/logo.png" />
                </div>
                <div class="card mt-4 p-4">
                  <h4 class="text-center">ARAÇ KAYIT</h4>
                  <h6 class="text-center">
                    Araç başvurunuzu buradan yapabilirsiniz
                  </h6>
                  <div class="alert alert-danger" v-if="arac != null">
                    <span v-html="arac.BasvuruGeriBildirim"></span>
                  </div>
                  <b-form @submit="onSubmit" class="theme-form">
                    <div class="form-group">
                      <label class="col-form-label">Ad Soyad</label>
                      <b-form-input
                        type="text"
                        v-model="kayit.SoforAdi"
                        :state="kayit_soforadi_state"
                        placeholder="Ad Soyad"
                      ></b-form-input>
                      <b-form-invalid-feedback :state="kayit_soforadi_state"
                        >Adınız ve soyadınızı giriniz</b-form-invalid-feedback
                      >
                    </div>
                    <div class="form-row">
                      <div class="col-md-6">
                        <div class="form-group">
                          <label class="col-form-label">Cep Telefonu</label>
                          <b-form-input
                            type="text"
                            id="kayit_email"
                            v-model="kayit.GSM"
                            v-mask="'5## ### ## ##'"
                            placeholder="Cep Telefonu"
                            :state="kayit_gsm_state"
                          ></b-form-input>
                          <b-form-invalid-feedback :state="kayit_gsm_state"
                            >Cep telefonunu giriniz</b-form-invalid-feedback
                          >
                        </div>
                      </div>
                      <div class="col-md-6">
                        <label class="col-form-label">Email</label>
                        <b-form-input
                          type="text"
                          id="kayit_email"
                          v-model="kayit.Email"
                          placeholder="Email"
                          :state="kayit_email_state"
                        ></b-form-input>
                        <b-form-invalid-feedback :state="kayit_email_state"
                          >Email adresini giriniz</b-form-invalid-feedback
                        >
                      </div>
                    </div>
                    <div class="form-row">
                      <div class="col-md-6">
                        <label class="col-form-label">T.C. Kimlik No</label>
                        <b-form-input
                          type="text"
                          id="kayit_tckimlikno"
                          v-model="kayit.TcKimlikNo"
                          v-mask="'###########'"
                          placeholder="T.C. Kimlik Numaranız"
                          :state="kayit_tckimlikno_state"
                        ></b-form-input>
                        <b-form-invalid-feedback :state="kayit_tckimlikno_state"
                          >11 haneli T.C. kimlik numaranızı
                          giriniz.</b-form-invalid-feedback
                        >
                      </div>
                      <div class="col-md-6">
                        <label class="col-form-label">Plaka</label>
                        <b-form-input
                          type="text"
                          id="kayit_plaka"
                          v-model="kayit.Plaka"
                          placeholder="Plaka"
                          :state="kayit_plaka_state"
                        ></b-form-input>
                        <b-form-invalid-feedback :state="kayit_plaka_state"
                          >Araç plakasını giriniz.</b-form-invalid-feedback
                        >
                      </div>
                    </div>
                    <div class="form-row">
                      <div class="col-md-6">
                        <div class="form-group">
                          <label class="col-form-label">Marka</label>
                          <b-form-input
                            type="text"
                            id="kayit_marka"
                            v-model="kayit.Marka"
                            placeholder="Araç Markası"
                            :state="kayit_marka_state"
                          ></b-form-input>
                          <b-form-invalid-feedback :state="kayit_marka_state"
                            >Araç markasını giriniz</b-form-invalid-feedback
                          >
                        </div>
                      </div>
                      <div class="col-md-6">
                        <label class="col-form-label">Model</label>
                        <b-form-input
                          type="text"
                          id="kayit_model"
                          v-model="kayit.Model"
                          placeholder="Model"
                          :state="kayit_model_state"
                        ></b-form-input>
                        <b-form-invalid-feedback :state="kayit_model_state"
                          >Araç modelini giriniz</b-form-invalid-feedback
                        >
                      </div>
                    </div>
                    <div class="form-row">
                      <div class="col-md-6">
                        <label></label>
                        <div class="checkbox checkbox-dark">
                          <b-form-checkbox
                            name="isWifi"
                            v-model="kayit.isWifi"
                            :state="kayit.isWifi"
                            >Wi-fi bağlantısı</b-form-checkbox
                          >
                        </div>
                      </div>
                      <div class="col-md-6">
                        <label></label>
                        <div class="checkbox checkbox-dark">
                          <b-form-checkbox
                            name="isAracTakip"
                            v-model="kayit.isAracTakip"
                            :state="kayit.isAracTakip"
                            >Araç takip sistemim var</b-form-checkbox
                          >
                        </div>
                      </div>
                    </div>
                    <div class="form-row">
                      <div class="col-md-6">
                        <div class="form-group">
                          <label class="col-form-label"
                            >Yetki Belgesi Türü</label
                          >
                          <b-form-input
                            type="text"
                            id="kayit_yetkibelgesituru"
                            v-model="kayit.YetkiBelgesiTuru"
                            placeholder="Yetki Belge Türü"
                            :state="kayit_yetkibelgesituru_state"
                          ></b-form-input>
                          <b-form-invalid-feedback
                            :state="kayit_yetkibelgesituru_state"
                            >Yetki belgenizin türünü
                            giriniz</b-form-invalid-feedback
                          >
                        </div>
                      </div>
                      <div class="col-md-6">
                        <label class="col-form-label">Yetki Belgesi No</label>
                        <b-form-input
                          type="text"
                          id="kayit_yetkibelgesino"
                          v-model="kayit.YetkiBelgesiNo"
                          placeholder="Yetki Belge No"
                          :state="kayit_yetkibelgesino_state"
                        ></b-form-input>
                        <b-form-invalid-feedback
                          :state="kayit_yetkibelgesino_state"
                          >Yetki belgenizin numarasını
                          giriniz</b-form-invalid-feedback
                        >
                      </div>
                    </div>
                    <div class="form-group">
                      <label class="col-form-label">Profil Resminiz</label>
                      <AracResimCrop @onChange="onResimFlChange" />
                    </div>
                    <div class="form-group">
                      <label class="col-form-label">1. Araç Resminiz</label>
                      <AracResimCrop @onChange="onResimFl2Change" />
                    </div>
                    <div class="form-group">
                      <label class="col-form-label">2. Araç Resminiz</label>
                      <AracResimCrop @onChange="onResimFl3Change" />
                    </div>
                    <div class="form-group">
                      <label class="col-form-label">3. Araç Resminiz</label>
                      <AracResimCrop @onChange="onResimFl4Change" />
                    </div>
                    <div class="form-group" v-if="arac == null">
                      <label class="col-form-label">Kullanıcı Adı</label>
                      <b-form-input
                        type="text"
                        id="kayit_kullaniciadi"
                        v-model="kayit.KullaniciAdi"
                        placeholder="Kullanıcı Adı"
                        :state="kayit_kullaniciadi_state"
                      ></b-form-input>
                      <b-form-invalid-feedback :state="kayit_kullaniciadi_state"
                        >Ana mekan kullanıcısının kullanıcı adını
                        giriniz</b-form-invalid-feedback
                      >
                    </div>
                    <div class="form-group" v-if="arac == null">
                      <label class="col-form-label">Şifre</label>
                      <b-form-input
                        type="text"
                        id="kayit_sifre"
                        v-model="kayit.Sifre"
                        placeholder="Şifre"
                        :state="kayit_sifre_state"
                      ></b-form-input>
                      <b-form-invalid-feedback :state="kayit_sifre_state"
                        >8 ile 20 karakter arasında rakam, küçük ve büyük harf
                        bulunan bir şifre giriniz</b-form-invalid-feedback
                      >
                    </div>
                    <div class="form-row">
                      <div class="col-sm-12 text-center mb-3">
                        <b-button variant="primary" type="submit">{{
                          arac == null ? "Kayıt Ol" : "Güncelle"
                        }}</b-button>
                      </div>
                    </div>
                    <div class="form-row">
                      <div class="col-sm-12 text-center">
                        <router-link
                          :to="'/giris'"
                          class="btn-link text-capitalize"
                          >Giriş Yap</router-link
                        >
                      </div>
                    </div>
                  </b-form>

                  <b-modal
                    id="sozlesme"
                    size="lg"
                    @ok="sozlesmeSubmit"
                    centered
                    title="Hizmet Sözleşmesi"
                    ok-title="Okudum Onaylıyorum"
                    cancel-title="Kapat"
                    class="theme-modal"
                  >
                    <div class="row">
                      <div class="col-md-12">
                        <span v-html="sozlesmeMetni"></span>
                      </div>
                    </div>
                  </b-modal>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- sign up page ends-->
    </div>
  </div>
  <!-- page-wrapper Ends-->
</template>

<script>
import AracResimCrop from "@/components/c_arac_resim_crop.vue";

export default {
  data() {
    return {
      id: this.$route.query.id,

      arac: null,

      kayit: {
        Guncelleme: false,
        SoforAdi: "",
        Email: "",
        GSM: "",
        TcKimlikNo: "",
        Plaka: "",
        Marka: "",
        Model: "",
        isWifi: false,
        isAracTakip: false,
        YetkiBelgesiTuru: "",
        YetkiBelgesiNo: "",
        ResimFl: null,
        Resim2Fl: null,
        Resim3Fl: null,
        Resim4Fl: null,

        KullaniciAdi: "",
        Sifre: "",
      },

      canvas: null,
      canvas2: null,
      canvas3: null,
      canvas4: null,

      sozlesmeMetni: "",
    };
  },
  mounted() {
    this.kayitGetir();
  },
  components: {
    AracResimCrop,
  },
  computed: {
    kayit_soforadi_state() {
      if (this.kayit.SoforAdi == null || this.kayit.SoforAdi == "")
        return false;
      else return true;
    },
    kayit_resim_state() {
      if (this.canvas == null) return false;
      else return true;
    },
    kayit_gsm_state() {
      if (!this.$validationHelper.validGsm(this.kayit.GSM)) return false;
      else return true;
    },
    kayit_email_state() {
      if (!this.$validationHelper.validEmail(this.kayit.Email)) return false;
      else return true;
    },
    kayit_tckimlikno_state() {
      if (
        this.kayit.TcKimlikNo == null ||
        this.kayit.TcKimlikNo == "" ||
        this.kayit.TcKimlikNo.length !== 11
      )
        return false;
      else return true;
    },
    kayit_plaka_state() {
      if (this.kayit.Plaka == null || this.kayit.Plaka == "") return false;
      else return true;
    },
    kayit_marka_state() {
      if (this.kayit.Marka == null || this.kayit.Marka == "") return false;
      else return true;
    },
    kayit_model_state() {
      if (this.kayit.Model == null || this.kayit.Model == "") return false;
      else return true;
    },
    kayit_yetkibelgesituru_state() {
      if (
        this.kayit.YetkiBelgesiTuru == null ||
        this.kayit.YetkiBelgesiTuru == ""
      )
        return false;
      else return true;
    },
    kayit_yetkibelgesino_state() {
      if (this.kayit.YetkiBelgesiNo == null || this.kayit.YetkiBelgesiNo == "")
        return false;
      else return true;
    },
    kayit_kullaniciadi_state() {
      if (
        this.id === undefined &&
        (this.kayit.KullaniciAdi === null || this.kayit.KullaniciAdi === "")
      )
        return false;
      else return true;
    },
    kayit_sifre_state() {
      if (
        this.id === undefined &&
        !this.$validationHelper.validPassword(this.kayit.Sifre)
      )
        return false;
      else return true;
    },
  },
  methods: {
    kayitGetir() {
      if (this.id !== undefined) {
        //Kayıt var formu doldur
        let vm = this;

        this.$connection.get("/araclar/" + this.id, function (response) {
          if (response.Success) {
            vm.arac = response.Data;

            vm.kayit = {
              SoforAdi: response.Data.SoforAdi,
              Email: response.Data.Email,
              GSM: response.Data.GSM,
              TcKimlikNo: response.Data.TcKimlikNo,
              Plaka: response.Data.Plaka,
              Marka: response.Data.Marka,
              Model: response.Data.Model,
              isWifi: response.Data.isWifi,
              isAracTakip: response.Data.isAracTakip,
              YetkiBelgesiTuru: response.Data.YetkiBelgesiTuru,
              YetkiBelgesiNo: response.Data.YetkiBelgesiNo,
            };
          } else {
            vm.$alert.messageWithTitle("Araç", response.Message, "error");
          }
        });
      }
    },
    onResimFlChange(canvas) {
      this.canvas = canvas;
    },
    onResimFl2Change(canvas) {
      this.canvas2 = canvas;
    },
    onResimFl3Change(canvas) {
      this.canvas3 = canvas;
    },
    onResimFl4Change(canvas) {
      this.canvas4 = canvas;
    },
    onSubmit(evt) {
      evt.preventDefault();

      let isValid =
        this.kayit_soforadi_state &&
        this.kayit_resim_state &&
        this.kayit_gsm_state &&
        this.kayit_email_state &&
        this.kayit_tckimlikno_state &&
        this.kayit_plaka_state &&
        this.kayit_marka_state &&
        this.kayit_model_state &&
        this.kayit_yetkibelgesituru_state &&
        this.kayit_yetkibelgesino_state &&
        this.kayit_kullaniciadi_state &&
        this.kayit_sifre_state;

      if (isValid) {
        let vm = this;

        this.$connection.get(
          "/parametreler/AracSozlesmesi",
          function (response) {
            vm.sozlesmeMetni = response;
            vm.$bvModal.show("sozlesme");
          }
        );
      }
    },
    sozlesmeSubmit(event) {
      event.preventDefault();

      this.onKaydet();
    },
    onKaydet() {
      let isValid =
        this.kayit_soforadi_state &&
        this.kayit_resim_state &&
        this.kayit_gsm_state &&
        this.kayit_email_state &&
        this.kayit_tckimlikno_state &&
        this.kayit_plaka_state &&
        this.kayit_marka_state &&
        this.kayit_model_state &&
        this.kayit_yetkibelgesituru_state &&
        this.kayit_yetkibelgesino_state &&
        this.kayit_kullaniciadi_state &&
        this.kayit_sifre_state;

      if (isValid) {
        let vm = this;

        if (this.canvas) {
          this.canvas.toBlob((blob) => {
            vm.kayit.ResimFl = blob;

            this.canvas2.toBlob((blob) => {
              vm.kayit.Resim2Fl = blob;

              this.canvas3.toBlob((blob) => {
                vm.kayit.Resim3Fl = blob;

                this.canvas4.toBlob((blob) => {
                  vm.kayit.Resim4Fl = blob;

                  let url = "/auth/aracBasvur";
                  let method = "post";
                  let messageTitle = "Araç Başvurusu";
                  if (vm.arac != null) {
                    url = "/auth/AracBasvuruGuncelle/" + vm.arac.Id;
                    vm.kayit.Guncelleme = true;
                  }

                  vm.$connection.postData(
                    url,
                    vm.kayit,
                    method,
                    function (response) {
                      if (response.Success) {
                        vm.$alert.messageWithFunction(
                          messageTitle,
                          "Araç başvurunuz alındı onaylandığında bilgilendireceğiz.",
                          "success",
                          function () {
                            vm.$router.push("/giris");
                          }
                        );
                      }
                    },
                    function (error) {
                      vm.$alert.messageWithTitle(
                        messageTitle,
                        error.Message,
                        "error"
                      );
                    }
                  );
                }, "image/jpeg");
              }, "image/jpeg");
            }, "image/jpeg");
          }, "image/jpeg");
        }
      }
    },
  },
};
</script>
<style>
.rehber-basvuru-box {
  width: 50% !important;
}

@media only screen and (max-width: 991px) {
  .rehber-basvuru-box {
    width: 100% !important;
  }
}
</style>
