import Vue from 'vue'
import App from './App.vue'
import BootstrapVue from 'bootstrap-vue'
import router from './router'
import Breadcrumbs from './components/bread_crumbs'
import { store } from './store';
import Vue2Filters from 'vue2-filters'
import VueSweetalert2 from 'vue-sweetalert2';
import VueFormWizard from 'vue-form-wizard';
import VueTour from 'vue-tour'
import Notifications from 'vue-notification'
import { Vue2Dragula } from 'vue2-dragula'
import Toasted from 'vue-toasted';
import SmartTable from 'vuejs-smart-table'
import * as VueGoogleMaps from 'vue2-google-maps'
import { VueMasonryPlugin } from 'vue-masonry'
import VueFeather from 'vue-feather';
import VueApexCharts from 'vue-apexcharts';
import VCalendar from 'v-calendar';
import VueTheMask from 'vue-the-mask';
import CKEditor from '@ckeditor/ckeditor5-vue2';

import { Icon } from "leaflet";
delete Icon.Default.prototype._getIconUrl;

// Import Theme scss
import './assets/scss/app.scss'

import connection from "./helpers/connections"
import user from "./helpers/user"
import alert from "./helpers/alert"
import listHelper from "./helpers/listHelper"
import formatHelper from "./helpers/formatHelper"
import validationHelper from "./helpers/validationHelper"

import cAktifCell from './components/c_aktif_cell'
import cTarihCell from './components/c_tarih_cell'
import cMoneyCell from './components/c_money_cell'
import cTable from './components/c_table'
import cMisafirler from './components/c_misafirler'

const pluginHelpers = {
  install() {
    Vue.connection = connection;
    Vue.prototype.$connection = connection;
    Vue.user = user;
    Vue.prototype.$user = user;
    Vue.alert = alert;
    Vue.prototype.$alert = alert;
    Vue.listHelper = listHelper;
    Vue.prototype.$listHelper = listHelper;
    Vue.formatHelper = formatHelper;
    Vue.prototype.$formatHelper = formatHelper;
    Vue.validationHelper = validationHelper;
    Vue.prototype.$validationHelper = validationHelper;
  }
}

Icon.Default.mergeOptions({
  iconRetinaUrl: require("./assets/images/vue2leaflet/marker-icon.png"),
  iconUrl: require("./assets/images/vue2leaflet/marker-icon.png")
});

Vue.use(VueFeather);

Vue.use(Toasted, {
  iconPack: 'fontawesome'
});
Vue.use(Vue2Dragula);

Vue.use(VueGoogleMaps, {
  load: {
    key: 'API_KEY',
    libraries: 'places',
  },
})

Vue.component("CAktifCell", cAktifCell);
Vue.component("CTarihCell", cTarihCell);
Vue.component("CMoneyCell", cMoneyCell);
Vue.component("CTable", cTable);
Vue.component("CMisafirler", cMisafirler);

Vue.use(VueTheMask)
Vue.use(Notifications)
Vue.use(Vue2Filters)
Vue.use(VueSweetalert2);
Vue.use(VueFormWizard)
Vue.use(VueTour)
Vue.use(BootstrapVue)
Vue.use(SmartTable)
Vue.use(require('vue-chartist'))
Vue.use(require('vue-moment'));
Vue.component('Breadcrumbs', Breadcrumbs)
Vue.use(VueMasonryPlugin)
Vue.component('apexchart', VueApexCharts);
Vue.use(pluginHelpers)
Vue.use(CKEditor);

Vue.use(VCalendar, {
  componentPrefix: 'vc',  // Use <vc-calendar /> instead of <v-calendar />
  screens: "lg"
});

Vue.config.productionTip = false



new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')