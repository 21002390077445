<template>
  <div>
    <Breadcrumbs main="Yeni Tur Rezervasyonu" :title="pageTitle" />

    <div class="container-fluid tur-rezervasyon-duzelt">
      <TurRezervasyonForm
        @onComplete="onComplete"
        :acenteId="id"
      ></TurRezervasyonForm>
    </div>
  </div>
</template>
<script>
import TurRezervasyonForm from "@/components/c_tur_rezervasyon_form.vue";

export default {
  data() {
    return {
      id: this.$route.params.id,

      pageTitle: "Yeni Rezervasyon",

      kayit: {},

      isComponentShow: false,
    };
  },
  mounted() {
    this.$store.dispatch("menu/setToggleSidebar", true);
  },
  components: {
    TurRezervasyonForm,
  },
  methods: {
    onComplete(kayit) {
      let vm = this;

      let url = "/turRezervasyonlari/" + this.id;
      let method = "post";
      let messageTitle = "Yeni Rezervasyon";

      vm.$connection.post(
        url,
        kayit,
        method,
        function (response) {
          if (response.Success) {
            vm.$alert.messageWithFunction(
              messageTitle,
              response.Message,
              "success",
              function () {
                vm.$router.push("/turRezervasyon/detay/" + response.Data);
              }
            );
          }
        },
        function (error) {
          vm.$alert.messageWithTitle(messageTitle, error.Message, "error");
        }
      );
    },
  },
};
</script>
