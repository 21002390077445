<template>
  <div>
    <Breadcrumbs main="İçerikler" :title="pageTitle" :links="breadLinks" />

    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <h5>İçerik Kaydı</h5>
              <span
                >Yeni içerik veya içerik kaydında değişiklikler için aşağıdaki
                formu kullanabilirsiniz</span
              >
            </div>
            <div class="card-body">
              <b-form @submit="onSubmit">
                <div class="form-row">
                  <div class="col-md-12 mb-3">
                    <label for="kayit_baslik">Başlık</label>
                    <b-form-input
                      type="text"
                      id="kayit_baslik"
                      v-model="kayit.Baslik"
                      :state="kayit_baslik_state"
                      placeholder="Başlık"
                    ></b-form-input>
                    <b-form-invalid-feedback :state="kayit_baslik_state"
                      >İçerik başlığını giriniz</b-form-invalid-feedback
                    >
                  </div>
                </div>
                <div class="form-row">
                  <div class="col-md-12 mb-3">
                    <label for="kayit_ozet">Özet</label>
                    <ckeditor :editor="editor" v-model="kayit.Ozet"></ckeditor>
                  </div>
                </div>
                <div class="form-row">
                  <div class="col-md-12 mb-3">
                    <label for="kayit_detay">Detay</label>
                    <ckeditor
                      :editor="editor"
                      v-model="kayit.Detay"
                      :state="kayit_detay_state"
                    ></ckeditor>
                    <b-form-invalid-feedback :state="kayit_detay_state"
                      >İçerik detayını giriniz</b-form-invalid-feedback
                    >
                  </div>
                </div>
                <div class="form-row">
                  <div class="col-md-6 mb-3">
                    <label for="kayit_url">Url</label>
                    <b-form-input
                      type="text"
                      id="kayit_url"
                      v-model="kayit.Url"
                      :state="kayit_url_state"
                      placeholder="Url"
                    ></b-form-input>
                    <b-form-invalid-feedback :state="kayit_url_state"
                      >İçerik linkini giriniz</b-form-invalid-feedback
                    >
                  </div>
                  <div class="col-md-6 mb-3">
                    <label></label>
                    <div class="checkbox checkbox-dark">
                      <b-form-checkbox
                        name="Aktif"
                        v-model="kayit.Aktif"
                        :state="kayit.Aktif"
                        >Aktif</b-form-checkbox
                      >
                    </div>
                  </div>
                </div>
                <router-link
                  :to="'/icerik/list'"
                  class="btn btn-light pull-left"
                  >Geri Dön</router-link
                >
                <b-button type="submit" class="pull-right" variant="primary"
                  >Kaydet</b-button
                >
              </b-form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

export default {
  data() {
    return {
      id: this.$route.params.id,

      pageTitle: "Yeni İçerik",

      breadLinks: [
        { path: "/icerik/list", title: "İçerikler", icon: "chevron-left" },
      ],

      kayit: {
        Baslik: "",
        Ozet: "",
        Detay: "",
        Url: "",
        Aktif: true,
      },

      editor: ClassicEditor,
    };
  },
  components: {},
  mounted() {
    let vm = this;

    if (this.id !== undefined) {
      vm.pageTitle = "İçerik Düzelt";

      this.$connection.get("/icerikler/" + this.id, function (response) {
        if (response.Success) {
          vm.kayit = response.Data;
        } else {
          vm.$alert.messageWithTitle("Yeni İçerik", response.Message, "error");
        }
      });
    }
  },
  computed: {
    kayit_baslik_state() {
      if (this.kayit.Baslik == null || this.kayit.Baslik == "") return false;
      else return true;
    },
    kayit_detay_state() {
      if (this.kayit.Detay === "" || this.kayit.Detay === null) return false;
      else return true;
    },
    kayit_url_state() {
      if (this.kayit.Url === "" || this.kayit.Url === null) return false;
      else return true;
    },
  },
  methods: {
    onSubmit(evt) {
      evt.preventDefault();

      let isValid =
        this.kayit_baslik_state &&
        this.kayit_detay_state &&
        this.kayit_url_state;

      if (isValid) {
        let vm = this;

        let url = "/icerikler";
        let method = "post";
        let messageTitle = "Yeni İçerik";
        let yeniKayit = vm.id === undefined;
        if (!yeniKayit) {
          url += "/" + vm.id;
          method = "put";
          messageTitle = "İçerik Düzelt";
        }

        vm.kayit.KullaniciTipleriList = vm.selectedKullaniciTipleri;

        vm.$connection.post(
          url,
          vm.kayit,
          method,
          function (response) {
            if (response.Success) {
              vm.$alert.messageWithFunction(
                messageTitle,
                response.Message,
                "success",
                function () {
                  vm.$router.push("/icerik/list");
                }
              );
            }
          },
          function (error) {
            vm.$alert.messageWithTitle(messageTitle, error.Message, "error");
          }
        );
      }
    },
  },
};
</script>
