<template>
  <div>
    <Breadcrumbs main="Araçlar" title="Onay Bekleyenler" :links="breadLinks" />

    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-body">
              <c-table :tablefields="tablefields" :items="items">
                <template #cell(SoforAdi)="row">
                  <div>
                    {{ row.item.SoforAdi }} / {{ row.item.Plaka }}<br />
                  </div>
                  <b-btn-group>
                    <router-link
                      :to="`/aracFirma/aracDetay/${row.item.Id}`"
                      class="btn btn-dark btn-xs"
                      >Detay</router-link
                    >
                    <router-link
                      :to="`/aracFirma/aracKayit/${row.item.AracFirmaId}/${row.item.Id}`"
                      class="btn btn-info btn-xs"
                      >Düzelt</router-link
                    >
                    <b-button
                      size="xs"
                      @click="onayla(row.item)"
                      variant="success"
                      >Onayla</b-button
                    >
                    <b-button
                      size="xs"
                      @click="iptalEt(row.item)"
                      variant="warning"
                      >İptal Et</b-button
                    >
                    <b-button size="xs" @click="sil(row.item)" variant="danger"
                      >Sil</b-button
                    >
                  </b-btn-group>
                </template>
                <template #cell(ToplamAlacak)="data">
                  <c-money-cell :fiyat="data.value" simge="TL"></c-money-cell>
                </template>
                <template #cell(KayitTarihi)="row">
                  <c-tarih-cell
                    :kayitTarihi="row.item.KayitTarihi"
                    :guncelTarih="row.item.GuncelTarih"
                    :rowKey="row.item.Id"
                  ></c-tarih-cell>
                </template>
              </c-table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      breadLinks: [],
      tablefields: [
        { key: "SoforAdi", label: "Şoför / Plaka", sortable: true },
        { key: "GSM", label: "GSM", sortable: true, thClass: "sabitKol" },
        { key: "Email", label: "Email", sortable: true, thClass: "sabitKol" },
        { key: "Marka", label: "Marka", sortable: true },
        { key: "Model", label: "Model", sortable: true },
        { key: "ToplamAlacak", label: "Bakiye", sortable: true },
        { key: "DurumStr", label: "Durum", sortable: true },
        { key: "KullaniciAdi", label: "Kullanıcı", sortable: true },
        { key: "KayitTarihi", label: "Kayıt Tarihi", sortable: true },
      ],

      items: [],
    };
  },
  mounted() {
    this.onList();
  },
  methods: {
    onList() {
      let vm = this;

      this.$connection.get("/araclar/onayBekleyenler", function (response) {
        vm.items = response;
      });
    },
    sil(item) {
      let vm = this;
      this.$alert.confirm(
        "Sil",
        "Aracı silmek istediğinize emin misiniz?",
        "warning",
        function () {
          vm.$connection.delete("/araclar/" + item.Id, function (response) {
            vm.$alert.messageWithFunction(
              "Silindi",
              "Araç kaydı silindi.",
              "error",
              function () {
                vm.$listHelper.removeItem(vm.items, item);
              }
            );
          });
        }
      );
    },
    onayla(item) {
      let vm = this;
      this.$alert.confirm(
        "Onayla",
        "Aracı onaylamak istediğinize emin misiniz?",
        "warning",
        function () {
          vm.$connection.post(
            "/araclar/durumDegistir/" + item.Id + "/30",
            {},
            "put",
            function (response) {
              if (response.Success) {
                vm.$alert.messageWithFunction(
                  "Onaylandı",
                  "Araç onaylandı.",
                  "success",
                  function () {
                    vm.$listHelper.removeItem(vm.items, item);
                  }
                );
              }
            }
          );
        }
      );
    },
    iptalEt(item) {
      let vm = this;
      this.$alert.confirm(
        "İptal Et",
        "Aracı aynı bilgilerle bir daha üye olamayacak. İptal etmek istedğinize emin misiniz?",
        "warning",
        function () {
          vm.$connection.post(
            "/araclar/durumDegistir/" + item.Id + "/100",
            {},
            "put",
            function (response) {
              if (response.Success) {
                vm.$alert.messageWithFunction(
                  "İptal Edildi",
                  "Araç iptal edildi.",
                  "success",
                  function () {
                    vm.$listHelper.removeItem(vm.items, item);
                  }
                );
              }
            }
          );
        }
      );
    },
  },
};
</script>
