<template>
    <div class="card">
        <div class="card-header b-l-primary">
            <div class="media">
                <div class="media-body">
                    <h5 class="mb-2 mt-2 f-w-700">Tur Rezervasyonlarınız</h5>
                </div>
                <div class="pull-right mt-0">
                    <b-form @submit="onFiltreSubmit">
                        <b-input-group prepend="Tarih">
                            <template #prepend>
                                <b-button @click="gun(-1)" variant="light"><i class="fa fa-angle-left"></i></b-button>
                                <b-button @click="gun(+1)" variant="light"><i class="fa fa-angle-right"></i></b-button>
                            </template>
                            <datepicker input-class="datepicker-here form-control digits" v-model="filtre.Tarih"
                                :language="tr" placeholder="Başlangıç Tarihi" :format="date_format">
                            </datepicker>
                            <b-input-group-append>
                                <b-button type="submit" variant="outline-success">Listele</b-button>
                            </b-input-group-append>
                        </b-input-group>
                    </b-form>
                </div>
            </div>
        </div>
        <div class="card-body">
            <div class="table-responsive active-order-table mb-0">

                <table class="table table-bordernone table-striped">
                    <thead>
                        <tr>
                            <th scope="col">İşlem</th>
                            <th scope="col">Kod</th>
                            <th scope="col">Durum</th>
                            <th scope="col">Tarih</th>
                            <th scope="col">Otel </th>
                            <th scope="col">Misafirler</th>
                            <th scope="col">Tur / Opsiyon</th>
                            <th scope="col">Rehber</th>
                            <th scope="col">Şoför</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="item in items" :key="item.Id">
                            <td>
                                <b-btn-group>
                                    <router-link :to="`/turRezervasyon/detay/${item.Id}`"
                                        class="btn btn-info btn-xs">Detay</router-link>
                                </b-btn-group>
                            </td>
                            <td>
                                <b-badge variant="info">{{ item.Kod }}</b-badge>
                            </td>
                            <td>
                                <RezervasyonDurum :durum="item.DurumStr" :durumInt="item.Durum" />
                            </td>
                            <td>
                                <c-tarih-cell :kayitTarihi="item.Tarih" :rowKey="item.Id"></c-tarih-cell>
                                <b-badge variant="primary">{{ item.Saat }}</b-badge>
                            </td>
                            <td>{{ item.OtelAdi }}</td>
                            <td>
                                <c-misafirler :misafirler="item.Misafirler"></c-misafirler>
                                <b-badge variant="warning">{{ item.KisiSayisi }}</b-badge>
                            </td>
                            <td :class="item.TurRenk + ' sabitKol'">
                                <div>{{ item.TurAdi }} / <small>{{ item.TurOpsiyonAdi }}</small></div>
                                <a :href="'/reservation/tour/' + item.Id" class="btn btn-info btn-xs" target="_blank">Rezervasyon Kartı</a>
                            </td>
                            <td>
                                <div class="customers">
                                    <ul class="mb-1">
                                        <li class="d-inline-block">
                                            <a href="#" :id="'rehber-tooltip-' + item.Id">
                                                <img class="img-40 rounded-circle" :src="item.RehberResim" alt="">
                                            </a>
                                        </li>
                                    </ul>
                                    {{ item.RehberAdi }}
                                </div>
                                <b-tooltip :target="'rehber-tooltip-' + item.Id" triggers="hover" placement="right">
                                    <img class="img-fluid" :src="item.RehberResim" alt="">
                                </b-tooltip>
                            </td>
                            <td>
                                <div class="customers">
                                    <ul class="mb-1">
                                        <li class="d-inline-block">
                                            <a href="#" :id="'arac-tooltip-' + item.Id" v-b-tooltip.hover>
                                                <img class="img-40 rounded-circle" :src="item.AracResim" alt="">
                                            </a>
                                        </li>
                                    </ul>
                                    {{ item.SoforAdi }}<br>
                                    {{ item.Plaka }}
                                </div>
                                <b-tooltip :target="'arac-tooltip-' + item.Id" triggers="hover" placement="left">
                                    <img class="img-fluid" :src="item.AracResim" alt="">
                                </b-tooltip>
                            </td>
                        </tr>

                    </tbody>
                </table>

            </div>

        </div>
    </div>
</template>
<script>
import Datepicker from 'vuejs-datepicker';
import { tr } from 'vuejs-datepicker/dist/locale'
import RezervasyonDurum from "./c_turRezervasyon_durum";

export default {
    props: {
    },
    data() {
        return {
            items: [],

            date_format: "dd.MM.yyyy",
            tr: tr,

            filtre: {
                Tarih: null
            }
        }
    },
    components: {
        Datepicker,
        RezervasyonDurum
    },
    mounted() {
        let bugun = new Date();
        this.filtre.Tarih = bugun;

        this.listele();
    },
    methods: {
        onFiltreSubmit(target) {
            target.preventDefault();
            this.listele();
        },
        gun(sayi) {
            this.filtre.Tarih = this.$formatHelper.addDay(new Date(this.filtre.Tarih), sayi);
            this.listele();
        },
        listele() {
            let vm = this;

            this.filtre.Tarih = this.$formatHelper.formatDateApi(this.filtre.Tarih);

            this.$connection.post("/turRezervasyonlari/filtreForAracFirma", vm.filtre, "post", function (response) {
                vm.items = response;
            });
        }
    }
}
</script>