import Vue from 'vue'
import Router from "vue-router";


import Body from '../components/body'
import auth from "../auth/auth"
import loginPage from "../auth/loginPage"
import SifremiUnuttum from "../auth/sifremiUnuttum"
import AcenteBasvur from '../auth/acenteBasvur'
import RehberBasvur from '../auth/rehberBasvur'
import AracBasvur from '../auth/aracBasvur'

import TurRezervasyonView from '../pages/reservation/tour'


/* Dashboards */
import Default from '../pages/dashboard/default'
import Acente from '../pages/dashboard/acente'
import Rehber from '../pages/dashboard/rehber'
import AracFirma from '../pages/dashboard/aracFirma'
import Arac from '../pages/dashboard/arac'

//Capbook
import TurList from '../pages/tur/list';
import TurKayit from '../pages/tur/kayit';
import Opsiyon from '../pages/tur/opsiyon';
import OpsiyonKayit from '../pages/tur/opsiyonKayit';

import MuzeList from '../pages/muze/list';
import MuzeShowroom from '../pages/muze/showroom';
import MuzeKayit from '../pages/muze/kayit';

import RestoranList from '../pages/restoran/list';
import RestoranKayit from '../pages/restoran/kayit';
import RestoranShowroom from '../pages/restoran/showroom';

import MekanList from '../pages/mekan/list';
import MekanKayit from '../pages/mekan/kayit';
import MekanShowroom from '../pages/mekan/showroom';

import KategoriList from '../pages/kategori/list';
import KategoriKayit from '../pages/kategori/kayit';

import AcenteList from '../pages/acente/list';
import AcenteKayit from '../pages/acente/kayit';
import AcenteDetay from '../pages/acente/detay';
import AcenteProfil from '../pages/acente/profil';
import AcenteFolyo from '../pages/acente/folyo';
import AcenteOnayBekleyenList from '../pages/acente/onayBekleyen';

import AracFirmaList from '../pages/aracFirma/list';
import AracFirmaKayit from '../pages/aracFirma/kayit';
import AracFirmaProfil from '../pages/aracFirma/profil';
import AracFirmaFolyo from '../pages/aracFirma/folyo';
import AracFirmaDetay from '../pages/aracFirma/aracFirmaDetay';
import AracList from '../pages/aracFirma/aracList';
import BagimsizAracList from '../pages/aracFirma/bagimsizAracList';
import AracOnayBekleyenList from '../pages/aracFirma/aracOnayBekleyen';
import AracKayit from '../pages/aracFirma/aracKayit';
import AracDetay from '../pages/aracFirma/aracDetay';
import AracResimOnay from '../pages/aracFirma/aracResimOnay';
import AracProfil from '../pages/aracFirma/aracProfil';
import AracFolyo from '../pages/aracFirma/aracFolyo';
import AracTakvim from '../pages/aracFirma/aracTakvim';

import AracTipiList from '../pages/aracTipi/list';
import AracTipiKayit from '../pages/aracTipi/kayit';

import BalonFirmaList from '../pages/balonFirma/list';
import BalonFirmaKayit from '../pages/balonFirma/kayit';

import BannerList from '../pages/banner/list';
import BannerKayit from '../pages/banner/kayit';

import DilList from '../pages/dil/list';
import DilKayit from '../pages/dil/kayit';

import DuyuruList from '../pages/duyuru/list';
import DuyuruKayit from '../pages/duyuru/kayit';

import IcerikList from '../pages/icerik/list';
import IcerikKayit from '../pages/icerik/kayit';

import MenuList from '../pages/menu/list';
import MenuKayit from '../pages/menu/kayit';

import RehberList from '../pages/rehber/list';
import RehberKayit from '../pages/rehber/kayit';
import RehberDetay from '../pages/rehber/detay';
import RehberProfil from '../pages/rehber/profil';
import RehberFolyo from '../pages/rehber/folyo';
import RehberOnayBekleyenList from '../pages/rehber/onayBekleyen';
import RehberTakvim from '../pages/rehber/takvim';

import TurRezervasyonList from '../pages/turRezervasyon/list';
import TurRezervasyonDetay from '../pages/turRezervasyon/detay';
import TurRezervasyonDuzelt from '../pages/turRezervasyon/duzelt';
import TurRezervasyonDuzeltArac from '../pages/turRezervasyon/duzeltArac';
import TurRezervasyonDuzeltRehber from '../pages/turRezervasyon/duzeltRehber';
import TurRezervasyonYeni from '../pages/turRezervasyon/yeni';

import BalonRezervasyonList from '../pages/balonRezervasyon/list';
import BalonRezervasyonDetay from '../pages/balonRezervasyon/detay';
import BalonRezervasyonDuzelt from '../pages/balonRezervasyon/duzelt';

import ParabirimiList from '../pages/paraBirimi/list';
import ParabirimiKayit from '../pages/paraBirimi/kayit';

import SehirList from '../pages/sehir/list';
import SehirKayit from '../pages/sehir/kayit';

import KullaniciTipiMenu from '../pages/kullaniciTipi/menu';

import KullaniciList from '../pages/kullanici/list';
import KullaniciKayit from '../pages/kullanici/kayit';

import ParametreGuncelle from '../pages/parametre/guncelle';

// component

Vue.use(Router)

const routes = [
  { path: '', redirect: { name: 'default' } },
  {
    path: '/giris',
    component: auth,
    children: [
      {
        path: '',
        component: loginPage,
        meta: {
          title: ' Giriş | Capbook',
        }
      },
      {
        path: 'sifremiUnuttum',
        component: SifremiUnuttum,
        meta: {
          title: ' Şifremi Unuttum | Capbook',
        }
      },
      {
        path: 'acenteBasvur',
        name: 'acenteBasvur',
        component: AcenteBasvur,
        meta: {
          title: ' Acente Başvuru | Capbook',
        }
      },
      {
        path: 'rehberBasvur',
        name: 'rehberBasvur',
        component: RehberBasvur,
        meta: {
          title: ' Rehber Başvuru | Capbook',
        }
      },
      {
        path: 'aracBasvur',
        name: 'aracBasvur',
        component: AracBasvur,
        meta: {
          title: ' Araç Başvuru | Capbook',
        }
      }
    ]
  },
  {
    path: '/reservation',
    component: auth,
    children: [
      {
        path: 'tour/:id',
        component: TurRezervasyonView,
        meta: {
          title: ' Tour Reservation'
        }
      }
    ]
  },

  {
    path: '/dashboard',
    component: Body,
    meta: {
      requiresAuth: true
    },
    children: [
      {
        path: 'default',
        name: 'default',
        component: Default,
        meta: {
          title: 'Kontrol Paneli',
        }
      },
      {
        path: 'acente',
        name: 'acenteDashboard',
        component: Acente,
        meta: {
          title: 'Kontrol Paneli',
        }
      },
      {
        path: 'rehber',
        name: 'rehberDashboard',
        component: Rehber,
        meta: {
          title: 'Kontrol Paneli',
        }
      },
      {
        path: 'aracFirma',
        name: 'aracFirmaDashboard',
        component: AracFirma,
        meta: {
          title: 'Kontrol Paneli',
        }
      },
      {
        path: 'arac',
        name: 'aracDashboard',
        component: Arac,
        meta: {
          title: 'Kontrol Paneli',
        }
      }
    ]
  },
  {
    path: '/tur',
    component: Body,
    meta: {
      requiresAuth: true
    },
    children: [
      {
        path: 'list',
        name: 'turList',
        component: TurList,
        meta: {
          title: 'Turlar'
        }
      },
      {
        path: 'kayit',
        name: 'turEkle',
        component: TurKayit,
        meta: {
          title: 'Yeni Tur - Turlar'
        }
      },
      {
        path: 'kayit/:id',
        name: 'turDuzelt',
        component: TurKayit,
        meta: {
          title: 'Düzelt - Turlar'
        }
      },
      {
        path: 'opsiyon/:id',
        name: 'opsiyonList',
        component: Opsiyon,
        meta: {
          title: 'Turlar Opsiyonları'
        }
      },
      {
        path: 'opsiyonKayit/:opsiyonId',
        name: 'opsiyonKayit',
        component: OpsiyonKayit,
        meta: {
          title: 'Tur Opsiyon Kaydet'
        }
      },
      {
        path: 'opsiyonKayit/:opsiyonId/:id',
        name: 'opsiyonDuzelt',
        component: OpsiyonKayit,
        meta: {
          title: 'Tur Opsiyon Düzelt'
        }
      }
    ]
  },
  {
    path: '/muze',
    component: Body,
    meta: {
      requiresAuth: true
    },
    children: [
      {
        path: 'list',
        name: 'muzeList',
        component: MuzeList,
        meta: {
          title: 'Müzeler'
        }
      },
      {
        path: 'showroom',
        name: 'muzeShowroom',
        component: MuzeShowroom,
        meta: {
          title: 'Müzeler'
        }
      },
      {
        path: 'kayit',
        name: 'muzeEkle',
        component: MuzeKayit,
        meta: {
          title: 'Yeni Müze - Müzeler'
        }
      },
      {
        path: 'kayit/:id',
        name: 'muzeDuzelt',
        component: MuzeKayit,
        meta: {
          title: 'Düzelt - Müzeler'
        }
      }
    ]
  },
  {
    path: '/restoran',
    component: Body,
    meta: {
      requiresAuth: true
    },
    children: [
      {
        path: 'list',
        name: 'restoranList',
        component: RestoranList,
        meta: {
          title: 'Restoranlar'
        }
      },
      {
        path: 'kayit',
        name: 'restoranEkle',
        component: RestoranKayit,
        meta: {
          title: 'Yeni Restoran - Restoranlar'
        }
      },
      {
        path: 'kayit/:id',
        name: 'restoranDuzelt',
        component: RestoranKayit,
        meta: {
          title: 'Düzelt - Restoranlar'
        }
      },
      {
        path: 'showroom',
        name: 'restoranShowroom',
        component: RestoranShowroom,
        meta: {
          title: 'Restoranlar'
        }
      }
    ]
  },
  {
    path: '/mekan',
    component: Body,
    meta: {
      requiresAuth: true
    },
    children: [
      {
        path: 'list',
        name: 'mekanList',
        component: MekanList,
        meta: {
          title: 'Mekanlar'
        }
      },
      {
        path: 'kayit',
        name: 'mekanEkle',
        component: MekanKayit,
        meta: {
          title: 'Yeni Mekan - Mekanlar'
        }
      },
      {
        path: 'kayit/:id',
        name: 'mekanDuzelt',
        component: MekanKayit,
        meta: {
          title: 'Düzelt - Mekanlar'
        }
      },
      {
        path: 'showroom',
        name: 'mekanShowroom',
        component: MekanShowroom,
        meta: {
          title: 'Mekanlar'
        }
      }
    ]
  },
  {
    path: '/kategori',
    component: Body,
    meta: {
      requiresAuth: true
    },
    children: [
      {
        path: 'list',
        name: 'kategoriList',
        component: KategoriList,
        meta: {
          title: 'Kategoriler'
        }
      },
      {
        path: 'kayit',
        name: 'kategoriEkle',
        component: KategoriKayit,
        meta: {
          title: 'Yeni Kategori - Kategoriler'
        }
      },
      {
        path: 'kayit/:id',
        name: 'kategoriDuzelt',
        component: KategoriKayit,
        meta: {
          title: 'Düzelt - Kategoriler'
        }
      }
    ]
  },
  {
    path: '/acente',
    component: Body,
    meta: {
      requiresAuth: true
    },
    children: [
      {
        path: 'list',
        name: 'acenteList',
        component: AcenteList,
        meta: {
          title: 'Acenteler'
        }
      },
      {
        path: 'onayBekleyen',
        name: 'acenteOnayBekleyenList',
        component: AcenteOnayBekleyenList,
        meta: {
          title: 'Onay Bekleyen Acenteler'
        }
      },
      {
        path: 'kayit',
        name: 'acenteEkle',
        component: AcenteKayit,
        meta: {
          title: 'Yeni Acente - Acenteler'
        }
      },
      {
        path: 'kayit/:id',
        name: 'acenteDuzelt',
        component: AcenteKayit,
        meta: {
          title: 'Düzelt - Acenteler'
        }
      },
      {
        path: 'detay/:id',
        name: 'acenteDetay',
        component: AcenteDetay,
        meta: {
          title: 'Detay - Acenteler'
        }
      },
      {
        path: 'profil',
        name: 'acenteProfil',
        component: AcenteProfil,
        meta: {
          title: 'Profil'
        }
      },
      {
        path: 'folyo',
        name: 'acenteFolyo',
        component: AcenteFolyo,
        meta: {
          title: 'Folyo'
        }
      }
    ]
  },
  {
    path: '/aracFirma',
    component: Body,
    meta: {
      requiresAuth: true
    },
    children: [
      {
        path: 'list',
        name: 'aracFirmaList',
        component: AracFirmaList,
        meta: {
          title: 'Araç Firmaları'
        }
      },
      {
        path: 'kayit',
        name: 'aracFirmaEkle',
        component: AracFirmaKayit,
        meta: {
          title: 'Yeni Araç Firması - Araç Firmaları'
        }
      },
      {
        path: 'kayit/:id',
        name: 'aracFirmaDuzelt',
        component: AracFirmaKayit,
        meta: {
          title: 'Düzelt - Araç Firmaları'
        }
      },
      {
        path: 'aracList/:aracFirmaId',
        name: 'aracListByFirmaId',
        component: AracList,
        meta: {
          title: 'Araçları'
        }
      },
      {
        path: 'bagimsizAracList',
        name: 'bagimsizAracList',
        component: BagimsizAracList,
        meta: {
          title: 'Bağımsız Araçlar'
        }
      },
      {
        path: 'aracList',
        name: 'aracList',
        component: AracList,
        meta: {
          title: 'Araçlar'
        }
      },
      {
        path: 'aracOnayBekleyen',
        name: 'aracOnayBekleyen',
        component: AracOnayBekleyenList,
        meta: {
          title: 'Onay Bekleyen Araçlar'
        }
      },
      {
        path: 'aracFirmaDetay/:id',
        name: 'aracFirmaDetay',
        component: AracFirmaDetay,
        meta: {
          title: 'Araç Firma Detayı - Araç Firmaları'
        }
      },
      {
        path: 'aracKayit/:aracFirmaId',
        name: 'aracEkle',
        component: AracKayit,
        meta: {
          title: 'Yeni Araç - Araçlar'
        }
      },
      {
        path: 'aracKayit',
        name: 'bagimsizAracEkle',
        component: AracKayit,
        meta: {
          title: 'Yeni Araç - Araçlar'
        }
      },
      {
        path: 'aracKayit/:aracFirmaId/:id',
        name: 'aracDuzelt',
        component: AracKayit,
        meta: {
          title: 'Düzelt - Araçlar'
        }
      },
      {
        path: 'aracDetay/:id',
        name: 'aracDetay',
        component: AracDetay,
        meta: {
          title: 'Detay - Araç'
        }
      },
      {
        path: 'profil',
        name: 'aracFirmaProfil',
        component: AracFirmaProfil,
        meta: {
          title: 'Profil - Araç Firmaları'
        }
      },
      {
        path: 'folyo',
        name: 'aracFirmaFolyo',
        component: AracFirmaFolyo,
        meta: {
          title: 'Folyo - Araç Firmaları'
        }
      },
      {
        path: 'aracResimOnay',
        name: 'aracResimOnayList',
        component: AracResimOnay,
        meta: {
          title: 'Onay Bekleyen Araç Resimleri'
        }
      },
      {
        path: 'aracProfil',
        name: 'aracProfil',
        component: AracProfil,
        meta: {
          title: 'Profil - Araçlar'
        }
      },
      {
        path: 'aracFolyo',
        name: 'aracFolyo',
        component: AracFolyo,
        meta: {
          title: 'Folyo - Araçlar'
        }
      },
      {
        path: 'aracTakvim',
        name: 'aracTakvim',
        component: AracTakvim,
        meta: {
          title: 'Müsaitlik Takvimi'
        }
      }
    ]
  },
  {
    path: '/aracTipi',
    component: Body,
    meta: {
      requiresAuth: true
    },
    children: [
      {
        path: 'list',
        name: 'aracTipiList',
        component: AracTipiList,
        meta: {
          title: 'Araç Tipleri'
        }
      },
      {
        path: 'kayit',
        name: 'aracTipiEkle',
        component: AracTipiKayit,
        meta: {
          title: 'Yeni Araç Tipi - Araç Tipleri'
        }
      },
      {
        path: 'kayit/:id',
        name: 'aracTipiDuzelt',
        component: AracTipiKayit,
        meta: {
          title: 'Düzelt - Araç Tipleri'
        }
      }
    ]
  },
  {
    path: '/parabirimi',
    component: Body,
    meta: {
      requiresAuth: true
    },
    children: [
      {
        path: 'list',
        name: 'parabirimiList',
        component: ParabirimiList,
        meta: {
          title: 'Parabirimleri'
        }
      },
      {
        path: 'kayit',
        name: 'parabirimiEkle',
        component: ParabirimiKayit,
        meta: {
          title: 'Yeni Parabirimi - Parabirimleri'
        }
      },
      {
        path: 'kayit/:id',
        name: 'parabirimiDuzelt',
        component: ParabirimiKayit,
        meta: {
          title: 'Düzelt - Parabirimleri'
        }
      }
    ]
  },
  {
    path: '/balonFirma',
    component: Body,
    meta: {
      requiresAuth: true
    },
    children: [
      {
        path: 'list',
        name: 'balonFirmaList',
        component: BalonFirmaList,
        meta: {
          title: 'Balon Firmaları'
        }
      },
      {
        path: 'kayit',
        name: 'balonFirmaEkle',
        component: BalonFirmaKayit,
        meta: {
          title: 'Yeni Balon Firması - Balon Firmaları'
        }
      },
      {
        path: 'kayit/:id',
        name: 'balonFirmaDuzelt',
        component: BalonFirmaKayit,
        meta: {
          title: 'Düzelt - Balon Firmaları'
        }
      }
    ]
  },
  {
    path: '/banner',
    component: Body,
    meta: {
      requiresAuth: true
    },
    children: [
      {
        path: 'list',
        name: 'bannerList',
        component: BannerList,
        meta: {
          title: 'Bannerlar'
        }
      },
      {
        path: 'kayit',
        name: 'bannerEkle',
        component: BannerKayit,
        meta: {
          title: 'Yeni Banner - Bannerlar'
        }
      },
      {
        path: 'kayit/:id',
        name: 'bannerDuzelt',
        component: BannerKayit,
        meta: {
          title: 'Düzelt - Bannerlar'
        }
      }
    ]
  },
  {
    path: '/dil',
    component: Body,
    meta: {
      requiresAuth: true
    },
    children: [
      {
        path: 'list',
        name: 'dilList',
        component: DilList,
        meta: {
          title: 'Diller'
        }
      },
      {
        path: 'kayit',
        name: 'dilEkle',
        component: DilKayit,
        meta: {
          title: 'Yeni Dil - Diller'
        }
      },
      {
        path: 'kayit/:id',
        name: 'dilDuzelt',
        component: DilKayit,
        meta: {
          title: 'Düzelt - Diller'
        }
      }
    ]
  },
  {
    path: '/duyuru',
    component: Body,
    meta: {
      requiresAuth: true
    },
    children: [
      {
        path: 'list',
        name: 'duyuruList',
        component: DuyuruList,
        meta: {
          title: 'Duyurular'
        }
      },
      {
        path: 'kayit',
        name: 'duyuruEkle',
        component: DuyuruKayit,
        meta: {
          title: 'Yeni Duyuru - Duyurular'
        }
      },
      {
        path: 'kayit/:id',
        name: 'duyuruDuzelt',
        component: DuyuruKayit,
        meta: {
          title: 'Düzelt - Duyurular'
        }
      }
    ]
  },
  {
    path: '/icerik',
    component: Body,
    meta: {
      requiresAuth: true
    },
    children: [
      {
        path: 'list',
        name: 'icerikList',
        component: IcerikList,
        meta: {
          title: 'İçerikler'
        }
      },
      {
        path: 'kayit',
        name: 'icerikEkle',
        component: IcerikKayit,
        meta: {
          title: 'Yeni İçerik - İçerikler'
        }
      },
      {
        path: 'kayit/:id',
        name: 'icerikDuzelt',
        component: IcerikKayit,
        meta: {
          title: 'İçerik - İçerikler'
        }
      }
    ]
  },
  {
    path: '/menu',
    component: Body,
    meta: {
      requiresAuth: true
    },
    children: [
      {
        path: 'list',
        name: 'menuList',
        component: MenuList,
        meta: {
          title: 'Menüler'
        }
      },
      {
        path: 'kayit',
        name: 'menuEkle',
        component: MenuKayit,
        meta: {
          title: 'Yeni Menü - Menüler'
        }
      },
      {
        path: 'kayit/:id',
        name: 'menuDuzelt',
        component: MenuKayit,
        meta: {
          title: 'Menü - Menüler'
        }
      }
    ]
  },
  {
    path: '/rehber',
    component: Body,
    meta: {
      requiresAuth: true
    },
    children: [
      {
        path: 'list',
        name: 'rehberList',
        component: RehberList,
        meta: {
          title: 'Rehberler'
        }
      },
      {
        path: 'onayBekleyen',
        name: 'rehberOnayBekleyenList',
        component: RehberOnayBekleyenList,
        meta: {
          title: 'Onay Bekleyen Rehberler'
        }
      },
      {
        path: 'kayit',
        name: 'rehberEkle',
        component: RehberKayit,
        meta: {
          title: 'Yeni Rehber - Rehberler'
        }
      },
      {
        path: 'kayit/:id',
        name: 'rehberDuzelt',
        component: RehberKayit,
        meta: {
          title: 'Rehber - Rehberler'
        }
      },
      {
        path: 'detay/:id',
        name: 'rehberDetay',
        component: RehberDetay,
        meta: {
          title: 'Rehber Detayı'
        }
      },
      {
        path: 'profil',
        name: 'rehberProfil',
        component: RehberProfil,
        meta: {
          title: 'Profil'
        }
      },
      {
        path: 'folyo',
        name: 'rehberFolyo',
        component: RehberFolyo,
        meta: {
          title: 'Folyo'
        }
      },
      {
        path: 'takvim',
        name: 'rehberTakvim',
        component: RehberTakvim,
        meta: {
          title: 'Müsaitlik Takvimi'
        }
      }
    ]
  },
  {
    path: '/turRezervasyon',
    component: Body,
    meta: {
      requiresAuth: true
    },
    children: [
      {
        path: 'list',
        name: 'turRezervasyonList',
        component: TurRezervasyonList,
        meta: {
          title: 'Tur Rezervasyonları'
        }
      },
      {
        path: 'detay/:id',
        name: 'turRezervasyonDetay',
        component: TurRezervasyonDetay,
        meta: {
          title: 'Tur Rezervasyon Detayı'
        }
      },
      {
        path: 'duzelt/:id',
        name: 'turRezervasyonDuzelt',
        component: TurRezervasyonDuzelt,
        meta: {
          title: 'Tur Rezervasyon Düzelt'
        }
      },
      {
        path: 'duzeltArac/:id',
        name: 'turRezervasyonDuzeltArac',
        component: TurRezervasyonDuzeltArac,
        meta: {
          title: 'Tur Rezervasyon Araç Düzelt'
        }
      },
      {
        path: 'duzeltRehber/:id',
        name: 'turRezervasyonDuzeltRehber',
        component: TurRezervasyonDuzeltRehber,
        meta: {
          title: 'Tur Rezervasyon Rehber Düzelt'
        }
      },
      {
        path: 'yeni/:id',
        name: 'turRezervasyonYeni',
        component: TurRezervasyonYeni,
        meta: {
          title: 'Yeni Tur Rezervasyonu'
        }
      }
    ]
  },
  {
    path: '/balonRezervasyon',
    component: Body,
    meta: {
      requiresAuth: true
    },
    children: [
      {
        path: 'list',
        name: 'balonRezervasyonList',
        component: BalonRezervasyonList,
        meta: {
          title: 'Balon Rezervasyonları'
        }
      },
      {
        path: 'detay/:id',
        name: 'balonRezervasyonDetay',
        component: BalonRezervasyonDetay,
        meta: {
          title: 'Balon Rezervasyon Detayı'
        }
      },
      {
        path: 'duzelt/:id',
        name: 'balonRezervasyonDuzelt',
        component: BalonRezervasyonDuzelt,
        meta: {
          title: 'Balon Rezervasyon Düzelt'
        }
      }
    ]
  },
  {
    path: '/sehir',
    component: Body,
    meta: {
      requiresAuth: true
    },
    children: [
      {
        path: 'list',
        name: 'sehirList',
        component: SehirList,
        meta: {
          title: 'Şehirler'
        }
      },
      {
        path: 'kayit',
        name: 'sehirEkle',
        component: SehirKayit,
        meta: {
          title: 'Yeni Şehir - Şehirler'
        }
      },
      {
        path: 'kayit/:id',
        name: 'sehirDuzelt',
        component: SehirKayit,
        meta: {
          title: 'Şehir - Şehirler'
        }
      }
    ]
  },
  {
    path: '/kullanici',
    component: Body,
    meta: {
      requiresAuth: true
    },
    children: [
      {
        path: 'list',
        name: 'kullaniciList',
        component: KullaniciList,
        meta: {
          title: 'Kullanıcılar'
        }
      },
      {
        path: 'kayit',
        name: 'kullaniciEkle',
        component: KullaniciKayit,
        meta: {
          title: 'Yeni Kullanıcı - Kullanıcılar'
        }
      },
      {
        path: 'kayit/:id',
        name: 'kullaniciDuzelt',
        component: KullaniciKayit,
        meta: {
          title: 'Kullanıcı - Kullanıcılar'
        }
      }
    ]
  },
  {
    path: '/kullaniciTipi',
    component: Body,
    meta: {
      requiresAuth: true
    },
    children: [
      {
        path: 'menu',
        name: 'kullaniciTipiMenu',
        component: KullaniciTipiMenu,
        meta: {
          title: 'Panel Menüleri'
        }
      }
    ]
  },
  {
    path: '/parametre',
    component: Body,
    meta: {
      requiresAuth: true
    },
    children: [
      {
        path: 'guncelle',
        name: 'parametreGuncelle',
        component: ParametreGuncelle,
        meta: {
          title: 'Parametre Güncelleme'
        }
      }
    ]
  }

];

const router = new Router({
  routes,
  // base: '/endless/',
  mode: 'history',
  linkActiveClass: "active",
  scrollBehavior() {
    return { x: 0, y: 0 }
  }
});


router.beforeEach((to, from, next) => {
  const path = ['/giris', '/giris/acentebasvur', '/giris/rehberbasvur', '/giris/aracbasvur', '/giris/sifremiunuttum', '/reservation/view'];
  if (path.includes(to.path.toLocaleLowerCase()) || localStorage.getItem('Token')) {
    return next();
  }
  next('/giris');
});


export default router