import moment from 'moment'

export default {
    formatMoney: function (n) {
        return parseFloat(n).toFixed(2).replace(/(\\d)(?=(\\d{3})+\\.)/g, '$1.').replace(/\\.(\\d+)$/, ',$1');
    },
    formatDate(value) {
        if (value) {
            return moment(String(value)).format('DD.MM.YYYY')
        }
    },
    formatDateApi(value) {
        if (value) {
            return moment(String(value)).format('YYYY-MM-DDT00:00:00.000Z')
        }
    },
    addDay(date, days) {
        date.setDate(date.getDate() + days)
        return date;
    },
    addMonth(date, month) {
        date.setMonth(date.getMonth() + month);
        return date;
    },
    misafirlerToList(misafirler) {
        return misafirler.split(',');
    }
}