<template>
  <div class="card height-equal">
    <div class="card-header">
      <h5>Rehber - Onay Bekleyenler</h5>
    </div>
    <div class="card-body">
      <div class="upcoming-event">
        <div class="upcoming-innner media" v-for="item in items" :key="item.Id">
          <div class="bg-primary left m-r-20">
            <feather type="users"></feather>
          </div>
          <div class="media-body">
            <p class="mb-0">
              {{ item.AdSoyad }}
              <span class="pull-right">{{ formatDate(item.KayitTarihi) }}</span>
            </p>
            <h6 class="f-w-600">{{ item.YetkiliAdi }}</h6>
            <p class="mb-0">
              <strong>Cep Telefonu : </strong> {{ item.GSM }}<br />
              <strong>Email : </strong> {{ item.Email }}
            </p>
            <ul>
              <li
                v-for="(dil, index) in item.Diller"
                :key="index"
                class="d-inline-block"
              >
                <img class="img-20 rounded-circle" :src="dil" alt="" />
              </li>
            </ul>
            <b-btn-group>
              <router-link
                :to="`/rehber/detay/${item.Id}`"
                class="btn btn-dark btn-xs"
                >Detay</router-link
              >
              <router-link
                :to="`/rehber/kayit/${item.Id}`"
                class="btn btn-info btn-xs"
                >Düzelt</router-link
              >
              <b-button size="xs" @click="onayla(item)" variant="success"
                >Onayla</b-button
              >
              <b-button size="xs" @click="iptalEt(item)" variant="warning"
                >İptal Et</b-button
              >
              <b-button size="xs" @click="sil(item)" variant="danger"
                >Sil</b-button
              >
            </b-btn-group>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {},
  data() {
    return {
      items: [],
    };
  },
  mounted() {
    this.onList();
  },
  methods: {
    formatDate(date) {
      return this.$formatHelper.formatDate(date);
    },
    onList() {
      let vm = this;

      this.$connection.get("/rehberler/onayBekleyenler", function (response) {
        vm.items = response;
      });
    },
    sil(item) {
      let vm = this;
      this.$alert.confirm(
        "Sil",
        "Rehber kaydını silmek istediğinize emin misiniz?",
        "warning",
        function () {
          vm.$connection.delete("/rehberler/" + item.Id, function (response) {
            vm.$alert.messageWithFunction(
              "Silindi",
              "Rehber kaydı silindi.",
              "error",
              function () {
                vm.$listHelper.removeItem(vm.items, item);
              }
            );
          });
        }
      );
    },
    onayla(item) {
      let vm = this;
      this.$alert.confirm(
        "Onayla",
        "Rehberi onaylamak istediğinize emin misiniz?",
        "warning",
        function () {
          vm.$connection.get(
            "/rehberler/onayla/" + item.Id,
            function (response) {
              if (response.Success) {
                vm.$alert.messageWithFunction(
                  "Onaylandı",
                  "Rehber onaylandı.",
                  "success",
                  function () {
                    vm.$listHelper.removeItem(vm.items, item);
                  }
                );
              }
            }
          );
        }
      );
    },
    iptalEt(item) {
      let vm = this;
      this.$alert.confirm(
        "İptal Et",
        "Rehber aynı bilgilerle bir daha üye olamayacak. İptal etmek istedğinize emin misiniz?",
        "warning",
        function () {
          vm.$connection.get(
            "/rehberler/durumDegistir/" + item.Id + "/100",
            function (response) {
              if (response.Success) {
                vm.$alert.messageWithFunction(
                  "İptal Edildi",
                  "Rehber iptal edildi.",
                  "success",
                  function () {
                    vm.$listHelper.removeItem(vm.items, item);
                  }
                );
              }
            }
          );
        }
      );
    },
  },
};
</script>
