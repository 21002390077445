<template>
  <div class="page-wrapper">
    <div class="container-fluid">
      <b-row>
        <b-col md="8" offset-md="2">
          <div class="card" v-if="detay.Id !== undefined">
            <div class="card-body">
              <div class="invoice">
                <div>
                  <div>
                    <div class="row">
                      <div class="col-sm-6">
                        <div class="media">
                          <div class="media-left" v-if="detay.AcenteLogo != ''">
                            <img
                              class="media-object img-60"
                              :src="detay.AcenteLogo"
                              :alt="detay.AcenteAdi"
                            />
                          </div>
                          <div class="media-body m-l-20">
                            <h4 class="media-heading">{{ detay.AcenteAdi }}</h4>
                            <p>
                              <span class="digits">{{
                                detay.AcenteOperasyonTel
                              }}</span>
                            </p>
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-6">
                        <div class="text-md-right">
                          <h3>
                            Reservation Code
                            <span class="digits counter">#{{ detay.Kod }}</span>
                          </h3>
                          <p>
                            Tour Date :
                            <span class="digits"
                              >{{ $formatHelper.formatDate(detay.Tarih) }}
                              {{ detay.Saat }}</span
                            >
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr />
                  <b-row>
                    <b-col md="8">
                      <div class="table-responsive invoice-table" id="table">
                        <table class="table table-bordered table-striped mb-3">
                          <tbody>
                            <tr>
                              <td width="30%">
                                <label>Başlangıç Yeri</label>
                              </td>
                              <td>
                                {{ detay.OtelAdi }} / {{ detay.OtelAdresi }}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <label>Tarih / Saat</label>
                              </td>
                              <td>
                                {{ $formatHelper.formatDate(detay.Tarih) }}
                                {{ detay.Saat }}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <label>Kişi Sayısı</label>
                              </td>
                              <td>
                                {{ detay.KisiSayisi }}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <label>Misafirler</label>
                              </td>
                              <td>
                                <span
                                  v-for="(misafir, index) in detay.Misafirler"
                                  :key="index"
                                  >{{ misafir.AdSoyad }}</span
                                >
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <label>Tur Adı</label>
                              </td>
                              <td>
                                {{ detay.TurAdi }}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <label>Tur Opsiyonu</label>
                              </td>
                              <td>
                                {{ detay.TurOpsiyonAdi }}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <label>Tur Dili</label>
                              </td>
                              <td>
                                {{ detay.DilAdi }}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>

                      <!--<b-card v-if="detay.TurDetayi.Restoran !== null" header-tag="div" header-bg-variant="info" class="card-absolute mb-5">
                                                <h5 slot="header">Restoran</h5>
                                                <b-list-group>
                                                    <b-list-group-item tag="a" href="javascript:"
                                                        class="flex-column align-items-start">
                                                        <div class="d-flex w-100 justify-content-between">
                                                            <h5 class="mb-1">{{ detay.TurDetayi.Restoran.RestoranAdi }}</h5>
                                                        </div>
                                                        <p class="mb-1" v-html="detay.TurDetayi.Restoran.RestoranAciklama"></p>
                                                    </b-list-group-item>
                                                </b-list-group>
                                            </b-card>

                                            <b-card v-if="detay.Muzeler !== null" header-tag="div" header-bg-variant="warning" class="card-absolute mb-5">
                                                <h5 slot="header">Müzeler</h5>
                                                <b-list-group>
                                                    <b-list-group-item v-for="(muze, index) in detay.Muzeler"
                                                        :key="index" tag="a" href="javascript:"
                                                        class="flex-column align-items-start">
                                                        <div class="d-flex w-100 justify-content-between">
                                                            <h5 class="mb-1">{{ muze.Tanim }}</h5>
                                                        </div>
                                                        <p class="mb-1" v-html="muze.Detay"></p>
                                                    </b-list-group-item>
                                                </b-list-group>
                                            </b-card>

                                            <b-card header-tag="div" header-bg-variant="success" class="card-absolute mb-5">
                                                <h5 slot="header">Mağazalar</h5>
                                                <b-list-group>
                                                    <b-list-group-item v-for="(mekan, index) in detay.TurDetayi.Mekanlar"
                                                        :key="index" tag="a" href="javascript:"
                                                        class="flex-column align-items-start">
                                                        <div class="d-flex w-100 justify-content-between">
                                                            <h5 class="mb-1">{{ mekan.MekanAdi }}</h5>
                                                        </div>
                                                        <p class="mb-1">{{ mekan.KategoriAdi }}</p>
                                                    </b-list-group-item>
                                                </b-list-group>
                                            </b-card>-->
                    </b-col>
                    <b-col md="4">
                      <b-card
                        header-tag="div"
                        header-bg-variant="secondary"
                        class="card-absolute mb-5"
                        body-class="text-center"
                        style="max-width: 20rem"
                      >
                        <h5 slot="header">Rehber</h5>
                        <h4>{{ detay.RehberAdi }}</h4>
                        <img
                          :src="detay.RehberResim"
                          :alt="detay.RehberAdi"
                          class="img-thumbnail img-100"
                        />
                      </b-card>
                      <b-card
                        header-tag="div"
                        header-bg-variant="primary"
                        class="card-absolute mb-2"
                        body-class="text-center"
                        style="max-width: 20rem"
                      >
                        <h5 slot="header">Araç</h5>
                        <h4>{{ detay.SoforAdi }}</h4>
                        <p>
                          {{ detay.AracMarka }} / {{ detay.AracModel }}
                          <b-badge v-if="detay.isWifi" variant="warning">
                            <i
                              class="fa fa-wifi"
                              aria-hidden="true"
                              style="font-size: 14px"
                            ></i>
                          </b-badge>
                        </p>
                        <img
                          :src="detay.AracResim"
                          :alt="detay.SoforAdi"
                          class="img-thumbnail"
                        />
                      </b-card>
                    </b-col>
                  </b-row>
                </div>
              </div>
            </div>
          </div>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      id: this.$route.params.id,
      detay: {},
    };
  },
  mounted() {
    this.onDetay();
  },
  components: {},
  computed: {},
  methods: {
    onDetay() {
      let vm = this;

      this.$connection.get(
        "/turRezervasyonlari/view/" + this.id,
        function (response) {
          if (response.Success) {
            vm.detay = response.Data;
          } else {
            vm.$alert.messageWithTitle(
              "Tour Reservation View",
              response.Message,
              "error"
            );
          }
        }
      );
    },
  },
};
</script>
