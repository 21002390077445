<template>
  <div>
    <Breadcrumbs main="Rehberler" title="Liste" :links="breadLinks" />

    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-body avatar-showcase">
              <div class="avatars dilRehberGrup">
                <div class="avatar" v-for="dil in dilListe" :key="dil.Id">
                  <a
                    href="#"
                    @click="dilFiltreSec(dil)"
                    :class="dil.Id == filtre.DilId ? 'active' : ''"
                  >
                    <img
                      class="img-50 rounded-circle"
                      :src="dil.Bayrak"
                      :alt="dil.DilAdi"
                    />
                    <b-badge variant="info">{{ dil.RehberSayisi }}</b-badge>
                  </a>
                </div>
              </div>
            </div>
            <div class="card-body">
              <c-table :tablefields="tablefields" :items="items">
                <template #cell(Islem)="row">
                  <b-btn-group>
                    <router-link
                      :to="`/rehber/detay/${row.item.Id}`"
                      class="btn btn-info btn-xs"
                      >Detay</router-link
                    >
                    <router-link
                      :to="`/rehber/kayit/${row.item.Id}`"
                      class="btn btn-success btn-xs"
                      >Düzelt</router-link
                    >
                    <b-button size="xs" @click="sil(row.item)" variant="danger"
                      >Sil</b-button
                    >
                  </b-btn-group>
                </template>
                <template #cell(Diller)="data">
                  <ul>
                    <li
                      v-for="(dil, index) in data.value"
                      :key="index"
                      class="d-inline-block"
                    >
                      <img class="img-20 rounded-circle" :src="dil" alt="" />
                    </li>
                  </ul>
                </template>
                <template #cell(ToplamAlacak)="data">
                  <c-money-cell :fiyat="data.value" simge="TL"></c-money-cell>
                </template>
                <template #cell(DurumStr)="row">
                  <b-badge :variant="durumVariant(row.item.Durum)">{{
                    row.item.DurumStr
                  }}</b-badge>
                </template>
                <template #cell(KayitTarihi)="row">
                  <c-tarih-cell
                    :kayitTarihi="row.item.KayitTarihi"
                    :guncelTarih="row.item.GuncelTarih"
                    :rowKey="row.item.Id"
                  ></c-tarih-cell>
                </template>
              </c-table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      breadLinks: [{ path: "/rehber/kayit", title: "Ekle", icon: "plus" }],
      tablefields: [
        { key: "Islem", label: "İşlemler", sortable: false },
        { key: "AdSoyad", label: "Adı", sortable: true },
        { key: "Diller", label: "Dil", sortable: true },
        {
          key: "GSM",
          label: "GSM",
          sortable: true,
          thClass: "sabitKol",
          tdClass: "sabitKol",
        },
        {
          key: "Email",
          label: "Email",
          sortable: true,
          thClass: "sabitKol",
          tdClass: "sabitKol",
        },
        { key: "ToplamAlacak", label: "Bakiye", sortable: true },
        { key: "Sira", label: "Sıra", sortable: true },
        { key: "DurumStr", label: "Durum", sortable: true },
        { key: "KayitTarihi", label: "Kayıt Tarihi", sortable: true },
      ],

      dilListe: [],
      filtre: {
        DilId: null,
      },
      items: [],
    };
  },
  components: {},
  mounted() {
    this.dilGrupList();
    this.onList();
  },
  methods: {
    dilFiltreSec(dil) {
      if (dil.Id === this.filtre.DilId) {
        this.filtre.DilId = null;
      } else {
        this.filtre.DilId = dil.Id;
      }
      this.onList();
    },
    dilGrupList() {
      let vm = this;

      this.$connection.get("/rehberler/dilGrup", function (response) {
        vm.dilListe = response;
      });
    },
    onList() {
      let vm = this;

      this.$connection.post(
        "/rehberler/filtre",
        this.filtre,
        "post",
        function (response) {
          vm.items = response;
        }
      );
    },
    sil(item) {
      let vm = this;
      this.$alert.confirm(
        "Sil",
        "Rehber kaydını silmek istediğinize emin misiniz?",
        "warning",
        function () {
          vm.$connection.delete("/rehberler/" + item.Id, function (response) {
            vm.$alert.messageWithFunction(
              "Silindi",
              "Rehber kaydı silindi.",
              "error",
              function () {
                vm.$listHelper.removeItem(vm.items, item);
              }
            );
          });
        }
      );
    },
    durumVariant(durum) {
      switch (durum) {
        case 0:
          return "info";
        case 20:
          return "warning";
        case 30:
          return "success";
        case 40:
          return "dark";
        case 100:
          return "danger";
        default:
          return "light";
      }
    },
  },
};
</script>
