<template>
  <div>
    <Breadcrumbs main="Acenteler" :title="pageTitle" :links="breadLinks" />

    <div class="container-fluid tur-rezervasyon-detay">
      <div class="row">
        <div class="col-md-12">
          <ul
            class="nav nav-tabs border-tab tabs-scoial"
            id="top-tab"
            role="tablist"
          >
            <li class="nav-item" v-on:click="changetab('genel')">
              <a
                class="nav-link"
                :class="{ 'show active': showtab == 'genel' }"
                id="top-genel"
                data-toggle="tab"
                role="tab"
                aria-controls="genel"
                aria-selected="true"
                >Genel <b-badge variant="info">{{ acente.Adi }}</b-badge></a
              >
            </li>
            <li class="nav-item" v-on:click="changetab('fiyat')">
              <a
                class="nav-link"
                :class="{ 'show active': showtab == 'fiyat' }"
                id="top-fiyat"
                data-toggle="tab"
                role="tab"
                aria-controls="fiyat"
                aria-selected="false"
                >Fiyatlar</a
              >
            </li>
            <li class="nav-item" v-on:click="changetab('folyo')">
              <a
                class="nav-link"
                :class="{ 'show active': showtab == 'folyo' }"
                id="top-folyo"
                data-toggle="tab"
                role="tab"
                aria-controls="folyo"
                aria-selected="false"
                >Folyo</a
              >
            </li>
            <li class="nav-item" v-on:click="changetab('mekan')">
              <a
                class="nav-link"
                :class="{ 'show active': showtab == 'mekan' }"
                id="top-mekan"
                data-toggle="tab"
                role="tab"
                aria-controls="mekan"
                aria-selected="false"
                >Anlaşmalı Mağazalar</a
              >
            </li>
            <li class="nav-item" v-on:click="changetab('belge')">
              <a
                class="nav-link"
                :class="{ 'show active': showtab == 'belge' }"
                id="top-belge"
                data-toggle="tab"
                role="tab"
                aria-controls="belge"
                aria-selected="false"
                >Belgeler</a
              >
            </li>
            <li class="nav-item" v-on:click="changetab('sozlesme')">
              <a
                class="nav-link"
                :class="{ 'show active': showtab == 'sozlesme' }"
                id="top-sozlesme"
                data-toggle="tab"
                role="tab"
                aria-controls="sozlesme"
                aria-selected="false"
                >Sözleşme</a
              >
            </li>
            <li class="nav-item" v-on:click="changetab('kullanici')">
              <a
                class="nav-link"
                :class="{ 'show active': showtab == 'kullanici' }"
                id="top-kullanici"
                data-toggle="tab"
                role="tab"
                aria-controls="kullanici"
                aria-selected="false"
                >Kullanıcılar</a
              >
            </li>
          </ul>
        </div>
      </div>

      <div class="tab-content" id="top-tabContent">
        <div
          class="tab-pane fade"
          :class="{ 'show active': showtab == 'genel' }"
          id="genel"
          role="tabpanel"
          aria-labelledby="genel"
        >
          <div class="row">
            <div class="col-md-6">
              <b-card
                header-tag="div"
                header-bg-variant="primary"
                class="card-absolute"
              >
                <h5 slot="header">Firma Bilgileri</h5>
                <table class="product-page-width">
                  <tbody>
                    <tr>
                      <td colspan="2">
                        <div class="avatar">
                          <img
                            class="img-100 rounded-circle"
                            :src="acente.Logo"
                            alt="#"
                          />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th>Adı</th>
                      <td>{{ acente.Adi }}</td>
                    </tr>
                    <tr>
                      <th>Unvan</th>
                      <td>{{ acente.Unvan }}</td>
                    </tr>
                    <tr>
                      <th>Yetkili Kişi</th>
                      <td>{{ acente.YetkiliKisi }}</td>
                    </tr>
                    <tr>
                      <th>Yetkili Kişi Unvanı</th>
                      <td>{{ acente.YetkiliUnvan }}</td>
                    </tr>
                    <tr>
                      <th>Adres</th>
                      <td>{{ acente.Adres }}</td>
                    </tr>
                    <tr>
                      <th>Şehir</th>
                      <td>{{ acente.SehirAdi }}</td>
                    </tr>
                    <tr>
                      <th>Vergi Dairesi</th>
                      <td>{{ acente.VergiDairesi }}</td>
                    </tr>
                    <tr>
                      <th>Vergi No</th>
                      <td>{{ acente.VergiNo }}</td>
                    </tr>
                    <tr>
                      <th>TÜRSAB İşletme Belge No</th>
                      <td>{{ acente.TursabBelgeNo }}</td>
                    </tr>
                    <tr>
                      <th>Toplam Borç</th>
                      <td>
                        <b-badge variant="info"
                          ><c-money-cell
                            :fiyat="acente.ToplamBorc"
                            simge="TL"
                          ></c-money-cell
                        ></b-badge>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </b-card>

              <b-card
                header-tag="div"
                header-bg-variant="info"
                class="card-absolute"
              >
                <h5 slot="header">Kayıt Bilgileri</h5>
                <table class="product-page-width">
                  <tbody>
                    <tr>
                      <th>Durum</th>
                      <td>
                        <b-badge variant="primary">{{
                          acente.DurumStr
                        }}</b-badge>
                      </td>
                    </tr>
                    <tr>
                      <th>Son Rezervasyon Tarihi</th>
                      <td>
                        <c-tarih-cell
                          :kayitTarihi="acente.SonRezervasyonTarihi"
                          :rowKey="acente.Id"
                        ></c-tarih-cell>
                      </td>
                    </tr>
                    <tr>
                      <th>Kullanıcı</th>
                      <td>{{ acente.KullaniciAdi }}</td>
                    </tr>
                    <tr>
                      <th>Kayıt Tarihi</th>
                      <td>
                        <c-tarih-cell
                          :kayitTarihi="acente.KayitTarihi"
                          :rowKey="acente.Id"
                        ></c-tarih-cell>
                      </td>
                    </tr>
                    <tr>
                      <th>Güncelleme Tarihi</th>
                      <td>
                        <c-tarih-cell
                          :kayitTarihi="acente.GuncelTarih"
                          :rowKey="acente.Id"
                        ></c-tarih-cell>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </b-card>
            </div>
            <div class="col-md-6">
              <div class="row">
                <div class="col-md-12">
                  <b-list-group class="mb-3">
                    <b-list-group-item
                      router-component-name="router-link"
                      :to="'/acente/kayit/' + acente.Id"
                    >
                      <feather type="edit-3"></feather> Düzelt
                    </b-list-group-item>
                    <b-list-group-item
                      tag="a"
                      href="#"
                      @click="onaylandi()"
                      :disabled="acente.Durum === 30"
                    >
                      <feather type="check"></feather> Onayla
                    </b-list-group-item>
                    <b-list-group-item
                      tag="a"
                      href="#"
                      @click="
                        durumDegistir(
                          0,
                          'Yeni',
                          'Yeni durumuna alınacaktır. Emin misiniz?',
                          'info'
                        )
                      "
                      :disabled="acente.Durum === 0"
                    >
                      <feather type="check"></feather> Yeni Durumuna Al
                    </b-list-group-item>
                    <b-list-group-item
                      tag="a"
                      href="#"
                      @click="
                        durumDegistir(
                          20,
                          'Pasif',
                          'Pasif durumuna alınacaktır. Emin misiniz?',
                          'warning'
                        )
                      "
                      :disabled="acente.Durum === 20"
                    >
                      <feather type="check"></feather> Pasife Al
                    </b-list-group-item>
                    <b-list-group-item
                      tag="a"
                      href="#"
                      @click="
                        durumDegistir(
                          100,
                          'İptal',
                          'İptal edilecektir. Emin misiniz?',
                          'error'
                        )
                      "
                      :disabled="acente.Durum === 100"
                    >
                      <feather type="check"></feather> İptal Et
                    </b-list-group-item>
                    <b-list-group-item
                      router-component-name="router-link"
                      :disabled="acente.Durum !== 30"
                      :to="'/turRezervasyon/yeni/' + acente.Id"
                    >
                      <feather type="file-plus"></feather> Yeni Tur Rezervasyonu
                    </b-list-group-item>
                  </b-list-group>
                </div>
              </div>

              <b-card
                header-tag="div"
                header-bg-variant="warning"
                class="card-absolute"
              >
                <h5 slot="header">İletişim Bilgileri</h5>
                <table class="product-page-width">
                  <tbody>
                    <tr>
                      <th>Telefon</th>
                      <td>{{ acente.Telefon }}</td>
                    </tr>
                    <tr>
                      <th>Cep Telefonu</th>
                      <td>{{ acente.GSM }}</td>
                    </tr>
                    <tr>
                      <th>Email</th>
                      <td>{{ acente.Email }}</td>
                    </tr>
                    <tr>
                      <th>Bilgilendirme Mailleri</th>
                      <td>
                        <div style="max-width: 70%; text-wrap: break-word">
                          {{ acente.BilgilendirmeMailleri }}
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th>Operasyon Yetkilisi</th>
                      <td>{{ acente.OperasyonYetkilisi }}</td>
                    </tr>
                    <tr>
                      <th>Operasyon Telefonu</th>
                      <td>{{ acente.OperasyonTelefonu }}</td>
                    </tr>
                  </tbody>
                </table>
              </b-card>
            </div>
          </div>
        </div>

        <div
          class="tab-pane fade"
          :class="{ 'show active': showtab == 'fiyat' }"
          id="fiyat"
          role="tabpanel"
          aria-labelledby="fiyat"
        >
          <div class="row">
            <div class="col-md-12">
              <b-card
                header-tag="div"
                header-bg-variant="primary"
                class="card-absolute"
              >
                <h5 slot="header">Tur Fiyatları</h5>
                <b-card-body class="p-1">
                  <AcenteTurFiyat
                    :fiyatlar="acente.TurFiyatlari"
                    :acenteId="acente.Id"
                    :noEdit="false"
                    @onDetay="onDetay()"
                  >
                  </AcenteTurFiyat>
                </b-card-body>
              </b-card>
            </div>
          </div>
        </div>

        <div
          class="tab-pane fade"
          :class="{ 'show active': showtab == 'mekan' }"
          id="mekan"
          role="tabpanel"
          aria-labelledby="mekan"
        >
          <div class="row">
            <div class="col-md-12">
              <b-card
                header-tag="div"
                header-bg-variant="primary"
                class="card-absolute"
              >
                <h5 slot="header">Anlaşmalı Mağazalar</h5>
                <b-card-body class="p-1">
                  <AcenteMekan
                    :acenteId="acente.Id"
                    :mekanlari="acenteMekanlari"
                  ></AcenteMekan>
                </b-card-body>
              </b-card>
            </div>
          </div>
        </div>

        <div
          class="tab-pane fade"
          :class="{ 'show active': showtab == 'folyo' }"
          id="folyo"
          role="tabpanel"
          aria-labelledby="folyo"
        >
          <div class="row">
            <div class="col-md-12">
              <b-card
                header-tag="div"
                header-bg-variant="primary"
                class="card-absolute"
              >
                <h5 slot="header">Folyo</h5>
                <b-card-body class="p-1">
                  <AcenteFolyo
                    :acente="acente"
                    v-if="isFolyoLoad"
                    :noEdit="false"
                    @onKayitEklendi="folyoKayitEklendi()"
                  ></AcenteFolyo>
                </b-card-body>
              </b-card>
            </div>
          </div>
        </div>

        <div
          class="tab-pane fade"
          :class="{ 'show active': showtab == 'belge' }"
          id="belge"
          role="tabpanel"
          aria-labelledby="belge"
        >
          <AcenteBelge
            :acente="acente"
            v-if="acente.Id != undefined"
            @onSubmitBelge="onSubmitBelge"
          />
        </div>

        <div
          class="tab-pane fade"
          :class="{ 'show active': showtab == 'sozlesme' }"
          id="sozlesme"
          role="tabpanel"
          aria-labelledby="sozlesme"
        >
          <div class="row">
            <div class="col-md-6">
              <b-card
                header-tag="div"
                header-bg-variant="primary"
                class="card-absolute"
              >
                <h5 slot="header">Rehber Sözleşmesi</h5>

                <AcenteSozlesme
                  :acente="acente"
                  :sozlesme="acente.RehberSozlesmesi"
                  v-if="acente.Id != undefined"
                />
              </b-card>
            </div>
            <div class="col-md-6">
              <b-card
                header-tag="div"
                header-bg-variant="primary"
                class="card-absolute"
              >
                <h5 slot="header">Araç Sözleşmesi</h5>

                <AcenteSozlesme
                  :acente="acente"
                  :sozlesme="acente.AracSozlesmesi"
                  v-if="acente.Id != undefined"
                />
              </b-card>
            </div>
          </div>
        </div>

        <div
          class="tab-pane fade"
          :class="{ 'show active': showtab == 'kullanici' }"
          id="kullanici"
          role="tabpanel"
          aria-labelledby="kullanici"
        >
          <div class="row">
            <div class="col-md-12">
              <b-card
                header-tag="div"
                header-bg-variant="primary"
                class="card-absolute"
              >
                <h5 slot="header">Kullanıcılar</h5>
                <b-card-body class="p-1">
                  <AcenteKullanici
                    :acenteId="acente.Id"
                    :kullanicilar="kullanicilar"
                  ></AcenteKullanici>
                </b-card-body>
              </b-card>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import AcenteTurFiyat from "@/components/c_acente_tur_fiyat";
import AcenteMekan from "@/components/c_acente_mekan";
import AcenteFolyo from "@/components/c_acente_folyo";
import AcenteKullanici from "@/components/c_acente_kullanici";
import AcenteBelge from "@/components/c_acente_belge";
import AcenteSozlesme from "@/components/c_acente_sozlesme";

export default {
  data() {
    return {
      id: this.$route.params.id,

      pageTitle: "Acente Detayı",

      breadLinks: [
        { path: "/acente/list", title: "Acenteler", icon: "chevron-left" },
      ],

      showtab: "genel",

      acente: {},

      sozlesmeTabStart:
        '<style>@page { margin: 0; }.page { width: 21cm; min-height: 29.7cm; padding: 1cm; margin: 0; }.page { font-family: "Times New Roman", Times, serif; }.page-break { display: block; page-break-before: always; }</style><div class="sozlesmePrint">',
      sozlesmeTabEnd: "</div>",

      acenteMekanlari: [],

      isFolyoLoad: false,

      kullanicilar: [],
      isKullaniciLoad: false,
    };
  },
  components: {
    AcenteTurFiyat,
    AcenteMekan,
    AcenteFolyo,
    AcenteKullanici,
    AcenteBelge,
    AcenteSozlesme,
  },
  computed: {
    yenifiyat_tur_state() {
      if (this.yeniFiyatForm.TurId == null || this.yeniFiyatForm.TurId == "")
        return false;
      else return true;
    },
    yenifiyat_opsiyon_state() {
      if (
        this.yeniFiyatForm.TurOpsiyonId == null ||
        this.yeniFiyatForm.TurOpsiyonId == ""
      )
        return false;
      else return true;
    },
    yenifiyat_fiyat_state() {
      if (
        this.yeniFiyatForm.Fiyat == null ||
        this.yeniFiyatForm.Fiyat == "" ||
        parseFloat(this.yeniFiyatForm.Fiyat) <= 0
      )
        return false;
      else return true;
    },
    sozlesme_state() {
      if (
        this.acente.RehberSozlesmesi == null ||
        this.acente.RehberSozlesmesi == ""
      )
        return false;
      else return true;
    },
    arac_sozlesme_state() {
      if (
        this.acente.AracSozlesmesi == null ||
        this.acente.AracSozlesmesi == ""
      )
        return false;
      else return true;
    },
  },
  mounted() {
    this.onDetay();
  },
  methods: {
    changetab(tab) {
      this.showtab = tab;

      switch (tab) {
        case "folyo":
          if (this.isFolyoLoad === false) this.folyoList();
          break;

        case "kullanici":
          if (this.isKullaniciLoad === false) this.kullaniciList();
          break;

        default:
          break;
      }
    },
    onDetay() {
      let vm = this;

      this.$connection.get("/acenteler/" + this.id, function (response) {
        if (response.Success) {
          vm.acente = response.Data;

          vm.acenteMekanList();
        } else {
          vm.$alert.messageWithTitle("Acente", response.Message, "error");
        }
      });
    },
    onaylandi() {
      let vm = this;
      this.$alert.confirm(
        "Onayla",
        "Acente onaylanacaktır. Emin misiniz?",
        "success",
        function () {
          vm.$connection.get("/acenteler/onayla/" + vm.acente.Id, function () {
            vm.$alert.messageWithFunction(
              "Onayla",
              "Acente onaylandı.",
              "success",
              function () {
                vm.onDetay();
              }
            );
          });
        }
      );
    },
    durumDegistir(durum, baslik, mesaj, icon) {
      let vm = this;
      this.$alert.confirm(baslik, mesaj, icon, function () {
        vm.$connection.get(
          "/acenteler/durumDegistir/" + vm.acente.Id + "/" + durum,
          function () {
            vm.$alert.messageWithFunction(
              "Sonuç",
              "İşlem tamamlandı.",
              "success",
              function () {
                vm.onDetay();
              }
            );
          }
        );
      });
    },
    onSubmitSozlesme(event) {
      event.preventDefault();

      let isValid = this.sozlesme_state;

      if (isValid) {
        let vm = this;

        let form = {
          Sozlesme: vm.acente.RehberSozlesmesi,
        };

        vm.$connection.post(
          "/acenteler/" + vm.acente.Id + "/rehberSozlesme",
          form,
          "put",
          function (response) {
            if (response.Success) {
              vm.$alert.messageWithTitle(
                "Rehber Sözleşmesi",
                "Sözleşme güncellendi.",
                "success"
              );
            }
          }
        );
      }
    },
    onSubmitAracSozlesme(event) {
      event.preventDefault();

      let isValid = this.arac_sozlesme_state;

      if (isValid) {
        let vm = this;

        let form = {
          Sozlesme: vm.acente.AracSozlesmesi,
        };

        vm.$connection.post(
          "/acenteler/" + vm.acente.Id + "/aracSozlesme",
          form,
          "put",
          function (response) {
            if (response.Success) {
              vm.$alert.messageWithTitle(
                "Araç Sözleşmesi",
                "Sözleşme güncellendi.",
                "success"
              );
            }
          }
        );
      }
    },
    acenteMekanList() {
      let vm = this;
      let url = "/acenteler/" + this.acente.Id + "/mekanlar";

      this.$connection.get(url, function (response) {
        vm.acenteMekanlari = response;
      });
    },
    folyoList() {
      this.isFolyoLoad = true;
      // let vm = this;

      // let filtre = {};
      // this.$connection.post(
      //   "/acenteler/folyo/filtre/" + this.acente.Id,
      //   filtre,
      //   "post",
      //   function (response) {
      //     vm.folyolar = response;
      //     vm.isFolyoLoad = true;
      //   }
      // );
    },
    folyoKayitEklendi(){
      this.onDetay();
    },
    kullaniciList() {
      let vm = this;

      this.$connection.get(
        "/acenteler/" + this.acente.Id + "/kullanicilar",
        function (response) {
          vm.kullanicilar = response;
          vm.isKullaniciLoad = true;
        }
      );
    },
    onSubmitBelge() {
      this.onDetay();
    },
  },
};
</script>
