<template>
  <div class="card rezervasyon-form">
    <div class="card-body">
      <form-wizard color="#4466f2" :start-index="mutateTab" back-button-text="Geri Dön" next-button-text="İleri"
        :finish-button-text="finishButtonText" @on-complete="onComplete()" @on-validate="mergePartialModels"
        @on-change="wizardTabChange" ref="rezervasyonWizard" scope="props">
        <div slot="title">
          <div class="pull-right">
            <b-button @click="cikis()" variant="dark"><i class="fa fa-times"></i></b-button>
          </div>
        </div>
        <tab-content title="Tur" :before-change="() => validateStep('tur')" @on-validate="mergePartialModels">
          <div class="setup-content" id="step-1">
            <b-alert show variant="danger" class="alert dark" v-if="turValidMessage != ''">
              <p>{{ turValidMessage }}</p>
            </b-alert>
            <div class="row mega-inline">
              <div class="col-md-12">
                <b-form-group class="mb-3">
                  <div class="row">
                    <div class="col-sm-6" v-for="tur in turlar" :key="tur.Id">
                      <div :class="'card card-' + tur.Renk">
                        <div class="media p-20">
                          <div class="radio">
                            <b-form-radio name="TurId" @change="turDegistir(tur)" v-model="kayit.TurId" :value="tur.Id">
                              <div class="media-body">
                                <h6 class="mt-0 mega-title-badge">
                                  {{ tur.Adi }}
                                  <span :class="'badge badge-' +
        tur.Renk +
        ' pull-right digits'
        ">{{ tur.TipiStr }}</span>
                                </h6>
                              </div>
                            </b-form-radio>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </b-form-group>

                <h4 class="text-center" v-if="turOpsiyonlari.length > 0">
                  <b-badge :variant="activeTur.Renk">{{ activeTur.Adi }} OPSİYONLARI</b-badge>
                </h4>

                <b-form-group class="mt-3">
                  <div class="row">
                    <div class="col-sm-6" v-for="opsiyon in turOpsiyonlari" :key="opsiyon.Id">
                      <div class="card">
                        <div class="media p-20 tur-opsiyon-zemin">
                          <div :class="'radio radio-' + activeTur.Renk">
                            <b-form-radio name="radio3" @change="opsiyonDegistir(opsiyon)" v-model="kayit.TurOpsiyonId"
                              :value="opsiyon.Id">
                              <div class="media-body">
                                <h6 class="mt-0 mega-title-badge">
                                  {{ opsiyon.Adi }}
                                  <span v-if="opsiyon.Fiyat > 0" :class="'badge badge-' +
        activeTur.Renk +
        ' pull-right digits'
        ">
                                    <c-money-cell :fiyat="opsiyon.Fiyat" simge="TL"></c-money-cell>
                                  </span>
                                </h6>
                                <p v-html="opsiyon.Aciklama"></p>
                              </div>
                            </b-form-radio>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </b-form-group>

                <b-modal id="opsiyonDetay" size="lg" centered :title="activeOpsiyon.Adi" ok-title="Tamam"
                  cancel-title="Kapat" class="theme-modal">
                  <div class="row" v-if="activeOpsiyonDetay.RestoranAdi !== null">
                    <div class="col-md-12 mb-3">
                      <h5>
                        Restoran
                        <b-badge variant="warning">{{
        activeOpsiyonDetay.RestoranAdi
      }}</b-badge>
                      </h5>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12">
                      <h3>Müzeler</h3>
                      <b-list-group>
                        <b-list-group-item v-for="muze in activeOpsiyonDetay.Muzeler" :key="muze.Id" tag="a" href="#"
                          class="flex-column align-items-start">
                          <div class="d-flex w-100 justify-content-between">
                            <h5 class="mb-1">{{ muze.Tanim }}</h5>
                          </div>
                          <p class="mb-1">
                            Giriş Ücreti :
                            <c-money-cell :fiyat="muze.GirisUcreti" simge="TL"></c-money-cell>
                          </p>
                        </b-list-group-item>
                      </b-list-group>
                    </div>
                  </div>
                </b-modal>
              </div>
            </div>
          </div>
        </tab-content>
        <tab-content title="Detay" :before-change="() => validateStep('detay')">
          <div class="setup-content" id="step-2">
            <b-alert show variant="danger" class="alert dark" v-if="detayValidMessage != ''">
              <p>{{ detayValidMessage }}</p>
            </b-alert>
            <div class="row">
              <div class="col-md-12">
                <div class="form-row">
                  <div class="col-md-6 mb-3">
                    <label for="kayit_tarih">Tarih</label>
                    <datepicker :language="tr" input-class="datepicker-here form-control digits" id="kayit_tarih"
                      v-model="kayit.Tarih" :clear-button="true" clear-button-icon="fa fa-times"
                      placeholder="Tur Tarihi" :format="date_format" :state="kayit_tarih_state">
                    </datepicker>
                    <b-form-invalid-feedback :state="kayit_tarih_state">Tur tarihini giriniz</b-form-invalid-feedback>
                  </div>
                  <div class="col-md-6 mb-3">
                    <label for="kayit_saat">Saat</label>
                    <b-form-input type="time" v-model="kayit.Saat" id="kayit_saat"
                      :state="kayit_saat_state"></b-form-input>
                    <b-form-invalid-feedback :state="kayit_saat_state">Tur saatini giriniz</b-form-invalid-feedback>
                  </div>
                </div>
                <div class="form-row">
                  <div class="col-md-12 mb-3">
                    <label for="kayit_aciklama">Açıklama</label>
                    <b-form-textarea id="kayit_aciklama" v-model="kayit.Aciklama"
                      placeholder="Açıklama"></b-form-textarea>
                  </div>
                </div>
                <div class="form-row">
                  <div class="col-md-6 mb-3">
                    <label for="kayit_oteladi">Otel Adı</label>
                    <b-form-input type="text" id="kayit_oteladi" v-model="kayit.OtelAdi" :state="kayit_oteladi_state"
                      placeholder="Otel Adı"></b-form-input>
                    <b-form-invalid-feedback :state="kayit_oteladi_state">Otel adını giriniz</b-form-invalid-feedback>
                  </div>
                  <div class="col-md-6 mb-3">
                    <label for="kayit_dilid">Dil</label>
                    <b-form-select id="kayit_dilid" v-model="kayit.DilId" :options="kayit_dil_options"
                      :state="kayit_dilid_state"></b-form-select>
                    <b-form-invalid-feedback :state="kayit_dilid_state">Turun dilini seçiniz</b-form-invalid-feedback>
                  </div>
                </div>
                <div class="form-row">
                  <div class="col-md-6 mb-3">
                    <label for="kayit_oteladres">Otel Adresi</label>
                    <b-form-input type="text" id="kayit_oteladres" v-model="kayit.OtelAdresi"
                      placeholder="Otel Adresi"></b-form-input>
                  </div>
                </div>
                <hr />
                <div class="form-row">
                  <div class="col-md-3 mb-5 b-primary b-r-3 p-2">
                    <h4 class="f-w-600">Misafirler Ekle</h4>
                    <div class="form-row">
                      <div class="col-md-12 mb-3">
                        <label for="kayit_yolcu_pasaport">Pasaport No / T.C. Kimlik No</label>
                        <b-form-input type="text" id="kayit_yolcu_pasaport" v-model="misafirKayit.PasaportNo"
                          placeholder="Pasaport No / T.C. Kimlik No"
                          :state="misafirekle_pasaportno_state"></b-form-input>
                        <b-form-invalid-feedback :state="misafirekle_pasaportno_state">Pasaport veya T.C. kimlik
                          numarasını
                          giriniz.</b-form-invalid-feedback>
                      </div>
                    </div>
                    <div class="form-row">
                      <div class="col-md-12 mb-3">
                        <label for="kayit_yolcu_adsoyad">Adı Soyadı</label>
                        <b-form-input type="text" id="kayit_yolcu_adsoyad" v-model="misafirKayit.AdSoyad"
                          placeholder="Adı Soyadı" :state="misafirekle_adsoyad_state"></b-form-input>
                        <b-form-invalid-feedback :state="misafirekle_adsoyad_state">Misafirin adını ve soyadını
                          giriniz.</b-form-invalid-feedback>
                      </div>
                    </div>
                    <div class="form-row">
                      <div class="col-md-12 mb-3">
                        <label for="kayit_yolcu_uyruk">Uyruk</label>
                        <multiselect id="kayit_yolcu_uyruk" v-model="misafirKayit.UyrukSelect"
                          tag-placeholder="Uyruk Seçiniz" placeholder="Uyruk seçiniz" label="text" selectLabel=""
                          deselectLabel="" selectedLabel="" track-by="value" :options="ulkeler"></multiselect>
                        <b-form-invalid-feedback :state="misafirekle_uyruk_state">Misafirin uyruğunu
                          seçiniz.</b-form-invalid-feedback>
                      </div>
                    </div>
                    <div class="form-row">
                      <div class="col-md-12 mb-3">
                        <label for="kayit_yolcu_yas">Yaş</label>
                        <b-form-select id="kayit_yolcu_yas" v-model="misafirKayit.Yas" :options="yas_options"
                          :state="misafirekle_yas_state"></b-form-select>
                        <b-form-invalid-feedback :state="misafirekle_yas_state">Misafirin yaş durumunu
                          seçiniz.</b-form-invalid-feedback>
                      </div>
                    </div>
                    <div class="form-row">
                      <div class="col-md-12 mb-3">
                        <label for="kayit_yolcu_telefon">Telefon</label>
                        <b-form-input type="text" id="kayit_yolcu_telefon" v-model="misafirKayit.Telefon"
                          placeholder="Telefon"></b-form-input>
                      </div>
                    </div>
                    <b-button type="button" @click="misafirEkle()" variant="info">Ekle</b-button>
                  </div>
                  <div class="col-md-9 mb-5">
                    <h4 class="f-w-600">
                      Misafirler
                      <b-badge variant="primary">{{ kayit.Misafirler.length }} kişi</b-badge>
                    </h4>

                    <div class="alert alert-info" v-if="kayit.Misafirler.length === 0">
                      Bu Tur İçin Misafir Girilmemiş
                    </div>

                    <div class="form-row">
                      <div class="col-md-4" v-for="row in kayit.Misafirler" v-bind:key="row.Id">
                        <div class="mb-2 b-dark b-r-3 p-2">
                          <div class="form-row">
                            <div class="col-md-12 mb-3">
                              <label for="kayit_yolcu_pasaport">Pasaport No / T.C. Kimlik No</label>
                              <b-form-input type="text" v-model="row.PasaportNo"
                                placeholder="Pasaport No / T.C. Kimlik No"></b-form-input>
                            </div>
                          </div>
                          <div class="form-row">
                            <div class="col-md-12 mb-3">
                              <label for="kayit_yolcu_adsoyad">Adı Soyadı</label>
                              <b-form-input type="text" v-model="row.AdSoyad" placeholder="Adı Soyadı"></b-form-input>
                            </div>
                          </div>
                          <div class="form-row">
                            <div class="col-md-12 mb-3">
                              <label for="kayit_yolcu_uyruk">Uyruk</label>
                              <multiselect v-model="row.UyrukSelect" tag-placeholder="Uyruk Seçiniz"
                                placeholder="Uyruk seçiniz" :id="row.Id" label="text" track-by="value"
                                :options="ulkeler" selectLabel="" deselectLabel="" selectedLabel="">
                              </multiselect>
                            </div>
                          </div>
                          <div class="form-row">
                            <div class="col-md-12 mb-3">
                              <label for="kayit_yolcu_yas">Yaş</label>
                              <b-form-select v-model="row.Yas" :options="yas_options"></b-form-select>
                            </div>
                          </div>
                          <div class="form-row">
                            <div class="col-md-12 mb-3">
                              <label for="kayit_yolcu_telefon">Telefon</label>
                              <b-form-input type="text" v-model="row.Telefon" placeholder="Telefon"></b-form-input>
                            </div>
                          </div>
                          <b-button type="button" @click="misafirKaldir(row)" variant="danger">Kaldır</b-button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </tab-content>
        <tab-content title="Rehber" :before-change="() => validateStep('rehber')">
          <div class="setup-content" id="step-3">
            <b-alert show variant="danger" class="alert dark" v-if="rehberValidMessage != ''">
              <p>{{ rehberValidMessage }}</p>
            </b-alert>
            <b-alert :show="rehberler === null" variant="info" class="alert dark">
              <p>Rehberler Yükleniyor.</p>
            </b-alert>
            <b-alert :show="rehberler !== null && rehberler.length === 0" variant="warning" class="alert dark">
              <p>Şu an uygun rehber maalesef bulunmamaktadır.</p>
            </b-alert>
            <div class="row tur-rez-form-rehber">
              <div class="col-md-2 col-md-2-special" :if="rehberler !== null" v-for="rehber in rehberler"
                :key="rehber.Id">
                <b-card header-tag="div" header-class="p-3"
                  :bg-variant="rehber.Id === kayit.RehberId ? 'dark' : 'light'" :header-bg-variant="rehber.Id === kayit.RehberId ? 'primary' : 'light'
        " body-class="p-1">
                  <h5 slot="header">
                    <b-link :class="rehber.Id === kayit.RehberId
        ? 'text-white'
        : 'text-dark'
        " @click="rehberSec(rehber)">
                      {{ rehber.AdSoyad }}
                    </b-link>
                  </h5>
                  <div class="product-box">
                    <div class="product-img">
                      <img class="img-fluid" :src="rehber.Resim" alt />
                    </div>
                    <div class="product-details" :class="rehber.Id === kayit.RehberId
        ? 'text-white'
        : 'text-dark'
        ">
                      <p>{{ rehber.Dilleri }}</p>
                      <b-button variant="warning" block @click="rehberDetayAc(rehber)">
                        Özgeçmiş
                      </b-button>
                      <b-button variant="primary" block :disabled="rehber.Id === kayit.RehberId"
                        @click="rehberSec(rehber)">
                        Rehberi Seç
                      </b-button>
                    </div>
                  </div>
                </b-card>
              </div>
            </div>

            <b-modal id="rehberDetay" size="lg" hide-footer centered :title="rehberDetay.AdSoyad" cancel-title="Kapat"
              class="theme-modal">
              <div class="row">
                <div class="col-md-12">
                  <span v-html="rehberDetay.Ozgecmis"></span>

                  <b-button class="mt-3" block @click="$bvModal.hide('rehberDetay')">Kapat</b-button>
                </div>
              </div>
            </b-modal>
          </div>
        </tab-content>
        <tab-content title="Araç" :before-change="() => validateStep('arac')">
          <div class="setup-content" id="step-4">
            <b-alert show variant="danger" class="alert dark" v-if="aracValidMessage != ''">
              <p>{{ aracValidMessage }}</p>
            </b-alert>
            <b-alert :show="araclar === null" variant="info" class="alert dark">
              <p>Araçlar Yükleniyor.</p>
            </b-alert>
            <b-alert :show="araclar !== null && araclar.length === 0" variant="warning" class="alert dark">
              <p>Şu an uygun araç maalesef bulunmamaktadır.</p>
            </b-alert>
            <div class="row tur-rez-form-arac">
              <div class="col-md-2 col-md-2-special" :if="araclar !== null" v-for="arac in araclar" :key="arac.Id">
                <b-card header-tag="div" header-class="p-3" :bg-variant="arac.Id === kayit.AracId ? 'dark' : 'light'"
                  :header-bg-variant="arac.Id === kayit.AracId ? 'primary' : 'light'
        " body-class="p-1">
                  <h5 slot="header">
                    <b-link :class="arac.Id === kayit.AracId ? 'text-white' : 'text-dark'
        " @click="aracSec(arac)">
                      {{ arac.SoforAdi }}
                    </b-link>
                    <div class="pull-right">
                      <b-badge v-if="arac.isWifi" variant="warning">
                        <i class="fa fa-wifi" aria-hidden="true" style="font-size: 14px"></i>
                      </b-badge>
                      <b-badge v-if="arac.isAracTakip" variant="warning">
                        <i class="fa fa-location-arrow" aria-hidden="true" style="font-size: 14px"></i>
                      </b-badge>
                    </div>
                  </h5>
                  <div class="product-box">
                    <div class="product-img">
                      <b-carousel :id="'carousel-' + arac.Id" :interval="4000" controls indicators background="#ababab"
                        img-width="1024" img-height="480" style="text-shadow: 1px 1px 2px #333">
                        <b-carousel-slide v-for="(resim, index) in arac.Resimler" :key="index"
                          :img-src="resim"></b-carousel-slide>
                      </b-carousel>
                    </div>
                    <div class="product-details">
                      <b-button class="primary" :disabled="arac.Id === kayit.AracId" @click="aracSec(arac)">
                        Aracı Seç
                      </b-button>
                    </div>
                  </div>
                </b-card>
              </div>
            </div>
          </div>
        </tab-content>
        <tab-content title="Önizleme" :before-change="() => validateStep('onizleme')">
          <div class="setup-content" id="step-5" v-if="turHesapla.Detay !== undefined">
            <div class="row">
              <div class="col-md-6">
                <b-card header-tag="div" :header-bg-variant="activeTur.Renk">
                  <h5 slot="header">{{ activeTur.Adi }}</h5>
                  <h5>{{ activeOpsiyon.Adi }}</h5>
                  <h4 v-if="activeOpsiyon.Fiyat > 0">
                    {{ activeOpsiyon.Fiyat }} ₺
                  </h4>
                  <p v-html="activeOpsiyon.Aciklama"></p>
                  <table class="product-page-width">
                    <tbody>
                      <tr>
                        <th>Toplam Tutar</th>
                        <td>
                          <b-badge variant="success"><c-money-cell :fiyat="turHesapla.ToplamTutar"
                              simge="TL"></c-money-cell></b-badge>
                        </td>
                      </tr>
                      <tr v-if="turHesapla.TurTutar > 0">
                        <th>Tur Toplamı</th>
                        <td>
                          <b-badge variant="warning"><c-money-cell :fiyat="turHesapla.TurTutar"
                              simge="TL"></c-money-cell></b-badge>
                        </td>
                      </tr>
                      <tr>
                        <th>Araç Tutar</th>
                        <td>
                          <b-badge variant="info"><c-money-cell :fiyat="turHesapla.AracTutar"
                              simge="TL"></c-money-cell></b-badge>
                        </td>
                      </tr>
                      <tr>
                        <th>Rehber Tutar</th>
                        <td>
                          <b-badge variant="dark"><c-money-cell :fiyat="turHesapla.RehberTutar"
                              simge="TL"></c-money-cell></b-badge>
                        </td>
                      </tr>
                      <tr>
                        <th>Restoran Tutar</th>
                        <td>
                          <b-badge variant="secondary"><c-money-cell :fiyat="turHesapla.RestoranTutar"
                              simge="TL"></c-money-cell></b-badge>
                        </td>
                      </tr>
                      <tr>
                        <th>Müze Tutar</th>
                        <td>
                          <b-badge variant="info"><c-money-cell :fiyat="turHesapla.MuzeTutar"
                              simge="TL"></c-money-cell></b-badge>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </b-card>

                <div class="row">
                  <div class="col-md-6">
                    <b-card header-tag="div" :header-bg-variant="activeTur.Renk">
                      <h5 slot="header">
                        {{ activeRehber.AdSoyad }}
                      </h5>
                      <div class="product-box">
                        <div class="product-img">
                          <img class="img-fluid" :src="activeRehber.Resim" alt />
                        </div>
                      </div>
                    </b-card>
                  </div>
                  <div class="col-md-6">
                    <b-card header-tag="div" :header-bg-variant="activeTur.Renk">
                      <h5 slot="header">
                        {{ activeArac.SoforAdi }}
                        <div class="pull-right">
                          <b-badge v-if="activeArac.isWifi" variant="warning">
                            <i class="fa fa-wifi" aria-hidden="true" style="font-size: 14px"></i>
                          </b-badge>
                          <b-badge v-if="activeArac.isAracTakip" variant="warning">
                            <i class="fa fa-location-arrow" aria-hidden="true" style="font-size: 14px"></i>
                          </b-badge>
                        </div>
                      </h5>
                      <div class="product-box">
                        <div class="product-img">
                          <img class="img-fluid" v-if="activeArac.Resimler != null &&
        activeArac.Resimler.length > 0
        " :src="activeArac.Resimler[0]" alt />
                        </div>
                      </div>
                    </b-card>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <h5>Tur Detayları</h5>

                <b-card header-tag="div" header-bg-variant="success">
                  <h5 slot="header">Anlaşmalı Mağazalar</h5>
                  <b-list-group>
                    <b-list-group-item v-for="mekan in turHesapla.Detay.Mekanlar" :key="mekan.Id" tag="a" href="#"
                      class="flex-column align-items-start">
                      <div class="d-flex w-100 justify-content-between">
                        <h5 class="mb-1">
                          {{ mekan.MekanAdi }}
                          <small>{{ mekan.KategoriAdi }}</small>
                        </h5>
                      </div>
                      <p class="mb-1" v-if="mekan.KomisyonOrani > 0">
                        Komisyon Oranı : %{{ mekan.KomisyonOrani }}
                      </p>
                    </b-list-group-item>
                  </b-list-group>
                </b-card>

                <b-card header-tag="div" header-bg-variant="success" v-if="turHesapla.Detay.Restoran !== null">
                  <h5 slot="header">Restoran</h5>
                  <b-list-group v-if="turHesapla.Detay.Restoran.RestoranAdi !== ''">
                    <b-list-group-item tag="a" href="#" class="flex-column align-items-start">
                      <div class="d-flex w-100 justify-content-between">
                        <h5 class="mb-1">
                          {{ turHesapla.Detay.Restoran.RestoranAdi }}
                        </h5>
                      </div>
                      <p class="mb-1" v-html="turHesapla.Detay.Restoran.RestoranAciklama"></p>
                    </b-list-group-item>
                  </b-list-group>
                </b-card>

                <b-card header-tag="div" header-bg-variant="success" v-if="turHesapla.Detay.Muzeler.length > 0">
                  <h5 slot="header">Müzeler</h5>
                  <b-list-group>
                    <b-list-group-item v-for="muze in turHesapla.Detay.Muzeler" :key="muze.Id" tag="a" href="#"
                      class="flex-column align-items-start">
                      <div class="d-flex w-100 justify-content-between">
                        <h5 class="mb-1">{{ muze.MuzeAdi }}</h5>
                      </div>
                    </b-list-group-item>
                  </b-list-group>
                </b-card>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <b-card header-tag="div" v-if="turHesapla.TurTutar > 0" :header-bg-variant="primary" body-class="p-3">
                  <h5 slot="header">Tur Opsiyon Tutarları</h5>
                  <b-table striped hover :fields="opsiyonTutarFields" :items="turHesapla.Detay.OpsiyonTutarlar">
                    <template #cell(YasStr)="row">
                      <b-badge variant="primary">{{ row.item.YasStr }}</b-badge>
                    </template>
                    <template #cell(Oran)="data">
                      <b-badge variant="primary">{{ data.value }}</b-badge>
                    </template>
                    <template #cell(Fiyat)="data">
                      <c-money-cell :fiyat="data.value" v-if="data.value !== 0" simge="TL"></c-money-cell>
                    </template>
                    <template #cell(ToplamFiyat)="data">
                      <c-money-cell :fiyat="data.value" simge="TL"></c-money-cell>
                    </template>
                    <template #cell(AcenteFiyati)="data">
                      <c-aktif-cell :isAktif="data.value"></c-aktif-cell>
                    </template>
                  </b-table>
                </b-card>

                <b-card header-tag="div" :header-bg-variant="primary" body-class="p-3">
                  <h5 slot="header">Müzeler</h5>
                  <b-card class="mb-1" v-for="muze in turHesapla.Detay.Muzeler" v-bind:key="muze.MuzeId"
                    body-class="p-0">
                    <b-card-header header-tag="div" class="bg-primary">
                      <h5 class="mb-0">
                        {{ muze.MuzeAdi }}
                        <small class="digits">Fiyat {{ muze.MuzeFiyatiTL }}TL</small>
                        /
                        <small class="digits">Toplam {{ muze.ToplamTutarTL }}TL</small>
                      </h5>
                    </b-card-header>
                    <b-table striped hover :fields="muzeTutarFields" :items="muze.Tutarlar"></b-table>
                  </b-card>
                </b-card>

                <b-card header-tag="div" :header-bg-variant="primary" body-class="p-3"
                  v-if="turHesapla.Detay.Restoran != null">
                  <h5 slot="header">Restoran</h5>
                  <b-card class="mb-1" body-class="p-0">
                    <b-card-header header-tag="div" class="bg-primary">
                      <h5 class="mb-0">
                        {{ turHesapla.Detay.Restoran.RestoranAdi }}
                      </h5>
                    </b-card-header>
                    <p>
                      <small class="digits">Menü Fiyatı :
                        {{
        $formatHelper.formatMoney(
          turHesapla.Detay.Restoran.MenuSatisFiyati
        )
      }}₺</small>
                      /
                      <small class="digits">Toplam :
                        {{
          $formatHelper.formatMoney(
            turHesapla.Detay.Restoran.ToplamSatis
                        )
                        }}₺</small>
                    </p>
                    <b-table striped hover :fields="restoranTutarFields"
                      :items="turHesapla.Detay.Restoran.Tutarlar"></b-table>
                  </b-card>
                </b-card>
              </div>
            </div>
          </div>
        </tab-content>
      </form-wizard>
    </div>
  </div>
</template>
<script>
import Datepicker from "vuejs-datepicker";
import { tr } from "vuejs-datepicker/dist/locale";
import { BFormTimepicker } from "bootstrap-vue";
import Multiselect from "vue-multiselect";

export default {
  props: {
    rezervasyon: {},
    acenteId: "",
    tab: 0,
    id: undefined,
  },
  data() {
    return {
      mutateTab: this.tab === undefined ? 0 : parseInt(this.tab),

      finishButtonText: "Oluştur",

      turValidMessage: "",
      detayValidMessage: "",
      rehberValidMessage: "",
      aracValidMessage: "",

      date_format: "dd.MM.yyyy",
      tr: tr,

      mutationAcenteId: this.acenteId,

      kayit: {
        ...(this.rezervasyon === undefined
          ? {
            TurId: null,
            EncTurId: null,
            TurOpsiyonId: null,
            EncTurOpsiyonId: null,
            Tarih: null,
            Saat: "09:00",
            Aciklama: null,
            OtelAdi: null,
            OtelAdresi: null,
            DilId: 0,
            EncDilId: null,
            RehberId: null,
            EncRehberId: null,
            AracId: null,
            EncAracId: null,
            KisiSayisi: 0,
            Misafirler: [],
          }
          : this.rezervasyon),
      },

      opsiyonTutarFields: [
        { key: "YasStr", label: "Yaş", sortable: false },
        { key: "KisiSayisi", label: "Kişi Sayısı", sortable: false },
        { key: "Oran", label: "İndirim (%)", sortable: false },
        { key: "Fiyat", label: "Fiyat", sortable: false },
        { key: "ToplamFiyat", label: "Toplam", sortable: false },
        { key: "AcenteFiyati", label: "Acente Ö.F.", sortable: false },
      ],

      muzeTutarFields: [
        { key: "YasStr", label: "Yaş", sortable: false },
        { key: "KisiSayisi", label: "Kişi Sayısı", sortable: false },
        { key: "Oran", label: "İndirim (%)", sortable: false },
        { key: "FiyatTL", label: "Fiyat", sortable: false },
        { key: "ToplamTutarTL", label: "Toplam", sortable: false },
      ],

      restoranTutarFields: [
        { key: "YasStr", label: "Yaş", sortable: false },
        { key: "KisiSayisi", label: "Kişi Sayısı", sortable: false },
        { key: "Oran", label: "İndirim (%)", sortable: false },
        { key: "SatisFiyat", label: "Menü Fiyatı", sortable: false },
        { key: "ToplamSatisFiyat", label: "Toplam", sortable: false },
      ],

      turHesapla: {},

      finalModel: {},

      activeTur: {},
      activeOpsiyon: {},
      activeOpsiyonDetay: {},
      activeRehber: {},
      activeArac: {},

      turlar: [],
      turOpsiyonlari: [],

      yas_options: [],

      kayit_dil_options: [{ value: "0", text: "Seçiniz" }],

      ulkeler: [{ value: "", text: "Uyruk" }],

      misafirKayit: {
        Id: 0,
        PasaportNo: "",
        Uyruk: "",
        UyrukSelect: { value: "", text: "Uyruk" },
        AdSoyad: "",
        Yas: 2,
        Telefon: "",
      },

      rehberler: null,
      araclar: null,

      rehberDetay: {},
    };
  },
  mounted() {
    if (this.rezervasyon === undefined) {
      this.kayit.Tarih = this.addDays(1);
      this.finishButtonText = "Oluştur";
    } else {
      this.finishButtonText = "Düzelt";

      for (let i = 0; i < this.rezervasyon.Misafirler.length; i++) {
        const element = this.rezervasyon.Misafirler[i];
        element.UyrukSelect = { value: element.Uyruk, text: element.Uyruk };
      }
    }

    if (this.acenteId === 0) {
      this.mutationAcenteId = this.$user.acenteId();
    }

    this.turList();
    this.turOpsiyonList(this.kayit.EncTurId);

    if (this.mutateTab > 0) {
      this.wizardTabChange(this.mutateTab - 1, this.mutateTab);
    }

    this.onYasList();
    this.onDilList();
    this.ulkeList();
  },
  components: {
    Datepicker,
    BFormTimepicker,
    Multiselect,
  },
  computed: {
    kayit_turid_state() {
      if (this.kayit.TurId == "" || this.kayit.TurId == null) return false;
      else return true;
    },
    kayit_turopsiyonid_state() {
      if (this.kayit.TurOpsiyonId == "" || this.kayit.TurOpsiyonId == null)
        return false;
      else return true;
    },
    kayit_tarih_state() {
      if (this.kayit.Tarih == "" || this.kayit.Tarih == null) return false;
      else return true;
    },
    kayit_saat_state() {
      if (this.kayit.Saat == "" || this.kayit.Saat == null) return false;
      else return true;
    },
    kayit_oteladi_state() {
      if (this.kayit.OtelAdi == "" || this.kayit.OtelAdi == null) return false;
      else return true;
    },
    kayit_dilid_state() {
      if (this.kayit.DilId == "" || this.kayit.DilId == null) return false;
      else return true;
    },
    kayit_misafir_state() {
      if (this.kayit.Misafirler == null || this.kayit.Misafirler.length == 0)
        return false;
      else return true;
    },
    kayit_rehberid_state() {
      if (this.kayit.RehberId == "" || this.kayit.RehberId == null)
        return false;
      else return true;
    },
    kayit_aracid_state() {
      if (this.kayit.AracId == "" || this.kayit.AracId == null) return false;
      else return true;
    },
    misafirekle_pasaportno_state() {
      if (
        this.misafirKayit.PasaportNo == "" ||
        this.misafirKayit.PasaportNo == null
      )
        return false;
      else return true;
    },
    misafirekle_adsoyad_state() {
      if (this.misafirKayit.AdSoyad == "" || this.misafirKayit.AdSoyad == null)
        return false;
      else return true;
    },
    misafirekle_uyruk_state() {
      if (
        this.misafirKayit.UyrukSelect == null ||
        this.misafirKayit.UyrukSelect.value == ""
      )
        return false;
      else return true;
    },
    misafirekle_yas_state() {
      if (this.misafirKayit.Yas == "" || this.misafirKayit.Yas == null)
        return false;
      else return true;
    },
  },
  methods: {
    isLastStep() {
      if (this.$refs.wizard) {
        return this.$refs.wizard.isLastStep;
      }
      return false;
    },
    formatTarih(tarih) {
      return this.$moment(String(tarih)).format("YYYY-MM-DD");
    },
    validateStep(name) {
      switch (name) {
        case "tur":
          this.turValidMessage = "";
          if (!this.kayit_turid_state) {
            this.turValidMessage = "Tur Seçiniz.";
            return false;
          }
          if (!this.kayit_turopsiyonid_state) {
            this.turValidMessage = "Tur Opsiyonunu Seçiniz.";
            return false;
          }
          break;

        case "detay":
          this.detayValidMessage = "";

          let tomorrow = new Date();
          tomorrow.setDate(tomorrow.getDate() + 1);
          let yarin = this.$formatHelper.formatDate(tomorrow);
          let tarih = this.$formatHelper.formatDate(this.kayit.Tarih);
          if (yarin == tarih) {
            let currentHour = (new Date()).getHours();
            if (currentHour >= 21) {
              this.detayValidMessage = "Rezervasyonunuz için lütfen 0531 728 3404 numaralı Capbook operasyon numarası ile iletişime geçiniz.";
              return false;
            }
          }

          if (!this.kayit_tarih_state) {
            this.detayValidMessage = "Tarih giriniz.";
            return false;
          }
          if (!this.kayit_saat_state) {
            this.detayValidMessage = "Saat giriniz.";
            return false;
          }
          if (!this.kayit_oteladi_state) {
            this.detayValidMessage = "Otel adını giriniz.";
            return false;
          }
          if (!this.kayit_dilid_state) {
            this.detayValidMessage = "Dili seçiniz.";
            return false;
          }
          if (!this.kayit_misafir_state) {
            this.detayValidMessage = "En az bir misafir giriniz.";
            return false;
          }
          break;

        case "rehber":
          this.rehberValidMessage = "";
          if (!this.kayit_rehberid_state) {
            this.rehberValidMessage = "Rehber Seçiniz.";
            return false;
          }
          break;

        case "arac":
          this.aracValidMessage = "";
          if (!this.kayit_aracid_state) {
            this.aracValidMessage = "Araç Seçiniz.";
            return false;
          }
          break;

        default:
          break;
      }
      return true;
    },
    mergePartialModels(model, isValid) {
      return isValid;
    },
    wizardTabChange: function (prevIndex, nextIndex) {
      switch (nextIndex) {
        case 0:
          return true;
        case 2:
          this.onRehberlerList(
            this.kayit.DilId,
            this.formatTarih(this.kayit.Tarih)
          );
          break;
        case 3:
          this.onAraclarList(this.formatTarih(this.kayit.Tarih));
          break;
        case 4:
          this.detayHesapla();
          break;

        default:
          break;
      }
      window.scrollTo({ top: 0, left: 0 });
    },
    addDays(days) {
      let date = new Date();
      date.setDate(date.getDate() + days);
      return date;
    },
    turList() {
      let vm = this;
      this.$connection.get("/turlar/forSale/0", function (response) {
        vm.turlar = response;

        if (vm.kayit.TurId > 0) {
          vm.activeTur = vm.turlar.filter((item) => {
            return item.Id === vm.kayit.TurId;
          })[0];
        }
      });
    },
    turOpsiyonList(turId) {
      if (turId != null) {
        let vm = this;
        this.$connection.get(
          "/turlar/opsiyonlarForSale/" + turId,
          function (response) {
            vm.turOpsiyonlari = response;

            if (vm.rezervasyon !== undefined) {
              //Güncellemeye girmiş aktif opsiyonu bul
              let aktifOpsiyon = vm.turOpsiyonlari.filter((item) => {
                return item.Id === vm.rezervasyon.TurOpsiyonId;
              });
              if (aktifOpsiyon.length > 0) {
                vm.activeOpsiyon = aktifOpsiyon[0];

                vm.opsiyonDetayDoldur();
              }
            }
          }
        );
      }
    },
    turDegistir(tur) {
      this.activeTur = tur;

      this.kayit.TurOpsiyonId = "";

      this.turOpsiyonList(tur.EncId);
    },
    opsiyonDegistir(opsiyon) {
      let vm = this;
      this.$connection.get(
        "/turlar/opsiyonlarGetForSaleById/" + opsiyon.EncId,
        function (response) {
          if (response.Success) {
            //vm.$bvModal.show('opsiyonDetay');
            vm.activeOpsiyon = opsiyon;
            vm.activeOpsiyonDetay = response.Data;
            vm.$refs.rezervasyonWizard.nextTab();
          } else {
            vm.$alert.messageWithTitle("Tur Detay", response.Message, "error");
          }
        }
      );
    },
    opsiyonDetayDoldur() {
      if (this.activeOpsiyon.Id !== undefined) {
        let vm = this;
        this.$connection.get(
          "/turlar/opsiyonlarGetForSaleById/" + this.activeOpsiyon.EncId,
          function (response) {
            if (response.Success) {
              vm.activeOpsiyonDetay = response.Data;
            } else {
              vm.$alert.messageWithTitle(
                "Tur Detay",
                response.Message,
                "error"
              );
            }
          }
        );
      }
    },
    onYasList() {
      let vm = this;

      this.$connection.get("/parametreler/yaslar", function (response) {
        vm.yas_options = response;
      });
    },
    onDilList() {
      let vm = this;

      this.$connection.get("/diller/select", function (response) {
        vm.kayit_dil_options = vm.kayit_dil_options.concat(response);
      });
    },
    ulkeList() {
      let vm = this;
      this.$connection.get("/ulkeler/select", function (response) {
        vm.ulkeler = vm.ulkeler.concat(response);
      });
    },
    misafirEkle() {
      let isValid =
        this.misafirekle_pasaportno_state &&
        this.misafirekle_adsoyad_state &&
        this.misafirekle_uyruk_state &&
        this.misafirekle_yas_state;

      if (isValid) {
        this.misafirKayit.Uyruk = this.misafirKayit.UyrukSelect.value;
        this.misafirKayit.Id = this.kayit.Misafirler.length + 1;

        this.kayit.Misafirler.push(this.misafirKayit);

        this.misafirKayit = {
          Id: 0,
          PasaportNo: "",
          AdSoyad: "",
          Uyruk: this.misafirKayit.UyrukSelect.value,
          UyrukSelect: this.misafirKayit.UyrukSelect,
          Yas: this.misafirKayit.Yas,
          Telefon: this.misafirKayit.Telefon,
        };
      }
    },
    misafirKaldir(misafir) {
      this.$listHelper.removeItem(this.kayit.Misafirler, misafir);
    },
    onRehberlerList(dilId, turTarihi) {
      let vm = this;

      let url =
        "/rehberler/select/" +
        dilId +
        "/" +
        turTarihi +
        "/" +
        this.mutationAcenteId;
      if (this.activeRehber.Id !== undefined) {
        url = url + "?rehberId=" + this.activeRehber.Id;
      } else if (this.rezervasyon !== undefined) {
        url = url + "?rehberId=" + this.rezervasyon.RehberId;
      }

      this.$connection.get(url, function (response) {
        vm.rehberler = response;

        let rehbers = vm.rehberler.filter((item) => {
          return item.Id === vm.kayit.RehberId;
        });
        if (rehbers.length > 0) vm.activeRehber = rehbers[0];
      });
    },
    rehberSec(rehber) {
      this.kayit.RehberId = rehber.Id;
      this.kayit.EncRehberId = rehber.EncId;
      this.activeRehber = rehber;
      this.$refs.rezervasyonWizard.nextTab();
    },
    rehberDetayAc(rehber) {
      this.rehberDetay = rehber;
      this.$bvModal.show("rehberDetay");
    },
    onAraclarList(turTarihi) {
      let vm = this;

      let url = "/araclar/select/" + turTarihi + "/" + this.mutationAcenteId;
      if (this.activeArac.Id !== undefined) {
        url = url + "?aracId=" + this.activeArac.Id;
      } else if (this.rezervasyon !== undefined) {
        url = url + "?aracId=" + this.rezervasyon.AracId;
      } else {
        url = url + "?aracId=0";
      }

      this.$connection.get(url, function (response) {
        vm.araclar = response;

        let aracs = vm.araclar.filter((item) => {
          return item.Id === vm.kayit.AracId;
        });
        if (aracs.length > 0) vm.activeArac = aracs[0];
      });
    },
    aracSec(arac) {
      this.kayit.AracId = arac.Id;
      this.kayit.EncAracId = arac.EncId;
      this.activeArac = arac;
      this.$refs.rezervasyonWizard.nextTab();
    },
    detayHesapla() {
      let vm = this;

      let url = "/turRezervasyonlari/hesapla/" + this.mutationAcenteId;
      let method = "post";
      let messageTitle = "Rezervasyon Detayları Hesapla";

      let kayitClone = { ...this.kayit };

      kayitClone.TurId = this.activeTur.EncId;
      kayitClone.TurOpsiyonId = this.activeOpsiyon.EncId;
      kayitClone.RehberId = kayitClone.EncRehberId;
      kayitClone.AracId = kayitClone.EncAracId;
      kayitClone.KisiSayisi = kayitClone.Misafirler.length;

      for (let i = 0; i < kayitClone.Misafirler.length; i++) {
        const element = kayitClone.Misafirler[i];
        element.Uyruk = element.UyrukSelect.value;
      }

      vm.$connection.post(
        url,
        kayitClone,
        method,
        function (response) {
          if (response.Success) {
            vm.turHesapla = response.Data;
          }
        },
        function (error) {
          vm.$alert.messageWithTitle(messageTitle, error.Message, "error");
        }
      );
    },
    basaDon() {
      this.mutateTab = 0;
    },
    cikis() {
      let vm = this;
      this.$alert.confirm(
        "İptal",
        "Rezervasyondan vazgeçiyorsunuz yapmış olduğunuz değişiklikler kaybolacaktır. Emin misiniz?",
        "error",
        function () {
          if (vm.id == undefined) {
            vm.$router.push("/");
          } else {
            vm.$router.push("/turRezervasyon/detay/" + vm.id);
          }
        }
      );
    },
    onComplete() {
      let isValid =
        this.kayit_turid_state &&
        this.kayit_turopsiyonid_state &&
        this.kayit_tarih_state &&
        this.kayit_saat_state &&
        this.kayit_oteladi_state &&
        this.kayit_dilid_state &&
        this.kayit_rehberid_state &&
        this.kayit_aracid_state;

      if (!isValid) {
        this.$alert.messageWithTitle(
          "Eksik Bilgi",
          "Lütfen rezervasyon bilgilerinizi kontrol ediniz.",
          "error"
        );
      } else {
        this.kayit.TurId = this.activeTur.EncId;
        this.kayit.TurOpsiyonId = this.activeOpsiyon.EncId;
        this.kayit.RehberId = this.kayit.EncRehberId;
        this.kayit.AracId = this.kayit.EncAracId;
        this.kayit.KisiSayisi = this.kayit.Misafirler.length;

        for (let i = 0; i < this.kayit.Misafirler.length; i++) {
          const element = this.kayit.Misafirler[i];
          element.Uyruk = element.UyrukSelect.value;
        }

        this.$emit("onComplete", this.kayit);
      }
    },
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
.vue-form-wizard .wizard-nav-pills>li>a .stepTitle {
  color: #333;
  font-weight: bold;
}

.vue-form-wizard .wizard-btn.wizard-footer-first {
  background-color: #e1e1e1 !important;
  border-color: #e1e1e1 !important;
  color: #333 !important;
}

.vue-form-wizard .wizard-btn.wizard-footer-back {
  background-color: #333 !important;
  border-color: #333 !important;
  color: #fff !important;
}

.vue-form-wizard .wizard-btn.wizard-footer-cancel {
  background-color: #ff5370 !important;
  border-color: #ff5370 !important;
  color: #fff !important;
}

.vue-form-wizard .wizard-btn.wizard-footer-next {
  color: #fff !important;
}

.vue-form-wizard .wizard-btn.wizard-footer-finish {
  background-color: #22af47 !important;
  border-color: #22af47 !important;
  color: #fff !important;
}
</style>
