export default {
    getUser() {
        if (localStorage.getItem('Token') === undefined || localStorage.getItem('Token') === null)
            return null;

        let Token = localStorage.getItem('Token');
        let Kullanici = JSON.parse(localStorage.getItem('KullaniciJson'));

        return {
            Kullanici,
            Token
        };
    },
    setUser(data) {
        localStorage.setItem('KullaniciJson', JSON.stringify(data.Kullanici));
        localStorage.setItem('Token', data.Token);
    },
    setGsmDogrulandi() {
        let user = this.getUser();
        user.Kullanici.isGsmDogrulandi = true;
        this.setUser(user);
    },
    setEmailDogrulandi() {
        let user = this.getUser();
        user.Kullanici.isEmailDogrulandi = true;
        this.setUser(user);
    },
    isYonetim() {
        let _user = this.getUser();
        if (_user.Kullanici.KullaniciTipi == 80 || _user.Kullanici.KullaniciTipi == 85 || _user.Kullanici.KullaniciTipi == 100)
            return true;

        return false;
    },
    isAcente() {
        let _user = this.getUser();
        if (_user.Kullanici.AcenteId != null)
            return true;

        return false;
    },
    isAcenteYoneticisi() {
        let _user = this.getUser();
        if (_user.Kullanici.Tipi == 10)
            return true;

        return false;
    },
    acenteId() {
        let _user = this.getUser();
        if (_user.Kullanici.AcenteId != null)
            return _user.Kullanici.AcenteId;

        return null;
    },
    isRehber() {
        let _user = this.getUser();
        if (_user.Kullanici.RehberId != null)
            return true;

        return false;
    },
    rehberId() {
        let _user = this.getUser();
        if (_user.Kullanici.RehberId != null)
            return _user.Kullanici.RehberId;

        return null;
    },
    isArac() {
        let _user = this.getUser();
        if (_user.Kullanici.AracId != null)
            return true;

        return false;
    },
    aracId() {
        let _user = this.getUser();
        if (_user.Kullanici.AracId != null)
            return _user.Kullanici.AracId;

        return null;
    },
    isAracFirmaYoneticisi() {
        let _user = this.getUser();
        if (_user.Kullanici.Tipi == 30)
            return true;

        return false;
    },
    isAracFirma() {
        let _user = this.getUser();
        if (_user.Kullanici.AracFirmaId != null)
            return true;

        return false;
    },
    aracFirmaId() {
        let _user = this.getUser();
        if (_user.Kullanici.AracFirmaId != null)
            return _user.Kullanici.AracFirmaId;

        return null;
    }
}