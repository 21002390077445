<template>
  <div>
    <Breadcrumbs main="Balon Rezervasyonları" title="Liste" />

    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <div class="default-according">
            <b-card no-body class="mb-1">
              <b-card-header header-tag="div" role="tab">
                <h5 class="mb-0">
                  <b-button v-b-toggle.simpleaccordion-1 variant="default"
                    >Filtre</b-button
                  >
                </h5>
              </b-card-header>
              <b-collapse
                id="simpleaccordion-1"
                accordion="myaccordion"
                role="tabpanel"
              >
                <b-card-body>
                  <b-form @submit="onFiltreSubmit">
                    <div class="form-row">
                      <div class="col-md-6 mb-3">
                        <label for="filtre_kelime">Kelime</label>
                        <b-form-input
                          type="text"
                          id="filtre_kelime"
                          v-model="filtre.Kelime"
                          placeholder="Kelime"
                        ></b-form-input>
                      </div>
                      <div class="col-md-6 mb-3">
                        <label for="filtre_kod">Rezervasyon Kodu</label>
                        <b-form-input
                          type="text"
                          id="filtre_kod"
                          v-model="filtre.Kod"
                          placeholder="Rezervasyon Kodu"
                        ></b-form-input>
                      </div>
                    </div>
                    <div class="form-row">
                      <div class="col-md-6 mb-3">
                        <label for="filtre_baslangictarihi"
                          >Başlangıç Tarihi</label
                        >
                        <datepicker
                          :language="tr"
                          input-class="datepicker-here form-control digits"
                          id="filtre_baslangictarihi"
                          v-model="filtre.BaslangicTarihi"
                          :clear-button="true"
                          clear-button-icon="fa fa-times"
                          placeholder="Başlangıç Tarihi"
                          :format="date_format"
                        >
                        </datepicker>
                      </div>
                      <div class="col-md-6 mb-3">
                        <label for="filtre_bitistarihi">Bitiş Tarihi</label>
                        <datepicker
                          :language="tr"
                          input-class="datepicker-here form-control digits"
                          id="filtre_bitistarihi"
                          v-model="filtre.BitisTarihi"
                          :clear-button="true"
                          clear-button-icon="fa fa-times"
                          placeholder="Bitiş Tarihi"
                          :format="date_format"
                        ></datepicker>
                      </div>
                    </div>
                    <div class="form-row">
                      <div class="col-md-6 mb-3">
                        <label for="filtre_acente">Acente</label>
                        <b-form-select
                          id="filtre_acente"
                          v-model="filtre.AcenteId"
                          :options="filtre_acenteler"
                        ></b-form-select>
                      </div>
                      <div class="col-md-6 mb-3">
                        <label for="filtre_durum">Durum</label>
                        <b-form-select
                          id="filtre_durum"
                          v-model="filtre.Durum"
                          :options="filtre_durumlar"
                        ></b-form-select>
                      </div>
                    </div>
                    <div class="form-row">
                      <div class="col-md-6 mb-3">
                        <label for="filtre_tur">Turlar</label>
                        <b-form-select
                          id="filtre_acente"
                          v-model="filtre.TurId"
                          :options="filtre_turlar"
                        ></b-form-select>
                      </div>
                      <div class="col-md-6 mb-3">
                        <label for="filtre_turOpsiyon">Tur Opsiyonu</label>
                        <b-form-select
                          id="filtre_turOpsiyon"
                          v-model="filtre.TurOpsiyonId"
                          :options="filtre_turOpsiyonlari"
                        ></b-form-select>
                      </div>
                    </div>
                    <div class="form-row">
                      <div class="col-md-6 mb-3">
                        <label for="filtre_rehber">Rehber</label>
                        <b-form-select
                          id="filtre_rehber"
                          v-model="filtre.RehberId"
                          :options="filtre_rehberler"
                        ></b-form-select>
                      </div>
                      <div class="col-md-6 mb-3">
                        <label for="filtre_dil">Dil</label>
                        <b-form-select
                          id="filtre_dil"
                          v-model="filtre.DilId"
                          :options="filtre_diller"
                        ></b-form-select>
                      </div>
                    </div>
                    <div class="form-row">
                      <div class="col-md-6 mb-3">
                        <label for="filtre_aracFirma">Araç Firma</label>
                        <b-form-select
                          id="filtre_aracFirma"
                          v-model="filtre.AracFirmaId"
                          :options="filtre_aracFirmalari"
                        ></b-form-select>
                      </div>
                      <div class="col-md-6 mb-3">
                        <label for="filtre_arac">Araç</label>
                        <b-form-select
                          id="filtre_arac"
                          v-model="filtre.AracId"
                          :options="filtre_araclar"
                        ></b-form-select>
                      </div>
                    </div>
                    <div class="form-row">
                      <div class="col-md-6 mb-3">
                        <label for="filtre_aracTipi">Araç Tipi</label>
                        <b-form-select
                          id="filtre_aracTipi"
                          v-model="filtre.AracTipiId"
                          :options="filtre_aracTipleri"
                        ></b-form-select>
                      </div>
                      <div class="col-md-6 mb-3">
                        <b-button type="submit" variant="warning"
                          >Listele</b-button
                        >
                      </div>
                    </div>
                  </b-form>
                </b-card-body>
              </b-collapse>
            </b-card>
          </div>

          <div class="card">
            <div class="card-body">
              <c-table :tablefields="tablefields" :items="items">
                <template #cell(Islem)="row">
                  <b-btn-group>
                    <router-link
                      :to="`/turRezervasyon/detay/${row.item.Id}`"
                      class="btn btn-success btn-xs"
                      >Detay</router-link
                    >
                  </b-btn-group>
                </template>
                <template #cell(Tarih)="row">
                  <c-tarih-cell
                    :kayitTarihi="row.item.Tarih"
                    :rowKey="row.item.Id"
                  ></c-tarih-cell>
                  <b-badge variant="primary">{{ row.item.Saat }}</b-badge>
                </template>
                <template #cell(ToplamTutar)="data">
                  <c-money-cell :fiyat="data.value" simge="TL"></c-money-cell>
                </template>
                <template #cell(KisiSayisi)="data">
                  <b-badge variant="primary">{{ data.value }}</b-badge>
                </template>
                <template #cell(SoforAdi)="row">
                  {{ row.item.SoforAdi }}
                  <b-badge variant="primary">{{ row.item.Plaka }}</b-badge>
                  <b-badge variant="warning">{{
                    row.item.AracTipiAdi
                  }}</b-badge>
                </template>
                <template #cell(KayitTarihi)="row">
                  <c-tarih-cell
                    :kayitTarihi="row.item.KayitTarihi"
                    :guncelTarih="row.item.GuncelTarih"
                    :rowKey="row.item.Id"
                  ></c-tarih-cell>
                </template>
              </c-table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Datepicker from "vuejs-datepicker";
import { tr } from "vuejs-datepicker/dist/locale";

export default {
  data() {
    return {
      tablefields: [
        { key: "Islem", label: "İşlemler", sortable: false },
        { key: "AcenteAdi", label: "Acente", sortable: true },
        { key: "Kod", label: "Kod", sortable: true },
        { key: "Tarih", label: "Tarih", sortable: true },
        { key: "OtelAdi", label: "Otel", sortable: true },
        { key: "DurumStr", label: "Durum", sortable: true },
        { key: "ToplamTutar", label: "Toplam", sortable: true },
        { key: "KisiSayisi", label: "Kişi", sortable: true },
        { key: "TurAdi", label: "Tur", sortable: true },
        { key: "TurOpsiyonAdi", label: "Opsiyon", sortable: true },
        { key: "RehberAdi", label: "Rehber", sortable: true },
        { key: "SoforAdi", label: "Şoför", sortable: true },
        { key: "KullaniciAdi", label: "Kullanıcı", sortable: true },
        { key: "KayitTarihi", label: "Kayıt Tarihi", sortable: true },
      ],

      items: [],

      date_format: "dd.MM.yyyy",
      tr: tr,

      filtre_durumlar: [
        { value: null, text: "Durumlar" },
        { value: "0", text: "Yeni" },
        { value: "20", text: "Pasif" },
        { value: "30", text: "Onaylı" },
        { value: "100", text: "İptal" },
      ],

      filtre_acenteler: [{ value: null, text: "Acenteler" }],

      filtre_turlar: [{ value: null, text: "Turlar" }],

      filtre_turOpsiyonlari: [{ value: null, text: "Tur Opsiyonları" }],

      filtre_rehberler: [{ value: null, text: "Rehberler" }],

      filtre_diller: [{ value: null, text: "Diller" }],

      filtre_aracFirmalari: [{ value: null, text: "Araç Firmaları" }],

      filtre_araclar: [{ value: null, text: "Araçlar" }],

      filtre_aracTipleri: [{ value: null, text: "Araç Tipleri" }],

      filtre: {
        AcenteId: null,
        TurId: null,
        TurOpsiyonId: null,
        Kod: null,
        BaslangicTarihi: null,
        BitisTarihi: null,
        Kelime: null,
        Durum: null,
        RehberId: null,
        AracFirmaId: null,
        AracId: null,
        DilId: null,
        AracTipiId: null,
      },
    };
  },
  components: {
    Datepicker,
  },
  mounted() {
    let bitisTarihi = new Date();
    let baslangicTarih = new Date();
    baslangicTarih.setDate(bitisTarihi.getDate() - 15);
    bitisTarihi.setDate(bitisTarihi.getDate() + 5);
    this.filtre.BaslangicTarihi = baslangicTarih;
    this.filtre.BitisTarihi = bitisTarihi;

    //this.onList();
  },
  methods: {
    onFiltreSubmit(evt) {
      evt.preventDefault();
      this.onList();
    },
    onList() {
      let vm = this;

      vm.filtre.MinBorc = vm.filtre.MinBorc === "" ? null : vm.filtre.MinBorc;
      vm.filtre.MaxBorc = vm.filtre.MaxBorc === "" ? null : vm.filtre.MaxBorc;

      this.$connection.post(
        "/turRezervasyonlari/filtre",
        vm.filtre,
        "post",
        function (response) {
          vm.items = response;
        }
      );
    },
  },
};
</script>
