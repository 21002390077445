<template>
  <div>
    <Breadcrumbs
      main="Balon Rezervasyonu"
      :title="pageTitle"
      :links="breadLinks"
    />

    <div class="container-fluid tur-rezervasyon-detay">
      <div class="row">
        <div class="col-md-12">
          <ul
            class="nav nav-tabs border-tab tabs-scoial"
            id="top-tab"
            role="tablist"
          >
            <li class="nav-item" v-on:click="changetab('tur')">
              <a
                class="nav-link"
                :class="{ 'show active': showtab == 'tur' }"
                id="top-tur"
                data-toggle="tab"
                role="tab"
                aria-controls="tur"
                aria-selected="true"
                >Rezervasyon</a
              >
            </li>
            <li class="nav-item" v-on:click="changetab('misafir')">
              <a
                class="nav-link"
                :class="{ 'show active': showtab == 'misafir' }"
                id="top-misafir"
                data-toggle="tab"
                role="tab"
                aria-controls="misafir"
                aria-selected="false"
                >Misafirler</a
              >
            </li>
            <li class="nav-item" v-on:click="changetab('detay')">
              <a
                class="nav-link"
                :class="{ 'show active': showtab == 'detay' }"
                id="top-detay"
                data-toggle="tab"
                role="tab"
                aria-controls="detay"
                aria-selected="false"
                >Tur Detayları</a
              >
            </li>
            <li class="nav-item" v-on:click="changetab('islem')">
              <a
                class="nav-link"
                :class="{ 'show active': showtab == 'islem' }"
                id="top-islem"
                data-toggle="tab"
                role="tab"
                aria-controls="islem"
                aria-selected="false"
                >İşlemler</a
              >
            </li>
          </ul>
        </div>
      </div>

      <div class="tab-content" id="top-tabContent">
        <div
          class="tab-pane fade"
          :class="{ 'show active': showtab == 'tur' }"
          id="tur"
          role="tabpanel"
          aria-labelledby="tur"
        >
          <div class="row">
            <div class="col-md-6">
              <b-card
                header-tag="div"
                header-bg-variant="primary"
                class="card-absolute"
              >
                <h5 slot="header">Rezervasyon Bilgileri</h5>
                <b-card-body>
                  <table class="product-page-width">
                    <tbody>
                      <tr>
                        <th>Kod</th>
                        <td>
                          <b-badge variant="primary">{{ detay.Kod }}</b-badge>
                        </td>
                      </tr>
                      <tr>
                        <th>Tarih</th>
                        <td>
                          <c-tarih-cell
                            :kayitTarihi="detay.Tarih"
                            :rowKey="detay.Id"
                          ></c-tarih-cell>
                        </td>
                      </tr>
                      <tr>
                        <th>Saat</th>
                        <td>
                          <b-badge variant="dark">{{ detay.Saat }}</b-badge>
                        </td>
                      </tr>
                      <tr>
                        <th colspan="2">Açıklama</th>
                      </tr>
                      <tr>
                        <td colspan="2">{{ detay.Aciklama }}</td>
                      </tr>
                      <tr>
                        <th>Otel Adı</th>
                        <td>{{ detay.OtelAdi }}</td>
                      </tr>
                      <tr>
                        <th>Tur</th>
                        <td>{{ detay.TurAdi }}</td>
                      </tr>
                      <tr>
                        <th>Opsiyon</th>
                        <td>{{ detay.TurOpsiyonAdi }}</td>
                      </tr>
                      <tr>
                        <th>Dil</th>
                        <td>{{ detay.DilAdi }}</td>
                      </tr>
                      <tr>
                        <th>Kişi Sayısı</th>
                        <td>
                          <b-badge variant="info">{{
                            detay.KisiSayisi
                          }}</b-badge>
                        </td>
                      </tr>
                      <tr>
                        <th>Durum</th>
                        <td>
                          <b-badge variant="primary">{{
                            detay.DurumStr
                          }}</b-badge>
                        </td>
                      </tr>
                      <tr>
                        <th>İptal Sebebi</th>
                        <td>{{ detay.IptalSebebi }}</td>
                      </tr>
                      <tr>
                        <th>Son İşlem Yapan Kullanıcı</th>
                        <td>{{ detay.KullaniciAdi }}</td>
                      </tr>
                      <tr>
                        <th>Kayıt Tarihi</th>
                        <td>
                          <c-tarih-cell
                            :kayitTarihi="detay.KayitTarihi"
                            :rowKey="detay.Id"
                          ></c-tarih-cell>
                        </td>
                      </tr>
                      <tr>
                        <th>Güncelleme Tarihi</th>
                        <td>
                          <c-tarih-cell
                            :kayitTarihi="detay.GuncelTarih"
                            :rowKey="detay.Id"
                          ></c-tarih-cell>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </b-card-body>
              </b-card>

              <b-card
                header-tag="div"
                header-bg-variant="secondary"
                class="card-absolute"
              >
                <h5 slot="header">Tutarlar</h5>
                <b-card-body>
                  <table class="product-page-width">
                    <tbody>
                      <tr>
                        <th>Toplam Tutar</th>
                        <td>
                          <b-badge variant="success"
                            ><c-money-cell
                              :fiyat="detay.ToplamTutar"
                              simge="TL"
                            ></c-money-cell
                          ></b-badge>
                        </td>
                      </tr>
                      <tr>
                        <th>Toplam Maliyet</th>
                        <td>
                          <b-badge variant="danger"
                            ><c-money-cell
                              :fiyat="detay.ToplamMaliyet"
                              simge="TL"
                            ></c-money-cell
                          ></b-badge>
                        </td>
                      </tr>
                      <tr>
                        <th>Tur Toplamı</th>
                        <td>
                          <b-badge variant="warning"
                            ><c-money-cell
                              :fiyat="detay.TurTutar"
                              simge="TL"
                            ></c-money-cell
                          ></b-badge>
                        </td>
                      </tr>
                      <tr>
                        <th>Araç Tutar</th>
                        <td>
                          <b-badge variant="info"
                            ><c-money-cell
                              :fiyat="detay.AracTutar"
                              simge="TL"
                            ></c-money-cell
                          ></b-badge>
                        </td>
                      </tr>
                      <tr>
                        <th>Rehber Tutar</th>
                        <td>
                          <b-badge variant="dark"
                            ><c-money-cell
                              :fiyat="detay.RehberTutar"
                              simge="TL"
                            ></c-money-cell
                          ></b-badge>
                        </td>
                      </tr>
                      <tr>
                        <th>Restoran Tutar</th>
                        <td>
                          <b-badge variant="secondary"
                            ><c-money-cell
                              :fiyat="detay.RestoranTutar"
                              simge="TL"
                            ></c-money-cell
                          ></b-badge>
                        </td>
                      </tr>
                      <tr>
                        <th>Müze Tutar</th>
                        <td>
                          <b-badge variant="info"
                            ><c-money-cell
                              :fiyat="detay.MuzeTutar"
                              simge="TL"
                            ></c-money-cell
                          ></b-badge>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </b-card-body>
              </b-card>
            </div>
            <div class="col-md-6">
              <b-card
                header-tag="div"
                header-bg-variant="success"
                class="card-absolute"
              >
                <h5 slot="header">Acente</h5>
                <b-card-body>
                  <table class="product-page-width">
                    <tbody>
                      <tr>
                        <th>Acente Adı</th>
                        <td>{{ detay.AcenteAdi }}</td>
                      </tr>
                      <tr>
                        <th>Rezervasyonu Yapan Kişi</th>
                        <td>{{ detay.AcenteKullaniciAdi }}</td>
                      </tr>
                    </tbody>
                  </table>
                  <router-link
                    :to="`/acente/detay/${detay.AcenteId}`"
                    class="btn btn-info pull-right"
                    >Acente Detayı</router-link
                  >
                </b-card-body>
              </b-card>

              <b-card
                header-tag="div"
                header-bg-variant="warning"
                class="card-absolute"
              >
                <h5 slot="header">Rehber & Araç</h5>
                <b-card-body>
                  <table class="product-page-width">
                    <tbody>
                      <tr>
                        <th>Rehber</th>
                        <td>{{ detay.RehberAdi }}</td>
                      </tr>
                      <tr>
                        <th>Araç Firması</th>
                        <td>{{ detay.AracFirmaAdi }}</td>
                      </tr>
                      <tr>
                        <th>Araç Plakası</th>
                        <td>{{ detay.Plaka }}</td>
                      </tr>
                      <tr>
                        <th>Şoför Adı</th>
                        <td>{{ detay.SoforAdi }}</td>
                      </tr>
                      <tr>
                        <th>Araç Tipi</th>
                        <td>{{ detay.AracTipi }}</td>
                      </tr>
                    </tbody>
                  </table>
                </b-card-body>
              </b-card>

              <b-list-group>
                <b-list-group-item
                  router-component-name="router-link"
                  :to="'/turRezervasyon/duzelt/' + detay.Id"
                >
                  <feather type="edit-3"></feather> Düzelt
                </b-list-group-item>
                <b-list-group-item tag="a" href="#" @click="onaylandi()">
                  <feather type="check"></feather> Onayla
                </b-list-group-item>
                <b-list-group-item
                  router-component-name="router-link"
                  tag="a"
                  href="#"
                  @click="tamamlandi()"
                >
                  <feather type="check-circle"></feather> Tamamlandı
                </b-list-group-item>
                <b-list-group-item
                  router-component-name="router-link"
                  tag="a"
                  href="#"
                  @click="iptalAc()"
                >
                  <feather type="slash"></feather> İptal
                </b-list-group-item>
                <b-list-group-item
                  router-component-name="router-link"
                  tag="a"
                  href="#"
                  @click="sil()"
                >
                  <feather type="delete"></feather> Sil
                </b-list-group-item>
              </b-list-group>
            </div>
          </div>
        </div>

        <div
          class="tab-pane fade"
          :class="{ 'show active': showtab == 'misafir' }"
          id="misafir"
          role="tabpanel"
          aria-labelledby="misafir"
        >
          <div class="row">
            <div class="col-md-12">
              <b-card
                header-tag="div"
                header-bg-variant="primary"
                class="card-absolute"
              >
                <h5 slot="header">Misafirler</h5>
                <b-card-body>
                  <b-table
                    striped
                    hover
                    :fields="misafirFields"
                    :items="detay.Misafirler"
                  ></b-table>
                </b-card-body>
              </b-card>
            </div>
          </div>
        </div>

        <div
          class="tab-pane fade"
          :class="{ 'show active': showtab == 'detay' }"
          id="detay"
          role="tabpanel"
          aria-labelledby="detay"
        >
          <div class="row">
            <div class="col-md-12">
              <b-card
                header-tag="div"
                header-bg-variant="info"
                class="card-absolute card-no-padding"
              >
                <h5 slot="header">Opsiyon Tutarları</h5>
                <b-card-body>
                  <b-table
                    striped
                    hover
                    :fields="opsiyonTutarFields"
                    :items="detay.TurDetayi.OpsiyonTutarlar"
                  >
                    <template #cell(YasMin)="row">
                      <b-badge variant="primary">{{ row.item.YasMin }}</b-badge>
                      -
                      <b-badge variant="secondary">{{
                        row.item.YasMax
                      }}</b-badge>
                    </template>
                    <template #cell(Oran)="data">
                      <b-badge variant="primary">{{ data.value }}</b-badge>
                    </template>
                    <template #cell(Fiyat)="data">
                      <c-money-cell
                        :fiyat="data.value"
                        v-if="data.value !== 0"
                        simge="TL"
                      ></c-money-cell>
                    </template>
                    <template #cell(ToplamFiyat)="data">
                      <c-money-cell
                        :fiyat="data.value"
                        simge="TL"
                      ></c-money-cell>
                    </template>
                    <template #cell(AcenteFiyati)="data">
                      <c-aktif-cell :isAktif="data.value"></c-aktif-cell>
                    </template>
                  </b-table>
                </b-card-body>
              </b-card>

              <b-card
                header-tag="div"
                header-bg-variant="success"
                class="card-absolute"
              >
                <h5 slot="header">Mekanlar</h5>
                <b-card-body>
                  <div class="row">
                    <div
                      class="col-md-4"
                      v-for="mekan in detay.TurDetayi.Mekanlar"
                      v-bind:key="mekan.MekanId"
                    >
                      <b-card class="mb-1 card-no-padding">
                        <b-card-header header-tag="div" class="bg-primary">
                          <h5 class="mb-0">
                            {{ mekan.MekanAdi }}
                          </h5>
                        </b-card-header>
                        <b-card-body>
                          <b-list-group tag="ul">
                            <b-list-group-item
                              tag="li"
                              class="d-flex justify-content-between align-items-center"
                              >Acente Komisyon Toplamı
                              <b-badge
                                variant="primary"
                                class="counter digits"
                                >{{ mekan.AcenteKomisyonToplami }}</b-badge
                              >
                            </b-list-group-item>

                            <b-list-group-item
                              tag="li"
                              class="d-flex justify-content-between align-items-center"
                              >Acente Komisyon Oranı
                              <b-badge
                                variant="primary"
                                class="counter digits"
                                >{{ mekan.AcenteKomisyonOrani }}</b-badge
                              >
                            </b-list-group-item>

                            <b-list-group-item
                              tag="li"
                              class="d-flex justify-content-between align-items-center"
                              >Merkez Komisyon Toplamı
                              <b-badge
                                pill
                                variant="success"
                                class="counter digits"
                                >{{ mekan.MerkezKomisyonToplami }}</b-badge
                              >
                            </b-list-group-item>

                            <b-list-group-item
                              tag="li"
                              class="d-flex justify-content-between align-items-center"
                              >Merkez Komisyon Oranı
                              <b-badge
                                pill
                                variant="success"
                                class="counter digits"
                                >{{ mekan.MerkezKomisyonOrani }}</b-badge
                              >
                            </b-list-group-item>

                            <b-list-group-item
                              tag="li"
                              class="d-flex justify-content-between align-items-center"
                              >Rehber Komisyon Toplamı
                              <b-badge
                                pill
                                variant="warning"
                                class="counter digits"
                                >{{ mekan.RehberKomisyonToplami }}</b-badge
                              >
                            </b-list-group-item>

                            <b-list-group-item
                              tag="li"
                              class="d-flex justify-content-between align-items-center"
                              >Rehber Komisyon Oranı
                              <b-badge
                                pill
                                variant="warning"
                                class="counter digits"
                                >{{ mekan.RehberKomisyonOrani }}</b-badge
                              >
                            </b-list-group-item>

                            <b-list-group-item
                              tag="li"
                              class="d-flex justify-content-between align-items-center"
                              >Şoför Komisyon Toplamı
                              <b-badge
                                pill
                                variant="info"
                                class="counter digits"
                                >{{ mekan.SoforKomisyonToplami }}</b-badge
                              >
                            </b-list-group-item>

                            <b-list-group-item
                              tag="li"
                              class="d-flex justify-content-between align-items-center"
                              >Şoför Komisyon Oranı
                              <b-badge
                                pill
                                variant="info"
                                class="counter digits"
                                >{{ mekan.SoforKomisyonOrani }}</b-badge
                              >
                            </b-list-group-item>

                            <b-list-group-item
                              tag="li"
                              class="d-flex justify-content-between align-items-center"
                              >Toplam Satış Tutarı
                              <b-badge
                                pill
                                variant="dark"
                                class="counter digits"
                                >{{ mekan.ToplamSatisTutari }}</b-badge
                              >
                            </b-list-group-item>

                            <b-list-group-item
                              tag="li"
                              class="d-flex justify-content-between align-items-center"
                              >Hesaplandı
                              <c-aktif-cell
                                :isAktif="mekan.Hesaplandi"
                              ></c-aktif-cell>
                            </b-list-group-item>

                            <b-list-group-item
                              tag="li"
                              class="d-flex justify-content-between align-items-center"
                              >Dağıtıldı
                              <c-aktif-cell
                                :isAktif="mekan.Dagitildi"
                              ></c-aktif-cell>
                            </b-list-group-item>
                          </b-list-group>
                        </b-card-body>
                      </b-card>
                    </div>
                  </div>
                </b-card-body>
              </b-card>

              <b-card
                header-tag="div"
                header-bg-variant="dark"
                class="card-absolute"
              >
                <h5 slot="header">Müzeler</h5>
                <b-card-body>
                  <b-card
                    class="mb-1 card-no-padding"
                    v-for="muze in detay.TurDetayi.Muzeler"
                    v-bind:key="muze.MuzeId"
                  >
                    <b-card-header header-tag="div" class="bg-primary">
                      <h5 class="mb-0">
                        {{ muze.MuzeAdi }}
                        <small class="digits"
                          >Fiyat {{ muze.MuzeFiyati }}TL</small
                        >
                        /
                        <small class="digits"
                          >Toplam {{ muze.ToplamTutar }}TL</small
                        >
                      </h5>
                    </b-card-header>
                    <b-card-body>
                      <b-table striped hover :items="muze.Tutarlar"></b-table>
                    </b-card-body>
                  </b-card>
                </b-card-body>
              </b-card>

              <b-card
                header-tag="div"
                header-bg-variant="warning"
                class="card-absolute"
              >
                <h5 slot="header">Restoranlar</h5>
                <b-card-body>
                  <b-card
                    class="mb-1"
                    v-for="restoran in detay.TurDetayi.Restoranlar"
                    v-bind:key="restoran.RestoranId"
                  >
                    <b-card-header header-tag="div" class="bg-primary">
                      <h5 class="mb-0">
                        {{ restoran.RestoranAdi }}
                        <small class="digits"
                          >Alış Fiyatı :
                          <c-money-cell
                            :fiyat="restoran.MenuAlisFiyati"
                            simge="TL"
                          ></c-money-cell
                        ></small>
                        /
                        <small class="digits"
                          >Satış Fiyatı :
                          <c-money-cell
                            :fiyat="restoran.MenuSatisFiyati"
                            simge="TL"
                          ></c-money-cell
                        ></small>
                        /
                        <small class="digits"
                          >Toplam Alış :
                          <c-money-cell
                            :fiyat="restoran.ToplamAlisFiyat"
                            simge="TL"
                          ></c-money-cell
                        ></small>
                        /
                        <small class="digits"
                          >Toplam Satış :
                          <c-money-cell
                            :fiyat="restoran.ToplamSatisFiyat"
                            simge="TL"
                          ></c-money-cell
                        ></small>
                      </h5>
                    </b-card-header>
                    <b-card-body>
                      <b-table
                        striped
                        hover
                        :items="restoran.Tutarlar"
                      ></b-table>
                    </b-card-body>
                  </b-card>
                </b-card-body>
              </b-card>

              <div class="row">
                <div class="col-md-6">
                  <b-card
                    header-tag="div"
                    header-bg-variant="primary"
                    class="card-absolute card-no-padding"
                  >
                    <h5 slot="header">Rehber</h5>
                    <b-card-body>
                      <table class="product-page-width">
                        <tbody>
                          <tr>
                            <th style="width: 30%">Adı</th>
                            <td>{{ detay.TurDetayi.Rehber.RehberAdi }}</td>
                          </tr>
                          <tr>
                            <th>Dil</th>
                            <td>{{ detay.TurDetayi.Rehber.Dil }}</td>
                          </tr>
                          <tr>
                            <th>Alış Fiyatı</th>
                            <td>
                              <b-badge variant="warning"
                                ><c-money-cell
                                  :fiyat="
                                    detay.TurDetayi.Rehber.RehberAlisFiyati
                                  "
                                  simge="TL"
                                ></c-money-cell
                              ></b-badge>
                            </td>
                          </tr>
                          <tr>
                            <th>Satış Fiyatı</th>
                            <td>
                              <b-badge variant="success"
                                ><c-money-cell
                                  :fiyat="
                                    detay.TurDetayi.Rehber.RehberSatisFiyati
                                  "
                                  simge="TL"
                                ></c-money-cell
                              ></b-badge>
                            </td>
                          </tr>
                          <tr>
                            <th>Komisyon Oranı</th>
                            <td>
                              <b-badge variant="info"
                                >%{{
                                  detay.TurDetayi.Rehber.RehberKomisyonOrani
                                }}</b-badge
                              >
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </b-card-body>
                  </b-card>
                </div>
                <div class="col-md-6">
                  <b-card
                    header-tag="div"
                    header-bg-variant="danger"
                    class="card-absolute card-no-padding"
                  >
                    <h5 slot="header">Araç & Şoför</h5>
                    <b-card-body>
                      <table class="product-page-width">
                        <tbody>
                          <tr>
                            <th style="width: 30%">Araç Firması</th>
                            <td>{{ detay.AracFirmaAdi }}</td>
                          </tr>
                          <tr>
                            <th>Araç Tipi</th>
                            <td>{{ detay.AracTipi }}</td>
                          </tr>
                          <tr>
                            <th>Şoför</th>
                            <td>{{ detay.TurDetayi.Arac.SoforAdi }}</td>
                          </tr>
                          <tr>
                            <th>Plaka</th>
                            <td>{{ detay.TurDetayi.Arac.Plaka }}</td>
                          </tr>
                          <tr>
                            <th>Alış Fiyatı</th>
                            <td>
                              <b-badge variant="warning"
                                ><c-money-cell
                                  :fiyat="detay.TurDetayi.Arac.AlisFiyati"
                                  simge="TL"
                                ></c-money-cell
                              ></b-badge>
                            </td>
                          </tr>
                          <tr>
                            <th>Satış Fiyatı</th>
                            <td>
                              <b-badge variant="success"
                                ><c-money-cell
                                  :fiyat="detay.TurDetayi.Arac.SatisFiyati"
                                  simge="TL"
                                ></c-money-cell
                              ></b-badge>
                            </td>
                          </tr>
                          <tr>
                            <th>Komisyon Oranı</th>
                            <td>
                              <b-badge variant="info"
                                >%{{
                                  detay.TurDetayi.Arac.SoforKomisyonOrani
                                }}</b-badge
                              >
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </b-card-body>
                  </b-card>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          class="tab-pane fade"
          :class="{ 'show active': showtab == 'islem' }"
          id="islem"
          role="tabpanel"
          aria-labelledby="islem"
        >
          <div class="row">
            <div class="col-md-12">
              <b-card
                header-tag="div"
                header-bg-variant="dark"
                class="card-absolute"
              >
                <h5 slot="header">İşlemler</h5>
                <b-card-body>
                  <b-table
                    striped
                    hover
                    :fields="islemFields"
                    :items="detay.Islemler"
                  >
                    <template #cell(isYonetimGorsun)="data">
                      <c-aktif-cell :isAktif="data.value"></c-aktif-cell>
                    </template>
                    <template #cell(Tarih)="row">
                      <c-tarih-cell
                        :kayitTarihi="row.item.Tarih"
                        :rowKey="row.item.Id"
                      ></c-tarih-cell>
                    </template>
                  </b-table>
                </b-card-body>
              </b-card>
            </div>
          </div>
        </div>
      </div>

      <b-modal
        id="iptalForm"
        size="lg"
        centered
        title="Rezervasyonu İptal Et"
        @ok="iptalSubmit"
        ok-title="İptal Et"
        cancel-title="Kapat"
        class="theme-modal"
      >
        <b-form @submit="iptalSubmit">
          <div class="form-row">
            <div class="col-md-12 mb-3">
              <label for="kayit_iptalsebep">İptal Sebebi</label>
              <b-form-textarea
                type="text"
                id="kayit_iptalsebep"
                v-model="iptalForm.Sebep"
                :state="iptal_sebep_state"
                placeholder="İptal Sebebi"
              ></b-form-textarea>
              <b-form-invalid-feedback :state="iptal_sebep_state"
                >İptal sebebini yazınız.</b-form-invalid-feedback
              >
            </div>
          </div>
        </b-form>
      </b-modal>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      id: this.$route.params.id,

      pageTitle: "Tur Detayı",

      breadLinks: [
        {
          path: "/turRezervasyon/list",
          title: "Tur Rezervasyonları",
          icon: "chevron-left",
        },
      ],

      showtab: "tur",

      misafirFields: [
        { key: "Yas", label: "Yaş", sortable: false },
        { key: "AdSoyad", label: "Ad Soyad", sortable: false },
        { key: "Uyruk", label: "Uyruk", sortable: false },
        { key: "Telefon", label: "Telefon", sortable: false },
        {
          key: "PasaportNo",
          label: "Pasaport / T.C. Kimlik No",
          sortable: false,
        },
      ],

      islemFields: [
        { key: "Islem", label: "İşlem", sortable: false },
        { key: "Aciklama", label: "Açıklama", sortable: false },
        { key: "Tarih", label: "Tarih", sortable: false },
        { key: "AcenteId", label: "Acente", sortable: false },
        { key: "RehberId", label: "Rehber", sortable: false },
        { key: "AracId", label: "Araç", sortable: false },
        { key: "MekanId", label: "Mekan", sortable: false },
        { key: "isYonetimGorsun", label: "Yön. Görsün", sortable: false },
      ],

      opsiyonTutarFields: [
        { key: "YasMin", label: "Yaş (Min-Max)", sortable: false },
        { key: "KisiSayisi", label: "Kişi Sayısı", sortable: false },
        { key: "Oran", label: "İndirim (%)", sortable: false },
        { key: "Fiyat", label: "Fiyat", sortable: false },
        { key: "ToplamFiyat", label: "Toplam", sortable: false },
        { key: "AcenteFiyati", label: "Acente Ö.F.", sortable: false },
      ],

      detay: {},

      iptalForm: {
        Sebep: "",
      },
    };
  },
  computed: {
    iptal_sebep_state() {
      if (this.iptalForm.Sebep == null || this.iptalForm.Sebep == "")
        return false;
      else return true;
    },
  },
  mounted() {
    this.onDetay();
  },
  methods: {
    changetab(tab) {
      this.showtab = tab;
    },
    onDetay() {
      let vm = this;

      this.$connection.get(
        "/turRezervasyonlari/" + this.id,
        function (response) {
          if (response.Success) {
            vm.detay = response.Data;

            vm.detay.TurDetayi.OpsiyonTutarlar.push({
              KisiSayisi: "Toplam",
              Fiyat: 0,
              ToplamFiyat: vm.detay.TurTutar,
            });
          } else {
            vm.$alert.messageWithTitle("Tur Detay", response.Message, "error");
          }
        }
      );
    },
    tamamlandi() {
      let vm = this;
      this.$alert.confirm(
        "Tamamlandı",
        "Rezervasyon tamamlandı olarak işaretlenecektir. Emin misiniz?",
        "success",
        function () {
          vm.$connection.get(
            "/turRezervasyonlari/tamamlandi/" + vm.detay.Id,
            function () {
              vm.$alert.messageWithFunction(
                "Tamamlandı",
                "Rezervasyon tamamlandı olarak işaretlendi.",
                "success",
                function () {
                  vm.onDetay();
                }
              );
            }
          );
        }
      );
    },
    onaylandi() {
      let vm = this;
      this.$alert.confirm(
        "Onayla",
        "Rezervasyon onaylanacaktır. Emin misiniz?",
        "info",
        function () {
          vm.$connection.get(
            "/turRezervasyonlari/onaylandi/" + vm.detay.Id,
            function () {
              vm.$alert.messageWithFunction(
                "Onayla",
                "Rezervasyon onaylandı.",
                "success",
                function () {
                  vm.onDetay();
                }
              );
            }
          );
        }
      );
    },
    iptalAc() {
      this.$bvModal.show("iptalForm");
    },
    iptalSubmit(event) {
      event.preventDefault();

      let isValid = this.iptal_sebep_state;

      if (isValid) {
        let vm = this;
        this.$alert.confirm(
          "İptal",
          "Rezervasyon iptal edilecektir. Emin misiniz?",
          "warning",
          function () {
            vm.$connection.post(
              "/turRezervasyonlari/iptal/" + vm.detay.Id,
              vm.iptalForm,
              "put",
              function () {
                vm.$alert.messageWithFunction(
                  "İptal",
                  "Rezervasyon iptal edildi.",
                  "success",
                  function () {
                    vm.onDetay();
                    vm.$bvModal.hide("iptalForm");
                  }
                );
              }
            );
          }
        );
      }
    },
    sil() {
      let vm = this;
      this.$alert.confirm(
        "Sil",
        "Rezervasyon silinecektir. Emin misiniz?",
        "error",
        function () {
          vm.$connection.delete(
            "/turRezervasyonlari/" + vm.detay.Id,
            function () {
              vm.$alert.messageWithFunction(
                "Sil",
                "Rezervasyon silindi.",
                "success",
                function () {
                  vm.$router.push("/turRezervasyon/list");
                }
              );
            }
          );
        }
      );
    },
  },
};
</script>
