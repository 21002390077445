<template>
  <div>
    <Breadcrumbs
      main="Tur Rezervasyonu"
      :title="pageTitle"
      :links="breadLinks"
    />

    <div class="container-fluid tur-rezervasyon-detay">
      <div class="row">
        <div class="col-md-12">
          <ul
            class="nav nav-tabs border-tab tabs-scoial"
            id="top-tab"
            role="tablist"
          >
            <li class="nav-item" v-on:click="changetab('tur')" v-if="isTurShow">
              <a
                class="nav-link"
                :class="{ 'show active': showtab == 'tur' }"
                id="top-tur"
                data-toggle="tab"
                role="tab"
                aria-controls="tur"
                aria-selected="true"
                >Rezervasyon
                <b-badge variant="primary">{{ detay.Kod }}</b-badge></a
              >
            </li>
            <li
              class="nav-item"
              v-on:click="changetab('misafir')"
              v-if="isMisafirShow"
            >
              <a
                class="nav-link"
                :class="{ 'show active': showtab == 'misafir' }"
                id="top-misafir"
                data-toggle="tab"
                role="tab"
                aria-controls="misafir"
                aria-selected="false"
                >Misafirler</a
              >
            </li>
            <li
              class="nav-item"
              v-on:click="changetab('detay')"
              v-if="isDetayShow"
            >
              <a
                class="nav-link"
                :class="{ 'show active': showtab == 'detay' }"
                id="top-detay"
                data-toggle="tab"
                role="tab"
                aria-controls="detay"
                aria-selected="false"
                >Tur Detayları</a
              >
            </li>
            <li
              class="nav-item"
              v-on:click="changetab('islem')"
              v-if="isIslemShow"
            >
              <a
                class="nav-link"
                :class="{ 'show active': showtab == 'islem' }"
                id="top-islem"
                data-toggle="tab"
                role="tab"
                aria-controls="islem"
                aria-selected="false"
                >İşlemler</a
              >
            </li>
          </ul>
        </div>
      </div>

      <div class="tab-content" id="top-tabContent">
        <div
          class="tab-pane fade"
          :class="{ 'show active': showtab == 'tur' }"
          id="tur"
          role="tabpanel"
          aria-labelledby="tur"
        >
          <div class="row">
            <div class="col-md-6">
              <b-card
                header-tag="div"
                header-bg-variant="primary"
                class="card-absolute"
              >
                <h5 slot="header">Rezervasyon Bilgileri</h5>
                <b-card-body>
                  <table class="product-page-width">
                    <tbody>
                      <tr>
                        <th>Kod</th>
                        <td>
                          <b-badge variant="primary">{{ detay.Kod }}</b-badge>
                        </td>
                      </tr>
                      <tr>
                        <th>Tarih</th>
                        <td>
                          <c-tarih-cell
                            :kayitTarihi="detay.Tarih"
                            :rowKey="detay.Id"
                          ></c-tarih-cell>
                        </td>
                      </tr>
                      <tr>
                        <th>Saat</th>
                        <td>
                          <b-badge variant="dark">{{ detay.Saat }}</b-badge>
                        </td>
                      </tr>
                      <tr>
                        <th colspan="2">Açıklama</th>
                      </tr>
                      <tr>
                        <td colspan="2">{{ detay.Aciklama }}</td>
                      </tr>
                      <tr>
                        <th>Otel Adı</th>
                        <td>{{ detay.OtelAdi }}</td>
                      </tr>
                      <tr>
                        <th>Otel Adresi</th>
                        <td>{{ detay.OtelAdresi }}</td>
                      </tr>
                      <tr>
                        <th>Tur</th>
                        <td>{{ detay.TurAdi }}</td>
                      </tr>
                      <tr>
                        <th>Opsiyon</th>
                        <td>{{ detay.TurOpsiyonAdi }}</td>
                      </tr>
                      <tr>
                        <th>Dil</th>
                        <td>{{ detay.DilAdi }}</td>
                      </tr>
                      <tr>
                        <th>Kişi Sayısı</th>
                        <td>
                          <b-badge variant="info">{{
                            detay.KisiSayisi
                          }}</b-badge>
                        </td>
                      </tr>
                      <tr>
                        <th>Durum</th>
                        <td>
                          <b-badge variant="primary">{{
                            detay.DurumStr
                          }}</b-badge>
                        </td>
                      </tr>
                      <tr>
                        <th>İptal Sebebi</th>
                        <td>{{ detay.IptalSebebi }}</td>
                      </tr>
                      <tr>
                        <th>Son İşlem Yapan Kullanıcı</th>
                        <td>{{ detay.KullaniciAdi }}</td>
                      </tr>
                      <tr>
                        <th>Kayıt Tarihi</th>
                        <td>
                          <c-tarih-cell
                            :kayitTarihi="detay.KayitTarihi"
                            :rowKey="detay.Id"
                          ></c-tarih-cell>
                        </td>
                      </tr>
                      <tr>
                        <th>Güncelleme Tarihi</th>
                        <td>
                          <c-tarih-cell
                            :kayitTarihi="detay.GuncelTarih"
                            :rowKey="detay.Id"
                          ></c-tarih-cell>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </b-card-body>
              </b-card>

              <CTurRezervasyonTutarlar
                :detay="detay"
                v-if="detay !== undefined"
              />
            </div>
            <div class="col-md-6">
              <b-card
                header-tag="div"
                header-bg-variant="success"
                class="card-absolute"
              >
                <h5 slot="header">Acente</h5>
                <b-card-body>
                  <table class="product-page-width">
                    <tbody>
                      <tr>
                        <th>Acente Adı</th>
                        <td>{{ detay.AcenteAdi }}</td>
                      </tr>
                      <tr>
                        <th>Operasyon Telefonu</th>
                        <td>0530 010 81 11</td>
                      </tr>
                    </tbody>
                  </table>
                  <p>
                    Misafirler veya tur ile ilgili herhangi bir durumda
                    iletişime geçiniz.
                  </p>
                  <router-link
                    :to="`/acente/detay/${detay.AcenteId}`"
                    v-if="isBtnAcenteDetay"
                    class="btn btn-info pull-right"
                    >Acente Detayı</router-link
                  >
                </b-card-body>
              </b-card>

              <b-card
                header-tag="div"
                header-bg-variant="warning"
                class="card-absolute"
              >
                <h5 slot="header">Rehber</h5>
                <b-card-body>
                  <table class="product-page-width">
                    <tbody>
                      <tr>
                        <td colspan="2" class="text-center">
                          <img
                            :src="detay.RehberResim"
                            class="img-thumbnail img-70"
                          />
                        </td>
                      </tr>
                      <tr>
                        <th>Rehber</th>
                        <td>{{ detay.RehberAdi }}</td>
                      </tr>
                      <tr>
                        <th>Telefon</th>
                        <td>{{ detay.RehberGSM }}</td>
                      </tr>
                      <tr v-if="isRehberSozlesme">
                        <th></th>
                        <td>
                          <b-button variant="primary" @click="sozlesmeAc(0)"
                            >Rehber Sözleşmesi</b-button
                          >
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </b-card-body>
              </b-card>

              <b-card
                header-tag="div"
                header-bg-variant="danger"
                class="card-absolute"
              >
                <h5 slot="header">Araç</h5>
                <b-card-body>
                  <table class="product-page-width">
                    <tbody>
                      <tr>
                        <td colspan="2" class="text-center">
                          <b-carousel
                            :id="'carousel-' + detay.Id"
                            :interval="4000"
                            controls
                            indicators
                            background="#ababab"
                            style="
                              width: 350px !important;
                              text-shadow: 1px 1px 2px #333;
                            "
                          >
                            <b-carousel-slide
                              v-for="(item, index) in aracResimler"
                              :key="index"
                              :img-src="item.Resim"
                            ></b-carousel-slide>
                          </b-carousel>
                        </td>
                      </tr>
                      <tr v-if="isBtnYonetim">
                        <th>Araç Firması</th>
                        <td>{{ detay.AracFirmaAdi }}</td>
                      </tr>
                      <tr>
                        <th>Plaka</th>
                        <td>{{ detay.Plaka }}</td>
                      </tr>
                      <tr>
                        <th>Şoför Adı</th>
                        <td>{{ detay.SoforAdi }}</td>
                      </tr>
                      <tr>
                        <th>Şoför Telefon</th>
                        <td>{{ detay.SoforGSM }}</td>
                      </tr>
                      <tr>
                        <th>Marka</th>
                        <td>{{ detay.AracMarka }}</td>
                      </tr>
                      <tr>
                        <th>Model</th>
                        <td>{{ detay.AracModel }}</td>
                      </tr>
                      <tr v-if="isAracSozlesme">
                        <th></th>
                        <td>
                          <div class="btn-group">
                            <b-button variant="primary" @click="sozlesmeAc(1)"
                              >Araç Sözleşmesi</b-button
                            >
                            <b-button
                              variant="secondary"
                              @click="misafirListAc()"
                              >Misafir Listesi Yazdır</b-button
                            >
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </b-card-body>
              </b-card>

              <b-modal
                id="sozlesmeDetay"
                size="xl"
                centered
                title="Sözleşme"
                @ok="sozlesmeYazdir"
                ok-title="Yazdır"
                cancel-title="Kapat"
                class="theme-modal"
              >
                <iframe
                  id="sozlesmeFrame"
                  name="sozlesmeFrame"
                  :srcdoc="sozlesme.SozlesmeMetni"
                  width="100%"
                  height="500"
                  frameborder="no"
                >
                </iframe>
              </b-modal>

              <b-modal
                id="misafirListeDetay"
                size="xl"
                centered
                title="Yolcu Listesi"
                @ok="misafirListYazdir"
                ok-title="Yazdır"
                cancel-title="Kapat"
                class="theme-modal"
              >
                <CTurRezervasyonMisafirPrint :rezervasyon="detay" />
              </b-modal>

              <b-list-group>
                <b-list-group-item
                  router-component-name="router-link"
                  v-if="isBtnDuzelt"
                  :disabled="disable_duzelt_state"
                  :to="'/turRezervasyon/duzelt/' + detay.Id"
                >
                  <feather type="edit-3"></feather> Tur / Opsiyon Değiştir
                  <span>{{
                    disable_duzelt_state
                      ? "(Düzeltmek için uygun değildir.)"
                      : ""
                  }}</span>
                </b-list-group-item>
                <b-list-group-item
                  router-component-name="router-link"
                  v-if="isBtnDuzelt"
                  :disabled="disable_duzelt_state"
                  :to="'/turRezervasyon/duzelt/' + detay.Id + '?tab=1'"
                >
                  <feather type="edit-3"></feather> Bilgi Değiştir
                  <span>{{
                    disable_duzelt_state
                      ? "(Düzeltmek için uygun değildir.)"
                      : ""
                  }}</span>
                </b-list-group-item>
                <b-list-group-item
                  router-component-name="router-link"
                  v-if="isBtnDuzelt && !isBtnYonetim"
                  :disabled="disable_duzelt_state"
                  :to="'/turRezervasyon/duzelt/' + detay.Id + '?tab=2'"
                >
                  <feather type="edit-3"></feather> Rehber Değiştir
                  <span>{{
                    disable_duzelt_state
                      ? "(Düzeltmek için uygun değildir.)"
                      : ""
                  }}</span>
                </b-list-group-item>
                <b-list-group-item
                  router-component-name="router-link"
                  v-if="isBtnDuzelt && !isBtnYonetim"
                  :disabled="disable_duzelt_state"
                  :to="'/turRezervasyon/duzelt/' + detay.Id + '?tab=3'"
                >
                  <feather type="edit-3"></feather> Araç Değiştir
                  <span>{{
                    disable_duzelt_state
                      ? "(Düzeltmek için uygun değildir.)"
                      : ""
                  }}</span>
                </b-list-group-item>
                <b-list-group-item
                  router-component-name="router-link"
                  :to="'/turRezervasyon/duzeltArac/' + detay.Id"
                  v-if="isBtnKismiDuzelt"
                  :disabled="disable_duzelt_state"
                >
                  <feather type="edit-3"></feather> Araç Değiştir
                  <span>{{
                    disable_duzelt_state
                      ? "(Düzeltmek için uygun değildir.)"
                      : ""
                  }}</span>
                </b-list-group-item>
                <b-list-group-item
                  router-component-name="router-link"
                  :to="'/turRezervasyon/duzeltRehber/' + detay.Id"
                  v-if="isBtnKismiDuzelt"
                  :disabled="disable_duzelt_state"
                >
                  <feather type="edit-3"></feather> Rehber Değiştir
                  <span>{{
                    disable_duzelt_state
                      ? "(Düzeltmek için uygun değildir.)"
                      : ""
                  }}</span>
                </b-list-group-item>
                <b-list-group-item
                  v-if="isBtnYonetim && showBtnOnayla"
                  tag="a"
                  href="#"
                  @click="onaylandi()"
                >
                  <feather type="check"></feather> Onayla
                </b-list-group-item>
                <b-list-group-item
                  v-if="isBtnYonetim && showBtnTamamlandi"
                  router-component-name="router-link"
                  tag="a"
                  href="#"
                  @click="tamamlandi()"
                >
                  <feather type="check-circle"></feather> Tamamlandı
                </b-list-group-item>
                <b-list-group-item
                  router-component-name="router-link"
                  v-if="isBtnIptal"
                  :disabled="disable_iptal_state"
                  tag="a"
                  href="#"
                  @click="iptalAc()"
                >
                  <feather type="slash"></feather> İptal
                  {{
                    disable_iptal_state ? "(İptal için uygun değildir.)" : ""
                  }}
                </b-list-group-item>
                <b-list-group-item
                  v-if="isBtnYonetim"
                  router-component-name="router-link"
                  tag="a"
                  href="#"
                  @click="sil()"
                >
                  <feather type="delete"></feather> Sil
                </b-list-group-item>
              </b-list-group>
            </div>
          </div>
        </div>

        <div
          class="tab-pane fade"
          :class="{ 'show active': showtab == 'misafir' }"
          id="misafir"
          role="tabpanel"
          aria-labelledby="misafir"
        >
          <div class="row">
            <div class="col-md-12">
              <b-card
                header-tag="div"
                header-bg-variant="primary"
                class="card-absolute"
              >
                <h5 slot="header">Misafirler</h5>
                <b-button
                  variant="primary"
                  class="pull-right mb-3"
                  @click="misafirListAc()"
                  >Misafir Listesi Yazdır</b-button
                >
                <b-table
                  striped
                  hover
                  :fields="misafirFields"
                  :items="detay.Misafirler"
                ></b-table>
              </b-card>
            </div>
          </div>
        </div>

        <div
          class="tab-pane fade"
          :class="{ 'show active': showtab == 'detay' }"
          id="detay"
          role="tabpanel"
          aria-labelledby="detay"
        >
          <CTurRezervasyonTurDetay
            :detay="detay"
            v-if="detay.TurDetayi !== undefined"
          />
        </div>

        <div
          class="tab-pane fade"
          :class="{ 'show active': showtab == 'islem' }"
          id="islem"
          role="tabpanel"
          aria-labelledby="islem"
        >
          <div class="row">
            <div class="col-md-12">
              <b-card
                header-tag="div"
                header-bg-variant="dark"
                class="card-absolute"
              >
                <h5 slot="header">İşlemler</h5>
                <b-card-body>
                  <b-table
                    striped
                    hover
                    :fields="islemFields"
                    :items="detay.Islemler"
                  >
                    <template #cell(isYonetimGorsun)="data">
                      <c-aktif-cell :isAktif="data.value"></c-aktif-cell>
                    </template>
                    <template #cell(Tarih)="row">
                      <c-tarih-cell
                        :kayitTarihi="row.item.Tarih"
                        :rowKey="row.item.Id"
                      ></c-tarih-cell>
                    </template>
                  </b-table>
                </b-card-body>
              </b-card>
            </div>
          </div>
        </div>
      </div>

      <b-modal
        id="iptalForm"
        size="lg"
        centered
        title="Rezervasyonu İptal Et"
        @ok="iptalSubmit"
        ok-title="İptal Et"
        cancel-title="Kapat"
        class="theme-modal"
      >
        <b-form @submit="iptalSubmit">
          <div class="form-row">
            <div class="col-md-12 mb-3">
              <label for="kayit_iptalsebep">İptal Sebebi</label>
              <b-form-textarea
                type="text"
                id="kayit_iptalsebep"
                v-model="iptalForm.Sebep"
                :state="iptal_sebep_state"
                placeholder="İptal Sebebi"
              ></b-form-textarea>
              <b-form-invalid-feedback :state="iptal_sebep_state"
                >İptal sebebini yazınız.</b-form-invalid-feedback
              >
            </div>
          </div>
        </b-form>
      </b-modal>
    </div>
  </div>
</template>
<script>
import CTurRezervasyonTutarlar from "@/components/c_turRezervasyon_tutarlar";
import CTurRezervasyonMisafirPrint from "@/components/c_turRezervasyon_misafir_print";
import CTurRezervasyonTurDetay from "@/components/c_turRezervasyon_turDetay";

export default {
  data() {
    return {
      id: this.$route.params.id,

      pageTitle: "Tur Detayı",

      breadLinks: [
        {
          path: "/turRezervasyon/list",
          title: "Tur Rezervasyonları",
          icon: "chevron-left",
        },
      ],

      isTurShow: false,
      isMisafirShow: false,
      isDetayShow: true, //Herkes görecek
      isIslemShow: false,
      isBtnDuzelt: false,
      isBtnKismiDuzelt: false,
      isBtnAcenteDetay: false,
      isBtnYonetim: false,
      isBtnIptal: false,
      isAracSozlesme: false,
      isRehberSozlesme: false,
      showBtnOnayla: false,
      showBtnTamamlandi: false,

      showtab: "tur",

      misafirFields: [
        { key: "YasStr", label: "Yaş", sortable: false },
        { key: "AdSoyad", label: "Ad Soyad", sortable: false },
        { key: "Uyruk", label: "Uyruk", sortable: false },
        { key: "Telefon", label: "Telefon", sortable: false },
        {
          key: "PasaportNo",
          label: "Pasaport / T.C. Kimlik No",
          sortable: false,
        },
      ],

      islemFields: [
        { key: "Islem", label: "İşlem", sortable: false },
        { key: "Aciklama", label: "Açıklama", sortable: false },
        { key: "Tarih", label: "Tarih", sortable: false },
        { key: "AcenteId", label: "Acente", sortable: false },
        { key: "RehberId", label: "Rehber", sortable: false },
        { key: "AracId", label: "Araç", sortable: false },
        { key: "MekanId", label: "Mekan", sortable: false },
        { key: "isYonetimGorsun", label: "Yön. Görsün", sortable: false },
      ],

      detay: {},

      aracResimler: [],

      iptalForm: {
        Sebep: "",
      },

      sozlesme: {},
    };
  },
  components: {
    CTurRezervasyonTutarlar,
    CTurRezervasyonMisafirPrint,
    CTurRezervasyonTurDetay,
  },
  computed: {
    iptal_sebep_state() {
      if (this.iptalForm.Sebep == null || this.iptalForm.Sebep == "")
        return false;
      else return true;
    },
    disable_duzelt_state() {
      if (this.detay.Durum == 0 || this.detay.Durum == 1) {
        //var rezTarih = this.$moment(String(this.detay.Tarih)).format("DD.MM.YYYY");
        if (new Date(this.detay.Tarih) > new Date()) return false;
      }

      return true;
    },
    disable_iptal_state() {
      if (new Date(this.detay.Tarih) > new Date() || this.detay.Durum == 2) {
        //tamamlandı ya da süre geçmişse
        //var rezTarih = this.$moment(String(this.detay.Tarih)).format("DD.MM.YYYY");
        // if (new Date(this.detay.Tarih) > new Date())
        //    return false;
        return false;
      }

      return true;
    },
  },
  mounted() {
    this.onDetay();
  },
  methods: {
    changetab(tab) {
      this.showtab = tab;
    },
    onDetay() {
      let vm = this;
      let user = this.$user.getUser();

      this.$connection.get(
        "/turRezervasyonlari/" + this.id,
        function (response) {
          if (response.Success) {
            switch (user.Kullanici.KullaniciTipi) {
              //Acente Yönetici
              case 10:
                vm.isTurShow = true;
                vm.isMisafirShow = true;
                vm.isDetayShow = true;
                vm.isDetayAcenteYoneticiFinansShow = true;
                vm.isBtnDuzelt = true;
                vm.isBtnIptal = true;
                vm.isAracSozlesme = true;
                vm.isRehberSozlesme = true;
                break;

              //Acente Kullanıcı
              case 15:
                vm.isTurShow = true;
                vm.isMisafirShow = true;
                vm.isDetayShow = true;
                vm.isBtnDuzelt = true;
                vm.isBtnIptal = true;
                vm.isAracSozlesme = true;
                vm.isRehberSozlesme = true;
                break;

              //Rehber
              case 20:
                vm.isTurShow = true;
                vm.isMisafirShow = true;
                vm.isRehberSozlesme = true;
                let kaldirilacak = vm.misafirFields.filter((item) => {
                  return item.key == "Telefon";
                });
                for (let index = 0; index < kaldirilacak.length; index++) {
                  const element = kaldirilacak[index];
                  vm.$listHelper.removeItem(vm.misafirFields, element);
                }
                break;

              //Araç Firması
              case 30:
              case 35:
                vm.isTurShow = true;
                vm.isMisafirShow = true;
                vm.isAracSozlesme = true;
                break;

              //Araç
              case 40:
                vm.isTurShow = true;
                vm.isMisafirShow = true;
                vm.isAracSozlesme = true;
                break;

              //Merkez
              case 80:
              case 85:
              case 100:
                vm.isTurShow = true;
                vm.isMisafirShow = true;
                vm.isDetayShow = true;
                vm.isIslemShow = true;
                vm.isBtnAcenteDetay = true;
                vm.isBtnYonetim = true;
                vm.isBtnDuzelt = true;
                vm.isBtnKismiDuzelt = true;
                vm.isBtnIptal = true;
                vm.isAracSozlesme = true;
                vm.isRehberSozlesme = true;
                break;

              default:
                break;
            }

            vm.detay = response.Data;

            switch (vm.detay.Durum) {
              case 2:
                //Tamamlandı
                vm.showBtnOnayla = false;
                vm.showBtnTamamlandi = false;
                break;

              case 1:
              case 0:
                //Yeni veya onaylandı
                vm.showBtnOnayla = false;
                break;

              case 100:
                //İptal
                vm.isBtnIptal = false;
                break;

              default:
                break;
            }

            vm.detay.TurDetayi.OpsiyonTutarlar.push({
              KisiSayisi: "Toplam",
              Fiyat: 0,
              ToplamFiyat: vm.detay.TurTutar,
            });

            vm.aracResimListe();
          } else {
            vm.$alert.messageWithTitle("Tur Detay", response.Message, "error");
          }
        }
      );
    },
    aracResimListe() {
      let vm = this;
      this.$connection.get(
        "/araclar/resimOnayli/" + this.detay.EncAracId,
        function (response) {
          vm.aracResimler = response;
        }
      );
    },
    tamamlandi() {
      let vm = this;
      this.$alert.confirm(
        "Tamamlandı",
        "Rezervasyon tamamlandı olarak işaretlenecektir. Emin misiniz?",
        "success",
        function () {
          vm.$connection.get(
            "/turRezervasyonlari/tamamlandi/" + vm.detay.Id,
            function () {
              vm.$alert.messageWithFunction(
                "Tamamlandı",
                "Rezervasyon tamamlandı olarak işaretlendi.",
                "success",
                function () {
                  vm.onDetay();
                }
              );
            }
          );
        }
      );
    },
    onaylandi() {
      let vm = this;
      this.$alert.confirm(
        "Onayla",
        "Rezervasyon onaylanacaktır. Emin misiniz?",
        "info",
        function () {
          vm.$connection.get(
            "/turRezervasyonlari/onaylandi/" + vm.detay.Id,
            function () {
              vm.$alert.messageWithFunction(
                "Onayla",
                "Rezervasyon onaylandı.",
                "success",
                function () {
                  vm.onDetay();
                }
              );
            }
          );
        }
      );
    },
    iptalAc() {
      this.$bvModal.show("iptalForm");
    },
    iptalSubmit(event) {
      event.preventDefault();

      let isValid = this.iptal_sebep_state;

      if (isValid) {
        let vm = this;
        this.$alert.confirm(
          "İptal",
          "Rezervasyon iptal edilecektir. Emin misiniz?",
          "warning",
          function () {
            vm.$connection.post(
              "/turRezervasyonlari/iptal/" + vm.detay.Id,
              vm.iptalForm,
              "put",
              function () {
                vm.$alert.messageWithFunction(
                  "İptal",
                  "Rezervasyon iptal edildi.",
                  "success",
                  function () {
                    vm.onDetay();
                    vm.$bvModal.hide("iptalForm");
                  }
                );
              }
            );
          }
        );
      }
    },
    sil() {
      let vm = this;
      this.$alert.confirm(
        "Sil",
        "Rezervasyon silinecektir. Emin misiniz?",
        "error",
        function () {
          vm.$connection.delete(
            "/turRezervasyonlari/" + vm.detay.Id,
            function () {
              vm.$alert.messageWithFunction(
                "Sil",
                "Rezervasyon silindi.",
                "success",
                function () {
                  vm.$router.push("/turRezervasyon/list");
                }
              );
            }
          );
        }
      );
    },
    sozlesmeAc(tip) {
      let vm = this;

      this.$connection.get(
        "/turRezervasyonlari/" + this.id + "/sozlesmeler?sozlesmeTipi=" + tip,
        function (response) {
          if (response.length > 0) {
            vm.$bvModal.show("sozlesmeDetay");
            vm.sozlesme = response[0];
            vm.sozlesme.SozlesmeMetni =
              '<link rel="preload" type="text/css" href="https://capbook.net/assets/print.css"/>' +
              vm.sozlesme.SozlesmeMetni;
          } else {
            vm.$alert.messageWithTitle(
              "Sözleşme",
              "Sözleşme bulunamadı.",
              "warning"
            );
          }
        }
      );
    },
    sozlesmeYazdir() {
      window.frames["sozlesmeFrame"].focus();
      window.frames["sozlesmeFrame"].print();
    },
    misafirListAc() {
      this.$bvModal.show("misafirListeDetay");
    },
    misafirListYazdir() {
      window.print();
    },
  },
};
</script>
