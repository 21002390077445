<template>
  <div>
    <Breadcrumbs main="Araç Detay" :title="pageTitle" />

    <div class="container-fluid tur-rezervasyon-detay">
      <div class="row">
        <div class="col-md-12">
          <ul
            class="nav nav-tabs border-tab tabs-scoial"
            id="top-tab"
            role="tablist"
          >
            <li class="nav-item" v-on:click="changetab('genel')">
              <a
                class="nav-link"
                :class="{ 'show active': showtab == 'genel' }"
                id="top-genel"
                data-toggle="tab"
                role="tab"
                aria-controls="genel"
                aria-selected="true"
                >Genel <b-badge variant="info">{{ aracFirma.Adi }}</b-badge></a
              >
            </li>
            <li class="nav-item" v-on:click="changetab('ucret')">
              <a
                class="nav-link"
                :class="{ 'show active': showtab == 'ucret' }"
                id="top-ucret"
                data-toggle="tab"
                role="tab"
                aria-controls="ucret"
                aria-selected="false"
                >Servis Ücretleri</a
              >
            </li>
            <li class="nav-item" v-on:click="changetab('folyo')">
              <a
                class="nav-link"
                :class="{ 'show active': showtab == 'folyo' }"
                id="top-folyo"
                data-toggle="tab"
                role="tab"
                aria-controls="folyo"
                aria-selected="false"
                >Folyo</a
              >
            </li>
            <li class="nav-item" v-on:click="changetab('kullanici')">
              <a
                class="nav-link"
                :class="{ 'show active': showtab == 'kullanici' }"
                id="top-kullanici"
                data-toggle="tab"
                role="tab"
                aria-controls="kullanici"
                aria-selected="false"
                >Kullanıcıları</a
              >
            </li>
          </ul>
        </div>
      </div>

      <div class="tab-content" id="top-tabContent">
        <div
          class="tab-pane fade"
          :class="{ 'show active': showtab == 'genel' }"
          id="genel"
          role="tabpanel"
          aria-labelledby="genel"
        >
          <div class="row">
            <div class="col-md-6">
              <b-card
                header-tag="div"
                header-bg-variant="primary"
                class="card-absolute"
              >
                <h5 slot="header">Bilgiler</h5>
                <b-card-body>
                  <table class="product-page-width">
                    <tbody>
                      <tr>
                        <th>Firma Adı</th>
                        <td>{{ aracFirma.Adi }}</td>
                      </tr>
                      <tr>
                        <th>Unvan</th>
                        <td>{{ aracFirma.Unvan }}</td>
                      </tr>
                      <tr>
                        <th>Adres</th>
                        <td>{{ aracFirma.Adres }}</td>
                      </tr>
                      <tr>
                        <th>Vergi Dairesi</th>
                        <td>{{ aracFirma.VergiDairesi }}</td>
                      </tr>
                      <tr>
                        <th>Vergi No</th>
                        <td>{{ aracFirma.VergiNo }}</td>
                      </tr>
                      <tr>
                        <th>Yetkili Kişi</th>
                        <td>{{ aracFirma.YetkiliKisi }}</td>
                      </tr>
                      <tr>
                        <th>Servis Alış Ücreti</th>
                        <td>
                          <c-money-cell
                            :fiyat="aracFirma.AlisUcreti"
                            simge="₺"
                          ></c-money-cell>
                        </td>
                      </tr>
                      <tr>
                        <th>Servis Satış Ücreti</th>
                        <td>
                          <c-money-cell
                            :fiyat="aracFirma.SatisUcreti"
                            simge="₺"
                          ></c-money-cell>
                        </td>
                      </tr>
                      <tr>
                        <th>Toplam Alacak</th>
                        <td>
                          <c-money-cell
                            :fiyat="aracFirma.ToplamAlacak"
                            simge="₺"
                          ></c-money-cell>
                        </td>
                      </tr>
                      <tr>
                        <th>Durum</th>
                        <td>
                          {{ aracFirma.DurumStr }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </b-card-body>
              </b-card>

              <b-card
                header-tag="div"
                header-bg-variant="info"
                class="card-absolute"
              >
                <h5 slot="header">Kayıt Bilgileri</h5>
                <b-card-body>
                  <table class="product-page-width">
                    <tbody>
                      <tr>
                        <th>Kayıt Tarihi</th>
                        <td>
                          <c-tarih-cell
                            :kayitTarihi="aracFirma.KayitTarihi"
                            :rowKey="aracFirma.Id"
                          ></c-tarih-cell>
                        </td>
                      </tr>
                      <tr>
                        <th>Güncelleme Tarihi</th>
                        <td>
                          <c-tarih-cell
                            :kayitTarihi="aracFirma.GuncelTarih"
                            :rowKey="aracFirma.Id"
                          ></c-tarih-cell>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </b-card-body>
              </b-card>
            </div>
            <div class="col-md-6">
              <div class="row">
                <div class="col-md-12">
                  <b-list-group class="mb-3">
                    <b-list-group-item
                      tag="a"
                      :to="`/aracFirma/kayit/${aracFirma.Id}`"
                      router-component-name="router-link"
                    >
                      <feather type="edit-3"></feather> Güncelle
                    </b-list-group-item>
                  </b-list-group>
                </div>
              </div>

              <b-card
                header-tag="div"
                header-bg-variant="warning"
                class="card-absolute"
              >
                <h5 slot="header">İletişim Bilgileri</h5>
                <b-card-body>
                  <table class="product-page-width">
                    <tbody>
                      <tr>
                        <th>Cep Telefonu</th>
                        <td>{{ aracFirma.GSM }}</td>
                      </tr>
                      <tr>
                        <th>Telefon</th>
                        <td>{{ aracFirma.Telefon }}</td>
                      </tr>
                      <tr>
                        <th>Email</th>
                        <td>{{ aracFirma.Email }}</td>
                      </tr>
                      <tr>
                        <th>Operasyon Yetkilisi</th>
                        <td>{{ aracFirma.OperasyonYetkilisi }}</td>
                      </tr>
                      <tr>
                        <th>Operasyon Telefonu</th>
                        <td>{{ aracFirma.OperasyonTel }}</td>
                      </tr>
                    </tbody>
                  </table>
                </b-card-body>
              </b-card>
            </div>
          </div>
        </div>

        <div
          class="tab-pane fade"
          :class="{ 'show active': showtab == 'ucret' }"
          id="ucret"
          role="tabpanel"
          aria-labelledby="ucret"
        >
          <div class="row">
            <div class="col-md-12">
              <AracFirmaUcret
                :aracFirma="aracFirma"
                v-if="aracFirma.Id != undefined"
              />
            </div>
          </div>
        </div>

        <div
          class="tab-pane fade"
          :class="{ 'show active': showtab == 'folyo' }"
          id="folyo"
          role="tabpanel"
          aria-labelledby="folyo"
        >
          <div class="row">
            <div class="col-md-12">
              <b-card
                header-tag="div"
                header-bg-variant="primary"
                class="card-absolute"
              >
                <h5 slot="header">Folyo</h5>
                <b-card-body class="p-1">
                  <AracFirmaFolyo
                    :aracFirma="aracFirma"
                    v-if="isFolyoLoad"
                    :noEdit="false"
                    @onKayitEklendi="folyoKayitEklendi()"
                  >
                  </AracFirmaFolyo>
                </b-card-body>
              </b-card>
            </div>
          </div>
        </div>

        <div
          class="tab-pane fade"
          :class="{ 'show active': showtab == 'kullanici' }"
          id="kullanici"
          role="tabpanel"
          aria-labelledby="kullanici"
        >
          <div class="row">
            <div class="col-md-12">
              <b-card
                header-tag="div"
                header-bg-variant="primary"
                class="card-absolute"
              >
                <h5 slot="header">Kullanıcıları</h5>
                <b-card-body class="p-1">
                  <AracFirmaKullanici
                    :aracFirmaId="aracFirma.Id"
                    v-if="isKullaniciLoad"
                    :kullanicilar="kullanicilar"
                    :noEdit="false"
                  >
                  </AracFirmaKullanici>
                </b-card-body>
              </b-card>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import AracFirmaFolyo from "@/components/c_aracFirma_folyo";
import AracFirmaKullanici from "@/components/c_aracFirma_kullanici";
import AracFirmaUcret from "@/components/c_aracFirma_ucret";

export default {
  data() {
    return {
      id: this.$route.params.id,

      pageTitle: "Araç Firma Detayı",

      showtab: "genel",

      aracFirma: {},

      isFolyoLoad: false,

      kayitBilgi: {
        AdSoyad: "",
        Email: "",
        Telefon: "",
        GSM: "",
      },

      kullanicilar: [],
      isKullaniciLoad: false,
    };
  },
  components: {
    AracFirmaFolyo,
    AracFirmaKullanici,
    AracFirmaUcret,
  },
  computed: {},
  mounted() {
    this.onDetay();
  },
  methods: {
    changetab(tab) {
      this.showtab = tab;

      switch (tab) {
        case "folyo":
          if (this.isFolyoLoad === false) this.folyoList();
          break;

        case "kullanici":
          if (this.isKullaniciLoad === false) this.kullaniciList();
          break;

        default:
          break;
      }
    },
    onDetay() {
      let vm = this;

      this.$connection.get("/aracFirmalari/" + this.id, function (response) {
        if (response.Success) {
          vm.aracFirma = response.Data;
        } else {
          vm.$alert.messageWithTitle("Araç Firma", response.Message, "error");
        }
      });
    },
    folyoList() {
      this.isFolyoLoad = true;
    },
    folyoKayitEklendi(){
      this.onDetay();
    },
    kullaniciList() {
      let vm = this;

      this.$connection.get(
        "/aracFirmalari/" + this.aracFirma.Id + "/kullanicilar",
        function (response) {
          vm.kullanicilar = response;
          vm.isKullaniciLoad = true;
        }
      );
    },
    kullaniciGuncellendi(payload) {
      this.kullanicilar = payload;
    },
  },
};
</script>
