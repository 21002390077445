var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"button-wrapper mb-3"},[_c('b-button',{staticClass:"button",on:{"click":function($event){return _vm.$refs.file.click()}}},[_c('input',{ref:"file",staticStyle:{"display":"none"},attrs:{"type":"file","accept":"image/*"},on:{"change":function($event){return _vm.loadImage($event)}}}),_vm._v(" Resim Yükle ")])],1),(_vm.image.src != null)?_c('div',[(_vm.image.src !== null)?_c('p',{staticClass:"lead"},[_vm._v(" Resmi küçültmek için; resmin üzerindeyken mouseun scroll tuşunu kullanabilirsiniz. ")]):_vm._e(),_c('CropNavigation',{attrs:{"zoom":_vm.zoom},on:{"change":_vm.onZoom}}),_c('cropper',{ref:"cropper",staticClass:"twitter-cropper",attrs:{"background-class":"twitter-cropper__background","foreground-class":"twitter-cropper__foreground","image-restriction":"stencil","stencil-size":_vm.stencilSize,"stencil-props":{
        lines: {},
        handlers: {},
        movable: false,
        resizable: false,
        scalable: false,
        previewClass: 'twitter-cropper__stencil',
      },"transitions":false,"debounce":false,"src":_vm.image.src},on:{"change":_vm.onChange}})],1):_vm._e(),_c('b-form-invalid-feedback',{attrs:{"state":_vm.kayit_resim_state}},[_c('strong',[_vm._v("Kokartınızı düzgün, geniş ve dik bir açıdan çekip yükleyiniz.")]),_c('br'),_vm._v(" Minumum 440px 770px boyutunda okunaklı ve anlaşılır kokart resminizi giriniz.")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }