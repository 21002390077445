<template>
  <div>
    <Breadcrumbs main="Tur Rezervasyonları" title="Liste" />

    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <div class="default-according">
            <b-card no-body class="mb-1">
              <b-card-header
                header-tag="div"
                role="tab"
                text-variant="dark"
                header-bg-variant="primary"
              >
                <h5 class="mb-0">
                  <b-button v-b-toggle.simpleaccordion-1
                    >DETAYLI FİLTRELEMEK İÇİN TIKLAYINIZ
                    <small
                      >({{
                        this.$formatHelper.formatDate(filtre.BaslangicTarihi)
                      }}
                      -
                      {{
                        this.$formatHelper.formatDate(filtre.BitisTarihi)
                      }}
                      arası rezervasyonlar listeleniyor)</small
                    >
                  </b-button>
                </h5>
              </b-card-header>
              <b-collapse
                id="simpleaccordion-1"
                accordion="myaccordion"
                role="tabpanel"
              >
                <b-card-body>
                  <b-form @submit="onFiltreSubmit">
                    <div class="form-row">
                      <div class="col-md-6 mb-3">
                        <label for="filtre_kelime">Kelime</label>
                        <b-form-input
                          type="text"
                          id="filtre_kelime"
                          v-model="filtre.Kelime"
                          placeholder="Kelime"
                        ></b-form-input>
                      </div>
                      <div class="col-md-6 mb-3">
                        <label for="filtre_kod">Rezervasyon Kodu</label>
                        <b-form-input
                          type="text"
                          id="filtre_kod"
                          v-model="filtre.Kod"
                          placeholder="Rezervasyon Kodu"
                        ></b-form-input>
                      </div>
                    </div>
                    <div class="form-row">
                      <div class="col-md-6 mb-3">
                        <label for="filtre_baslangictarihi"
                          >Başlangıç Tarihi</label
                        >
                        <datepicker
                          input-class="datepicker-here form-control digits"
                          id="filtre_baslangictarihi"
                          v-model="filtre.BaslangicTarihi"
                          :language="tr"
                          :clear-button="true"
                          clear-button-icon="fa fa-times"
                          placeholder="Başlangıç Tarihi"
                          :format="date_format"
                        >
                        </datepicker>
                      </div>
                      <div class="col-md-6 mb-3">
                        <label for="filtre_bitistarihi">Bitiş Tarihi</label>
                        <datepicker
                          input-class="datepicker-here form-control digits"
                          id="filtre_bitistarihi"
                          v-model="filtre.BitisTarihi"
                          :language="tr"
                          :clear-button="true"
                          clear-button-icon="fa fa-times"
                          placeholder="Bitiş Tarihi"
                          :format="date_format"
                        ></datepicker>
                      </div>
                    </div>
                    <div class="form-row">
                      <div class="col-md-6 mb-3" v-if="filtre_durumlar != null">
                        <label for="filtre_durum">Durum</label>
                        <b-form-select
                          id="filtre_durum"
                          v-model="filtre.Durum"
                          :options="filtre_durumlar"
                        ></b-form-select>
                      </div>
                      <div class="col-md-6 mb-3">
                        <label for="filtre_dil">Dil</label>
                        <b-form-select
                          id="filtre_dil"
                          v-model="filtre.DilId"
                          :options="filtre_diller"
                        ></b-form-select>
                      </div>
                    </div>
                    <div class="form-row">
                      <div
                        class="col-md-6 mb-3"
                        v-if="filtre_acenteler !== null"
                      >
                        <label for="filtre_acente">Acente</label>
                        <vue-bootstrap-typeahead
                          v-model="queryAcente"
                          :data="filtre_acenteler"
                          :serializer="(item) => item.Adi"
                          @hit="filtre.AcenteId = $event.Id"
                          placeholder="Acentelerde Ara"
                        />
                      </div>
                      <div
                        class="col-md-6 mb-3"
                        v-if="filtre_rehberler !== null"
                      >
                        <label for="filtre_rehber">Rehber</label>
                        <vue-bootstrap-typeahead
                          v-model="queryRehber"
                          :data="filtre_rehberler"
                          :serializer="(item) => item.AdSoyad"
                          @hit="filtre.RehberId = $event.EncId"
                          placeholder="Rehberlerde Ara"
                        />
                      </div>
                    </div>
                    <div class="form-row">
                      <div class="col-md-6 mb-3">
                        <label for="filtre_tur">Turlar</label>
                        <b-form-select
                          id="filtre_tur"
                          @change="turSec"
                          v-model="filtre.TurId"
                          :options="filtre_turlar"
                        ></b-form-select>
                      </div>
                      <div class="col-md-6 mb-3">
                        <label for="filtre_turOpsiyon">Tur Opsiyonu</label>
                        <b-form-select
                          id="filtre_turOpsiyon"
                          v-model="filtre.TurOpsiyonId"
                          :options="filtre_turOpsiyonlari"
                        ></b-form-select>
                      </div>
                    </div>
                    <div class="form-row">
                      <div
                        class="col-md-6 mb-3"
                        v-if="filtre_aracFirmalari !== null"
                      >
                        <label for="filtre_aracFirma">Araç Firma</label>
                        <b-form-select
                          id="filtre_aracFirma"
                          v-model="filtre.AracFirmaId"
                          :options="filtre_aracFirmalari"
                        ></b-form-select>
                      </div>
                      <div class="col-md-6 mb-3" v-if="filtre_araclar !== null">
                        <label for="filtre_arac">Araç</label>
                        <vue-bootstrap-typeahead
                          v-model="queryArac"
                          :data="filtre_araclar"
                          :serializer="
                            (item) => item.SoforAdi + ' (' + item.Plaka + ')'
                          "
                          @hit="filtre.AracId = $event.EncId"
                          placeholder="Araçlarda Ara"
                        />
                      </div>
                    </div>
                    <div class="form-row">
                      <div class="col-md-6 mb-3">
                        <b-button type="submit" variant="warning"
                          >Listele</b-button
                        >
                      </div>
                    </div>
                  </b-form>
                </b-card-body>
              </b-collapse>
            </b-card>
          </div>

          <div class="card">
            <div class="card-body">
              <c-table :tablefields="tablefields" :items="items">
                <template #cell(Islem)="row">
                  <b-btn-group>
                    <router-link
                      :to="`/turRezervasyon/detay/${row.item.Id}`"
                      class="btn btn-success btn-xs"
                      >Detay</router-link
                    >
                    <a
                      :href="'/reservation/tour/' + row.item.Id"
                      class="btn btn-info btn-xs"
                      target="_blank"
                      >Kart</a
                    >
                  </b-btn-group>
                </template>
                <template #cell(DurumStr)="row">
                  <RezervasyonDurum
                    :durum="row.item.DurumStr"
                    :durumInt="row.item.Durum"
                  />
                </template>
                <template #cell(Tarih)="row">
                  <c-tarih-cell
                    :kayitTarihi="row.item.Tarih"
                    :rowKey="row.item.Id"
                  ></c-tarih-cell>
                  <b-badge variant="primary">{{ row.item.Saat }}</b-badge>
                </template>
                <template #cell(ToplamTutar)="data">
                  <c-money-cell :fiyat="data.value" simge="TL"></c-money-cell>
                </template>
                <template #cell(KisiSayisi)="data">
                  <b-badge variant="primary">{{ data.value }}</b-badge>
                </template>
                <template #cell(SoforAdi)="row">
                  {{ row.item.SoforAdi }}
                  <b-badge variant="primary">{{ row.item.Plaka }}</b-badge>
                </template>
                <template #cell(KayitTarihi)="row">
                  <c-tarih-cell
                    :kayitTarihi="row.item.KayitTarihi"
                    :guncelTarih="row.item.GuncelTarih"
                    :rowKey="row.item.Id"
                  ></c-tarih-cell>
                </template>
              </c-table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Datepicker from "vuejs-datepicker";
import { tr } from "vuejs-datepicker/dist/locale";
import VueBootstrapTypeahead from "vue-bootstrap-typeahead";
import RezervasyonDurum from "@/components/c_turRezervasyon_durum";

export default {
  data() {
    return {
      tablefields: [
        { key: "Islem", label: "İşlemler", sortable: false },
        { key: "AcenteAdi", label: "Acente", sortable: true },
        { key: "Kod", label: "Kod", sortable: true },
        { key: "DurumStr", label: "Durum", sortable: true },
        { key: "Tarih", label: "Tarih", sortable: true },
        { key: "OtelAdi", label: "Otel", sortable: true },
        { key: "ToplamTutar", label: "Toplam", sortable: true },
        { key: "KisiSayisi", label: "Kişi", sortable: true },
        { key: "TurAdi", label: "Tur", sortable: true, thClass: "sabitKol" },
        {
          key: "TurOpsiyonAdi",
          label: "Opsiyon",
          sortable: true,
          thClass: "sabitKol",
        },
        { key: "RehberAdi", label: "Rehber", sortable: true },
        { key: "SoforAdi", label: "Şoför", sortable: true },
        { key: "KullaniciAdi", label: "Kullanıcı", sortable: true },
      ],

      items: [],

      date_format: "dd.MM.yyyy",
      tr: tr,

      filtre_durumlar: [
        { value: null, text: "Durumlar" },
        { value: "0", text: "Yeni" },
        { value: "20", text: "Pasif" },
        { value: "30", text: "Onaylı" },
        { value: "100", text: "İptal" },
      ],

      queryAcente: "",
      filtre_acenteler: [{ value: null, text: "Acenteler" }],

      filtre_turlar: [{ value: null, text: "Turlar" }],

      filtre_turOpsiyonlari: [{ value: null, text: "Tur Opsiyonları" }],

      queryRehber: "",
      filtre_rehberler: [{ value: null, text: "Rehberler" }],

      filtre_diller: [{ value: null, text: "Diller" }],

      filtre_aracFirmalari: [{ value: null, text: "Araç Firmaları" }],

      queryArac: "",
      filtre_araclar: [{ value: null, text: "Araçlar" }],

      filtre: {
        AcenteId: null,
        TurId: null,
        TurOpsiyonId: null,
        Kod: null,
        BaslangicTarihi: null,
        BitisTarihi: null,
        Kelime: null,
        Durum: null,
        RehberId: null,
        AracFirmaId: null,
        AracId: null,
        DilId: null,
        AracTipiId: null,
      },
    };
  },
  components: {
    Datepicker,
    VueBootstrapTypeahead,
    RezervasyonDurum,
  },
  watch: {
    queryAcente(newQuery) {
      let vm = this;

      let searchFiltre = {
        Kelime: newQuery,
      };

      this.$connection.post(
        "/acenteler/filtre",
        searchFiltre,
        "post",
        function (response) {
          vm.filtre_acenteler = response;
        }
      );
    },
    queryRehber(newQuery) {
      let vm = this;

      this.$connection.get(
        "/rehberler/selectByKelime?kelime=" + newQuery,
        function (response) {
          vm.filtre_rehberler = response;
        }
      );
    },
    queryArac(newQuery) {
      let vm = this;

      this.$connection.get(
        "/araclar/selectByKelime?kelime=" + newQuery,
        function (response) {
          vm.filtre_araclar = response;
        }
      );
    },
  },
  mounted() {
    this.setKullaniciTipi();

    this.dilList();
    this.turList();
    this.onList();
  },
  methods: {
    onFiltreSubmit(evt) {
      evt.preventDefault();
      this.onList();
    },
    dilList() {
      let vm = this;

      this.$connection.get("/diller/select", function (response) {
        vm.filtre_diller = vm.filtre_diller.concat(response);
      });
    },
    turList() {
      let vm = this;
      this.$connection.get("/turlar/forSale/0", function (response) {
        response = response.map((item) => {
          return { value: item.EncId, text: item.Adi };
        });
        vm.filtre_turlar = vm.filtre_turlar.concat(response);
      });
    },
    turSec() {
      let vm = this;
      this.$connection.get(
        "/turlar/opsiyonlarForSale/" + this.filtre.TurId,
        function (response) {
          response = response.map((item) => {
            return { value: item.EncId, text: item.Adi };
          });
          vm.filtre_turOpsiyonlari = [
            { value: null, text: "Tur Opsiyonları" },
          ].concat(response);
        }
      );
    },
    aracFirmaList() {
      let vm = this;
      this.$connection.get("/aracFirmalari", function (response) {
        response = response.map((item) => {
          return { value: item.Id, text: item.Adi };
        });
        vm.filtre_aracFirmalari = vm.filtre_aracFirmalari.concat(response);
      });
    },
    onList() {
      let vm = this;

      vm.filtre.MinBorc = vm.filtre.MinBorc === "" ? null : vm.filtre.MinBorc;
      vm.filtre.MaxBorc = vm.filtre.MaxBorc === "" ? null : vm.filtre.MaxBorc;

      this.$connection.post(
        "/turRezervasyonlari/filtre",
        vm.filtre,
        "post",
        function (response) {
          vm.items = response;
        }
      );
    },
    setKullaniciTipi() {
      let fiyatGoster = false;
      let acenteGoster = false;

      let bitisTarihi = new Date();
      let baslangicTarih = new Date();
      baslangicTarih.setDate(bitisTarihi.getDate() - 15);
      bitisTarihi.setDate(bitisTarihi.getDate() + 15);
      this.filtre.BaslangicTarihi = baslangicTarih;
      this.filtre.BitisTarihi = bitisTarihi;

      if (this.$user.isAcente()) {
        //Giren acente onun kini listeleyeceği sadece
        this.filtre.AcenteId = this.$user.acenteId();

        this.filtre_acenteler = null;
        this.filtre_aracFirmalari = null;
        this.filtre_araclar = null;
        this.filtre_rehberler = null;

        fiyatGoster = true;
      } else if (this.$user.isRehber()) {
        //Giren rehber onun kini listeleyeceği sadece
        this.filtre.RehberId = this.$user.rehberId();
        //this.filtre.Durum = 30;

        this.filtre_acenteler = null;
        this.filtre_aracFirmalari = null;
        this.filtre_araclar = null;
        this.filtre_rehberler = null;
        this.filtre_durumlar = null;
      } else if (this.$user.isArac()) {
        //Giren araç onun kini listeleyeceği sadece
        this.filtre.AracId = this.$user.aracId();
        //this.filtre.Durum = 30;

        this.filtre_acenteler = null;
        this.filtre_aracFirmalari = null;
        this.filtre_araclar = null;
        this.filtre_rehberler = null;
        this.filtre_durumlar = null;
      } else if (this.$user.isAracFirma()) {
        //Giren araç firması onun kini listeleyeceği sadece
        this.filtre.AracFirmaId = this.$user.aracFirmaId();

        this.filtre_acenteler = null;
        this.filtre_aracFirmalari = null;
        this.filtre_araclar = null;
        this.filtre_rehberler = null;
        this.filtre_durumlar = null;
      } else {
        this.aracFirmaList();

        fiyatGoster = true;
        acenteGoster = true;
      }

      this.tablefields = [
        { key: "Islem", label: "İşlemler", sortable: false },
        acenteGoster
          ? { key: "AcenteAdi", label: "Acente", sortable: true }
          : null,
        { key: "Kod", label: "Kod", sortable: true },
        { key: "DurumStr", label: "Durum", sortable: true },
        { key: "Tarih", label: "Tarih", sortable: true },
        { key: "OtelAdi", label: "Otel", sortable: true },
        fiyatGoster
          ? { key: "ToplamTutar", label: "Toplam", sortable: true }
          : null,
        { key: "KisiSayisi", label: "Kişi", sortable: true },
        { key: "TurAdi", label: "Tur", sortable: true, thClass: "sabitKol" },
        {
          key: "TurOpsiyonAdi",
          label: "Opsiyon",
          sortable: true,
          thClass: "sabitKol",
        },
        { key: "RehberAdi", label: "Rehber", sortable: true },
        { key: "SoforAdi", label: "Şoför", sortable: true },
        { key: "KullaniciAdi", label: "Kullanıcı", sortable: true },
      ];
    },
  },
};
</script>
