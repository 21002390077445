<template>
  <div>
    <Breadcrumbs main="Dashboard" title="Kontrol Paneli" />

    <div class="container-fluid">
      <div class="row">
        <div class="col-xl-12">
          <TurRezervasyonList />
        </div>
      </div>
    </div>

    <DuyuruGosterimModal />
  </div>
</template>
<script>
import TurRezervasyonList from "@/components/c_tur_rezervasyon_dashboard_aracFirma.vue";
import DuyuruGosterimModal from "@/components/c_duyuru_gosterim";

export default {
  data() {
    return {};
  },
  components: {
    TurRezervasyonList,
    DuyuruGosterimModal,
  },
  mounted() {
    let user = this.$user.getUser();
    if (user.Kullanici.RedirectUrl !== "/dashboard/aracFirma")
      this.$router.push(user.Kullanici.RedirectUrl);
  },
};
</script>
