<template>
  <div>
    <Breadcrumbs
      main="Rehberler"
      title="Onay Bekleyenler"
      :links="breadLinks"
    />

    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-body">
              <c-table :tablefields="tablefields" :items="items">
                <template #cell(AdSoyad)="row">
                  {{ row.item.AdSoyad }}<br />
                  <b-btn-group>
                    <router-link
                      :to="`/rehber/detay/${row.item.Id}`"
                      class="btn btn-dark btn-xs"
                      >Detay</router-link
                    >
                    <router-link
                      :to="`/rehber/kayit/${row.item.Id}`"
                      class="btn btn-info btn-xs"
                      >Düzelt</router-link
                    >
                    <b-button
                      size="xs"
                      @click="onayla(row.item)"
                      variant="success"
                      >Onayla</b-button
                    >
                    <b-button
                      size="xs"
                      @click="iptalEt(row.item)"
                      variant="warning"
                      >İptal Et</b-button
                    >
                    <b-button size="xs" @click="sil(row.item)" variant="danger"
                      >Sil</b-button
                    >
                  </b-btn-group>
                </template>
                <template #cell(Diller)="data">
                  <ul>
                    <li
                      v-for="(dil, index) in data.value"
                      :key="index"
                      class="d-inline-block"
                    >
                      <img class="img-20 rounded-circle" :src="dil" alt="" />
                    </li>
                  </ul>
                </template>
                <template #cell(KayitTarihi)="row">
                  <c-tarih-cell
                    :kayitTarihi="row.item.KayitTarihi"
                    :guncelTarih="row.item.GuncelTarih"
                    :rowKey="row.item.Id"
                  ></c-tarih-cell>
                </template>
              </c-table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      breadLinks: [{ path: "/rehber/kayit", title: "Ekle", icon: "plus" }],
      tablefields: [
        { key: "AdSoyad", label: "Adı", sortable: true },
        { key: "Diller", label: "Dil", sortable: true },
        { key: "GSM", label: "GSM", sortable: true, thClass: "sabitKol" },
        { key: "Email", label: "Email", sortable: true, thClass: "sabitKol" },
        { key: "KullaniciAdi", label: "Kullanıcı", sortable: true },
        { key: "KayitTarihi", label: "Kayıt Tarihi", sortable: true },
      ],

      items: [],
    };
  },
  mounted() {
    this.onList();
  },
  methods: {
    onList() {
      let vm = this;

      this.$connection.get("/rehberler/onayBekleyenler", function (response) {
        vm.items = response;
      });
    },
    sil(item) {
      let vm = this;
      this.$alert.confirm(
        "Sil",
        "Rehber kaydını silmek istediğinize emin misiniz?",
        "warning",
        function () {
          vm.$connection.delete("/rehberler/" + item.Id, function (response) {
            vm.$alert.messageWithFunction(
              "Silindi",
              "Rehber kaydı silindi.",
              "error",
              function () {
                vm.$listHelper.removeItem(vm.items, item);
              }
            );
          });
        }
      );
    },
    onayla(item) {
      let vm = this;
      this.$alert.confirm(
        "Onayla",
        "Rehberi onaylamak istediğinize emin misiniz?",
        "warning",
        function () {
          vm.$connection.get(
            "/rehberler/onayla/" + item.Id,
            function (response) {
              if (response.Success) {
                vm.$alert.messageWithFunction(
                  "Onaylandı",
                  "Rehber onaylandı.",
                  "success",
                  function () {
                    vm.$listHelper.removeItem(vm.items, item);
                  }
                );
              }
            }
          );
        }
      );
    },
    iptalEt(item) {
      let vm = this;
      this.$alert.confirm(
        "İptal Et",
        "Rehber aynı bilgilerle bir daha üye olamayacak. İptal etmek istedğinize emin misiniz?",
        "warning",
        function () {
          vm.$connection.get(
            "/rehberler/durumDegistir/" + item.Id + "/100",
            function (response) {
              if (response.Success) {
                vm.$alert.messageWithFunction(
                  "İptal Edildi",
                  "Rehber iptal edildi.",
                  "success",
                  function () {
                    vm.$listHelper.removeItem(vm.items, item);
                  }
                );
              }
            }
          );
        }
      );
    },
  },
};
</script>
