<template>
  <div class="page-wrapper">
    <div class="container-fluid p-0">
      <div class="authentication-main">
        <div class="row">
          <div class="col-md-12">
            <div class="auth-innerright">
              <div class="authentication-box">
                <div class="text-center">
                  <img alt="" src="../assets/images/logo.png" />
                </div>
                <div class="card mt-4">
                  <div class="card-body">
                    <div class="text-center">
                      <h4>Giriş</h4>
                      <h6>Kullanıcı adı ve şifrenizi yazınız</h6>
                    </div>
                    <b-form @submit="doLogin" class="theme-form">
                      <div class="form-group">
                        <label class="col-form-label pt-0">Kullanıcı Adı</label>
                        <b-form-input
                          type="text"
                          v-model="kullaniciAdi"
                          :state="login_kullaniciadi_state"
                          placeholder="Kullanıcı Adı"
                        ></b-form-input>
                        <b-form-invalid-feedback
                          :state="login_kullaniciadi_state"
                          >Kullanıcı adınızı giriniz</b-form-invalid-feedback
                        >
                      </div>
                      <div class="form-group">
                        <label class="col-form-label">Şifre</label>
                        <b-form-input
                          :type="type"
                          v-model="sifre"
                          :state="login_sifre_state"
                          placeholder="Şifre"
                        ></b-form-input>
                        <div class="show-hide">
                          <span class="show" @click="showPassword"> </span>
                          <b-form-invalid-feedback :state="login_sifre_state"
                            >Şifrenizi giriniz</b-form-invalid-feedback
                          >
                        </div>
                      </div>
                      <div class="form-row">
                        <div class="col-md-6">
                          <div class="checkbox p-0">
                            <b-form-checkbox
                              name="BeniHatirla"
                              v-model="beniHatirla"
                              >Beni Hatırla</b-form-checkbox
                            >
                          </div>
                        </div>
                        <div class="col-md-6 text-right">
                          <router-link
                            :to="'/giris/sifremiUnuttum'"
                            class="btn btn-link mt-1"
                            >Şifremi Unuttum</router-link
                          >
                        </div>
                      </div>
                      <div class="form-group form-row mt-3 mb-0">
                        <b-button
                          variant="dark"
                          class="btn-block mb-3"
                          type="submit"
                          >Giriş</b-button
                        >
                        <router-link
                          :to="'/giris/acenteBasvur'"
                          class="btn btn-primary btn-block"
                          >Acente Kayıt</router-link
                        >
                        <router-link
                          :to="'/giris/rehberBasvur'"
                          class="btn btn-secondary btn-block"
                          >Rehber Kayıt</router-link
                        >
                        <router-link
                          :to="'/giris/aracBasvur'"
                          class="btn btn-warning btn-block"
                          >Araç Kayıt</router-link
                        >
                      </div>
                    </b-form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      type: "password",
      kullaniciAdi: "",
      sifre: "",
      beniHatirla: false,
    };
  },
  computed: {
    login_kullaniciadi_state() {
      if (this.kullaniciAdi == null || this.kullaniciAdi == "") return false;
      else return true;
    },
    login_sifre_state() {
      if (this.sifre == null || this.sifre == "") return false;
      else return true;
    },
  },
  methods: {
    showPassword: function () {
      if (this.type === "password") {
        this.type = "text";
      } else {
        this.type = "password";
      }
    },
    doLogin(event) {
      event.preventDefault();

      let isValid = this.login_kullaniciadi_state && this.login_sifre_state;

      if (isValid) {
        let vm = this;
        let data = {
          KullaniciAdi: this.kullaniciAdi,
          Sifre: this.sifre,
          BeniHatirla: this.beniHatirla,
        };
        this.$connection.post("/auth", data, "post", function (response) {
          if (response.Success) {
            vm.$user.setUser(response.Data);
            vm.$router.push(response.Data.Kullanici.RedirectUrl);
          } else {
            vm.$alert.messageWithTitle("Giriş", response.Message, "error");
          }
        });
      }
    },
  },
};
</script>
