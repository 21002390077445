<template>
  <div>
    <Breadcrumbs main="Duyurular" :title="pageTitle" :links="breadLinks" />

    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <h5>Duyuru Kaydı</h5>
              <span
                >Yeni duyuru veya duyuru kaydında değişiklikler için aşağıdaki
                formu kullanabilirsiniz</span
              >
            </div>
            <div class="card-body">
              <b-form @submit="onSubmit">
                <div class="form-row">
                  <div class="col-md-6 mb-3">
                    <label for="kayit_baslik">Başlık</label>
                    <b-form-input
                      type="text"
                      id="kayit_baslik"
                      v-model="kayit.Baslik"
                      :state="kayit_baslik_state"
                      placeholder="Başlık"
                    ></b-form-input>
                    <b-form-invalid-feedback :state="kayit_baslik_state"
                      >Duyuru başlığını giriniz</b-form-invalid-feedback
                    >
                  </div>
                  <div class="col-md-6 mb-3">
                    <label for="kayit_oncelik">Öncelik</label>
                    <b-form-input
                      type="text"
                      id="kayit_oncelik"
                      v-model="kayit.Oncelik"
                      :state="kayit_oncelik_state"
                      placeholder="Öncelik"
                    ></b-form-input>
                    <b-form-invalid-feedback :state="kayit_oncelik_state"
                      >Öncelik alanına 0'dan büyük bir rakam
                      giriniz.</b-form-invalid-feedback
                    >
                  </div>
                </div>
                <div class="form-row">
                  <div class="col-md-12 mb-3">
                    <label for="kayit_detay">Detay</label>
                    <ckeditor
                      :editor="editor"
                      v-model="kayit.Detay"
                      :state="kayit_detay_state"
                    ></ckeditor>
                    <b-form-invalid-feedback :state="kayit_detay_state"
                      >Duyuru detayını giriniz</b-form-invalid-feedback
                    >
                  </div>
                </div>
                <div class="form-row">
                  <div class="col-md-6 mb-3">
                    <label for="kayit_sira">Sıra</label>
                    <b-form-input
                      type="text"
                      id="kayit_sira"
                      v-model="kayit.Sira"
                      :state="kayit_sira_state"
                      placeholder="Duyuru sırası"
                    ></b-form-input>
                    <b-form-invalid-feedback :state="kayit_sira_state"
                      >Duyuru sırasına 0'dan büyük bir rakam
                      giriniz</b-form-invalid-feedback
                    >
                  </div>
                  <div class="col-md-6 mb-3">
                    <label></label>
                    <div class="checkbox checkbox-dark">
                      <b-form-checkbox
                        name="Aktif"
                        v-model="kayit.Aktif"
                        :state="kayit.Aktif"
                        >Aktif</b-form-checkbox
                      >
                    </div>
                  </div>
                </div>
                <div class="form-row">
                  <div class="col-md-12 mb-3">
                    <label>Kullanıcı Tipleri</label>
                    <label class="d-block" for="kullaniciTipiTumunuSec"
                      ><input
                        class="checkbox_animated"
                        v-model="isKullaniciTipiTumunuSec"
                        value="true"
                        id="kullaniciTipiTumunuSec"
                        type="checkbox"
                        @change="kullaniciTipiTumunuSecChange"
                      />Tümünü Seç</label
                    >
                    <hr />
                    <div class="checkbox-animated">
                      <label
                        v-for="tip in kullaniciTipleri"
                        :key="tip.value"
                        class="d-block"
                        :for="'kullaniciTipiId' + tip.value"
                      >
                        <input
                          class="checkbox_animated"
                          v-model="selectedKullaniciTipleri"
                          :value="tip.value"
                          :id="'kullaniciTipiId' + tip.value"
                          type="checkbox"
                        />
                        {{ tip.text }}
                      </label>
                    </div>
                  </div>
                </div>
                <router-link
                  :to="'/duyuru/list'"
                  class="btn btn-light pull-left"
                  >Geri Dön</router-link
                >
                <b-button type="submit" class="pull-right" variant="primary"
                  >Kaydet</b-button
                >
              </b-form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

export default {
  data() {
    return {
      id: this.$route.params.id,

      pageTitle: "Yeni Duyuru",

      breadLinks: [
        { path: "/duyuru/list", title: "Duyurular", icon: "chevron-left" },
      ],

      kayit: {
        Oncelik: 0,
        Baslik: "",
        Detay: "",
        Aktif: true,
        Sira: 0,
        KullaniciTipleriList: [],
      },

      selectedKullaniciTipleri: [],

      isKullaniciTipiTumunuSec: false,

      kullaniciTipleri: [],

      editor: ClassicEditor,
    };
  },
  components: {},
  mounted() {
    this.onKullaniciTipiList();

    let vm = this;

    if (this.id !== undefined) {
      vm.pageTitle = "Duyuru Düzelt";

      this.$connection.get("/duyurular/" + this.id, function (response) {
        if (response.Success) {
          vm.kayit = response.Data;
          vm.selectedKullaniciTipleri = response.Data.KullaniciTipleriListInt;
        } else {
          vm.$alert.messageWithTitle("Yeni Duyuru", response.Message, "error");
        }
      });
    }
  },
  computed: {
    kayit_baslik_state() {
      if (this.kayit.Baslik == null || this.kayit.Baslik == "") return false;
      else return true;
    },
    kayit_oncelik_state() {
      if (this.kayit.Oncelik === "" || this.kayit.Oncelik === null)
        return false;
      else return true;
    },
    kayit_sira_state() {
      if (this.kayit.Sira === "" || this.kayit.Sira === null) return false;
      else return true;
    },
    kayit_detay_state() {
      if (this.kayit.Detay === "" || this.kayit.Detay === null) return false;
      else return true;
    },
  },
  methods: {
    onKullaniciTipiList() {
      let vm = this;

      this.$connection.get(
        "/kullanicilar/kullaniciTipleri",
        function (response) {
          vm.kullaniciTipleri = response;
        }
      );
    },
    kullaniciTipiTumunuSecChange() {
      if (this.isKullaniciTipiTumunuSec) {
        for (let i = 0; i < this.kullaniciTipleri.length; i++) {
          this.selectedKullaniciTipleri.push(this.kullaniciTipleri[i].value);
        }
      } else {
        this.selectedKullaniciTipleri = [];
      }
    },
    onSubmit(evt) {
      evt.preventDefault();

      let isValid =
        this.kayit_baslik_state &&
        this.kayit_oncelik_state &&
        this.kayit_sira_state &&
        this.kayit_detay_state;

      if (isValid) {
        let vm = this;

        let url = "/duyurular";
        let method = "post";
        let messageTitle = "Yeni Duyuru";
        let yeniKayit = vm.id === undefined;
        if (!yeniKayit) {
          url += "/" + vm.id;
          method = "put";
          messageTitle = "Duyuru Düzelt";
        }

        vm.kayit.KullaniciTipleriList = vm.selectedKullaniciTipleri;

        vm.$connection.post(
          url,
          vm.kayit,
          method,
          function (response) {
            if (response.Success) {
              vm.$alert.messageWithFunction(
                messageTitle,
                response.Message,
                "success",
                function () {
                  vm.$router.push("/duyuru/list");
                }
              );
            }
          },
          function (error) {
            vm.$alert.messageWithTitle(messageTitle, error.Message, "error");
          }
        );
      }
    },
  },
};
</script>
