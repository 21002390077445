<template>
  <div>
    <Breadcrumbs main="Turlar" :title="pageTitle" :links="breadLinks" />

    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <h5>Tur Opsiyon Kaydı</h5>
              <span
                >İlgili tura yeni opsiyon ekleyebilir veya opsiyonda değişiklik
                yapmak için aşağıdaki formu kullanabilirsiniz</span
              >
            </div>
            <div class="card-body">
              <b-form @submit="onCustomStyleSubmit">
                <div class="form-row">
                  <div class="col-md-12 mb-3">
                    <label for="kayit_adi">Opsiyon Adı</label>
                    <b-form-input
                      type="text"
                      id="kayit_adi"
                      v-model="kayit.Adi"
                      :state="kayit_adi_state"
                      placeholder="Opsiyon adı"
                    ></b-form-input>
                    <b-form-invalid-feedback :state="kayit_adi_state"
                      >Opsiyon adını giriniz</b-form-invalid-feedback
                    >
                  </div>
                </div>
                <div class="form-row">
                  <div class="col-md-12 mb-3">
                    <label for="kayit_aciklama">Açıklama</label>
                    <ckeditor
                      :editor="editor"
                      v-model="kayit.Aciklama"
                      :state="kayit_aciklama_state"
                    ></ckeditor>
                    <b-form-invalid-feedback :state="kayit_aciklama_state"
                      >Opsiyon açıklamasını giriniz</b-form-invalid-feedback
                    >
                  </div>
                </div>
                <div class="form-row">
                  <div class="col-md-6 mb-3">
                    <label for="kayit_fiyat">Fiyat</label>
                    <b-input-group prepend="TL">
                      <b-form-input
                        type="text"
                        id="kayit_fiyat"
                        v-model="kayit.Fiyat"
                        :state="kayit_fiyat_state"
                        placeholder="Opsiyon fiyatı"
                      ></b-form-input>
                      <b-form-invalid-feedback :state="kayit_fiyat_state"
                        >Opsiyon fiyatını giriniz</b-form-invalid-feedback
                      >
                    </b-input-group>
                  </div>
                  <div class="col-md-6 mb-3">
                    <label for="kayit_tipi">Restoran</label>
                    <b-form-select
                      id="kayit_restoranId"
                      v-model="kayit.RestoranId"
                      :options="restoranlar"
                    ></b-form-select>
                  </div>
                </div>
                <div class="form-row">
                  <div class="col-md-6 mb-3">
                    <label for="kayit_sira">Sıra</label>
                    <b-form-input
                      type="text"
                      id="kayit_sira"
                      v-model="kayit.Sira"
                      placeholder="Liste Sırası"
                    ></b-form-input>
                    <p>
                      Opsiyon listelemesinde en büyük değer en başta gözükür.
                    </p>
                  </div>
                </div>
                <div class="form-row">
                  <div class="col-md-12 mb-3 animate-chk">
                    <label>Müzeler</label>
                    <label class="d-block" for="muzeTumunuSec"
                      ><input
                        class="checkbox_animated"
                        v-model="isMuzeTumunuSec"
                        value="true"
                        id="muzeTumunuSec"
                        type="checkbox"
                        @change="muzeTumunuSecChange"
                      />Tümünü Seç</label
                    >
                    <hr />
                    <div class="checkbox-animated">
                      <label
                        v-for="muze in muzeler"
                        :key="muze.Id"
                        class="d-block"
                        :for="'muzeId' + muze.Id"
                      >
                        <input
                          class="checkbox_animated"
                          v-model="selectedMuzeler"
                          :value="muze.Id"
                          :id="'muzeId' + muze.Id"
                          type="checkbox"
                        />
                        {{ muze.Tanim }}
                        <b-badge variant="primary"
                          >{{ muze.GirisUcreti }} {{ muze.Simge }}</b-badge
                        >
                      </label>
                    </div>
                  </div>
                </div>
                <router-link
                  :to="`/tur/opsiyon/${opsiyonId}`"
                  class="btn btn-light pull-left"
                  >Geri Dön</router-link
                >
                <b-button type="submit" class="pull-right" variant="primary"
                  >Kaydet</b-button
                >
              </b-form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

export default {
  data() {
    return {
      opsiyonId: this.$route.params.opsiyonId,
      id: this.$route.params.id,

      pageTitle: "",

      breadLinks: [
        {
          path: "/tur/opsiyon/" + this.$route.params.opsiyonId,
          title: "Turlar",
          icon: "chevron-left",
        },
      ],

      kayit: {
        Adi: "",
        Aciklama: "",
        RestoranId: 0,
        Fiyat: 0,
        Sira: 0,
        Muzeler: [],
        Mekanlar: [],
      },

      isMuzeTumunuSec: false,

      selectedMuzeler: [],

      restoranlar: [],
      muzeler: [],

      editor: ClassicEditor,
    };
  },
  components: {},
  mounted() {
    let vm = this;

    if (this.opsiyonId !== undefined) {
      vm.pageTitle = "";

      this.$connection.get("/turlar/" + this.opsiyonId, function (response) {
        if (response.Success) {
          vm.pageTitle = response.Data.Adi;
        } else {
          vm.$alert.messageWithTitle(
            "Opsiyon Kaydet",
            response.Message,
            "error"
          );
        }
      });
    }

    if (this.id !== undefined) {
      this.$connection.get(
        "/turlar/opsiyonlarGetById/" + this.id,
        function (response) {
          if (response.Success) {
            vm.kayit = response.Data;

            vm.kayit.Muzeler = response.Data.MuzelerInt;
            vm.selectedMuzeler = response.Data.MuzelerInt;
            vm.kayit.Mekanlar = response.Data.MekanlarInt;
            vm.selectedMekanlar = response.Data.MekanlarInt;
          } else {
            vm.$alert.messageWithTitle(
              "Opsiyon Kaydet",
              response.Message,
              "error"
            );
          }
        }
      );
    }

    this.$connection.get("/restoranlar/select", function (response) {
      vm.restoranlar = [
        {
          value: "0",
          text: "Seçiniz",
        },
      ];
      for (var i = 0; i < response.length; i++) {
        vm.restoranlar.push({
          value: response[i].Id,
          text:
            response[i].Adi +
            " (A.F: " +
            response[i].MenuFiyatiAlis +
            " TL) " +
            " (S.F: " +
            response[i].MenuFiyatiSatis +
            " TL) ",
        });
      }
    });

    this.$connection.get("/muzeler/select", function (response) {
      vm.muzeler = response;
    });
  },
  computed: {
    kayit_adi_state() {
      if (this.kayit.Adi == null || this.kayit.Adi == "") return false;
      else return true;
    },
    kayit_fiyat_state() {
      return true;
      // if (this.kayit.Fiyat == 0)
      //   return false;
      // else
      //   return true;
    },
    kayit_aciklama_state() {
      if (this.kayit.Aciklama == null || this.kayit.Aciklama == "")
        return false;
      else return true;
    },
  },
  methods: {
    muzeTumunuSecChange() {
      if (this.isMuzeTumunuSec) {
        for (let i = 0; i < this.muzeler.length; i++) {
          this.selectedMuzeler.push(this.muzeler[i].Id);
        }
      } else {
        this.selectedMuzeler = [];
      }
    },
    onCustomStyleSubmit(evt) {
      evt.preventDefault();

      let isValid =
        this.kayit_adi_state &&
        this.kayit_fiyat_state &&
        this.kayit_aciklama_state;

      if (isValid) {
        let vm = this;

        let url = "/turlar/opsiyonlar/" + vm.opsiyonId;
        let method = "post";
        let messageTitle = "Yeni Opsiyonu";
        let yeniKayit = vm.id === undefined;
        if (!yeniKayit) {
          url = "/turlar/opsiyonlar/" + vm.id;
          method = "put";
          messageTitle = "Opsiyon Düzelt";
        }

        vm.kayit.Muzeler = vm.selectedMuzeler;
        vm.kayit.Mekanlar = [];

        vm.$connection.post(
          url,
          vm.kayit,
          method,
          function (response) {
            if (response.Success) {
              vm.$alert.messageWithFunction(
                messageTitle,
                response.Message,
                "success",
                function () {
                  vm.$router.push("/tur/opsiyon/" + vm.opsiyonId);
                }
              );
            }
          },
          function (error) {
            vm.$alert.messageWithTitle(messageTitle, error.Message, "error");
          }
        );
      }
    },
  },
};
</script>
