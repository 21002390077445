<template>
  <div>
    <Breadcrumbs
      main="Bağımsız Araçlar"
      :title="pageTitle"
      :links="breadLinks"
      v-if="breadLinks.length > 0"
    />

    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-body">
              <c-table :tablefields="tablefields" :items="items">
                <template #cell(Islem)="row">
                  <b-btn-group>
                    <router-link
                      :to="`/aracFirma/aracDetay/${row.item.Id}`"
                      class="btn btn-info btn-xs"
                      >Detay</router-link
                    >
                    <router-link
                      :to="`/aracFirma/aracKayit/0/${row.item.Id}`"
                      class="btn btn-success btn-xs"
                      >Düzelt</router-link
                    >
                    <b-button size="xs" @click="sil(row.item)" variant="danger"
                      >Sil</b-button>
                  </b-btn-group>
                </template>
                <template #cell(ToplamAlacak)="data">
                  <c-money-cell :fiyat="data.value" simge="TL"></c-money-cell>
                </template>
                <template #cell(DurumStr)="row">
                  <b-badge :variant="durumVariant(row.item.Durum)">{{
                    row.item.DurumStr
                  }}</b-badge>
                </template>
                <template #cell(KayitTarihi)="row">
                  <c-tarih-cell
                    :kayitTarihi="row.item.KayitTarihi"
                    :guncelTarih="row.item.GuncelTarih"
                    :rowKey="row.item.Id"
                  ></c-tarih-cell>
                </template>
              </c-table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      pageTitle: "",

      breadLinks: [],

      tablefields: [
        { key: "Islem", label: "İşlemler", sortable: false },
        { key: "SoforAdi", label: "Şoför", sortable: true },
        { key: "Plaka", label: "Plaka", sortable: true },
        { key: "GSM", label: "GSM", sortable: true, thClass: "sabitKol" },
        { key: "Email", label: "Email", sortable: true, thClass: "sabitKol" },
        { key: "Marka", label: "Marka", sortable: true },
        { key: "Model", label: "Model", sortable: true },
        { key: "ToplamAlacak", label: "Bakiye", sortable: true },
        { key: "Sira", label: "Sıra", sortable: true },
        { key: "DurumStr", label: "Durum", sortable: true },
        { key: "KullaniciAdi", label: "Kullanıcı", sortable: true },
        { key: "KayitTarihi", label: "Kayıt Tarihi", sortable: true },
      ],

      items: [],
    };
  },
  mounted() {
    this.breadLinks = [
      {
        path: "/aracFirma/aracKayit/0",
        title: "Ekle",
        icon: "plus",
      },
    ];

    this.onList();
  },
  methods: {
    onList() {
      let vm = this;

      let filtre = {
        Kelime: null,
        AracFirmaId: "0",
      };

      this.$connection.post(
        "/araclar/filtre",
        filtre,
        "post",
        function (response) {
          vm.items = response;
        }
      );
    },
    sil(item) {
      let vm = this;
      this.$alert.confirm(
        "Sil",
        "Aracı silmek istediğinize emin misiniz?",
        "warning",
        function () {
          vm.$connection.delete("/araclar/" + item.Id, function (response) {
            vm.$alert.messageWithFunction(
              "Silindi",
              "Araç kaydı silindi.",
              "error",
              function () {
                vm.$listHelper.removeItem(vm.items, item);
              }
            );
          });
        }
      );
    },
    durumVariant(durum) {
      switch (durum) {
        case 0:
          return "info";
        case 20:
          return "warning";
        case 30:
          return "success";
        case 40:
          return "dark";
        case 100:
          return "danger";
        default:
          return "light";
      }
    },
  },
};
</script>
