<template>
  <div>
    <Breadcrumbs main="Acente" title="Kontrol Paneli" />

    <div class="container-fluid">
      <div class="row" v-if="profilDurumu !== ''">
        <div class="col-xl-12">
          <b-alert show variant="danger">
            <h4 class="alert-heading">Profil Durumunuz</h4>
            <p>
              Profilinizde bazı eksiklikler bulduk. Bunları doldurduğunuzda
              rezervasyon işlemlerinizi kolaylaştıracaktır.
            </p>
            <hr />
            <p v-html="profilDurumu" class="mb-3"></p>
            <p>
              <router-link to="/acente/profil" class="btn btn-dark"
                >Güncelle</router-link
              >
              Profil sayfanızdan giriş yapıp Düzelt butonuna basarak gerekli
              güncellemeleri yapabilirsiniz.
            </p>
          </b-alert>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <ul
            class="nav nav-tabs border-tab tabs-scoial"
            id="top-tab"
            role="tablist"
          >
            <li class="nav-item" v-on:click="changetab('rezervasyon')">
              <a
                class="nav-link"
                :class="[
                  { 'show active': showtab == 'rezervasyon' },
                  'shadow-lg p-25 shadow-showcase',
                ]"
                id="top-rezervasyon"
                data-toggle="tab"
                role="tab"
                aria-controls="rezervasyon"
                aria-selected="true"
                >Yeni Tur Rezervasyonu</a
              >
            </li>
            <li class="nav-item" v-on:click="changetab('dashboard')">
              <a
                class="nav-link"
                :class="[
                  { 'show active': showtab == 'dashboard' },
                  'shadow-lg p-25 shadow-showcase',
                ]"
                id="top-dashboard"
                data-toggle="tab"
                role="tab"
                aria-controls="dashboard"
                aria-selected="false"
                >Kontrol Paneli
                <b-badge variant="primary"
                  >Önümüzdeki 7 Gün {{ turRezervasyonSayi }} Rezervasyon
                  Var</b-badge
                ></a
              >
            </li>
          </ul>
        </div>
      </div>

      <div class="tab-content" id="top-tabContent">
        <div
          class="tab-pane fade"
          :class="{ 'show active': showtab == 'rezervasyon' }"
          id="rezervasyon"
          role="tabpanel"
          aria-labelledby="rezervasyon"
        >
          <!-- <div class="default-according">
  
              <b-card no-body class="mb-1">
                <b-card-header header-tag="div" class="bg-primary">
                  <h5 class="mb-0">
                    <b-button style="display: block; width: 100%; text-align: left;" v-b-toggle.primary_1>Yeni Tur
                      Rezervasyonu <feather :type="rezervasyonPanelVisible ? 'arrow-up' : 'arrow-down'"></feather>
                    </b-button>
                  </h5>
                </b-card-header>
                <b-collapse id="primary_1" :visible="rezervasyonPanelVisible" accordion="my-accordion-primary"
                  role="tabpanel" @shown="sidebarChange(true)" @hidden="sidebarChange(false)">
                  <b-card-body> -->
          <TurRezervasyonForm
            @onComplete="onComplete"
            :acenteId="acenteId"
          ></TurRezervasyonForm>
          <!--</b-card-body>
                </b-collapse>
              </b-card>
  
            </div>-->
        </div>

        <div
          class="tab-pane fade"
          :class="{ 'show active': showtab == 'dashboard' }"
          id="dashboard"
          role="tabpanel"
          aria-labelledby="dashboard"
        >
          <div class="row">
            <div class="col-xl-12">
              <TurRezervasyonList />
            </div>
          </div>
        </div>
      </div>
    </div>

    <DuyuruGosterimModal />
  </div>
</template>
<script>
import TurRezervasyonForm from "@/components/c_tur_rezervasyon_form.vue";
import TurRezervasyonList from "@/components/c_tur_rezervasyon_dashboard_acente.vue";
import DuyuruGosterimModal from "@/components/c_duyuru_gosterim";

export default {
  data() {
    return {
      acenteId: 0,
      rezervasyonPanelVisible: true,

      showtab: "rezervasyon",

      turRezervasyonSayi: 0,

      profilDurumu: "",
    };
  },
  components: {
    TurRezervasyonForm,
    TurRezervasyonList,
    DuyuruGosterimModal,
  },
  mounted() {
    let user = this.$user.getUser();
    this.acenteId = user.Kullanici.AcenteId;
    this.turRezervasyonSayiLoad();
    this.profilDurumLoad();
  },
  methods: {
    changetab(tab) {
      this.showtab = tab;
    },
    onComplete(kayit) {
      let vm = this;

      let url = "/turRezervasyonlari/" + this.acenteId;
      let method = "post";
      let messageTitle = "Yeni Rezervasyon";

      vm.$connection.post(
        url,
        kayit,
        method,
        function (response) {
          if (response.Success) {
            vm.$alert.messageWithFunction(
              messageTitle,
              response.Message,
              "success",
              function () {
                vm.$router.push("/turRezervasyon/detay/" + response.Data);
              }
            );
          }
        },
        function (error) {
          vm.$alert.messageWithTitle(messageTitle, error.Message, "error");
        }
      );
    },
    turRezervasyonSayiLoad() {
      let vm = this;

      this.$connection.get(
        "/turRezervasyonlari/sayiForAcente",
        function (response) {
          vm.turRezervasyonSayi = response;
        }
      );
    },
    profilDurumLoad() {
      let vm = this;
      this.$connection.get("/acenteler/profilDurum", function (response) {
        vm.profilDurumu = response;
      });
    },
  },
};
</script>
