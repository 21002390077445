<template>
  <div>
    <Breadcrumbs
      main="Balon Rezervasyonu"
      :title="pageTitle"
      :links="breadLinks"
    />

    <div class="container-fluid tur-rezervasyon-duzelt">
      <div class="card">
        <div class="card-body">
          <form-wizard
            color="#4466f2"
            :start-index="0"
            back-button-text="Geri Dön"
            next-button-text="İleri"
            finish-button-text="Düzelt"
            @on-complete="onComplete()"
          >
            <tab-content title="Tur">
              <div class="setup-content" id="step-1">
                <div class="row mega-inline">
                  <div class="col-md-12">
                    <b-form-group class="mb-3">
                      <div class="row">
                        <div
                          class="col-sm-6"
                          v-for="tur in turlar"
                          :key="tur.Id"
                        >
                          <div class="card">
                            <div class="media p-20">
                              <div :class="'radio radio-' + tur.Renk">
                                <b-form-radio
                                  name="TurId"
                                  @change="turDegistir(tur)"
                                  v-model="kayit.TurId"
                                  :value="tur.Id"
                                >
                                  <div class="media-body">
                                    <h6 class="mt-0 mega-title-badge">
                                      {{ tur.Adi }}
                                      <span
                                        :class="
                                          'badge badge-' +
                                          tur.Renk +
                                          ' pull-right digits'
                                        "
                                        >{{ tur.TipiStr }}</span
                                      >
                                    </h6>
                                  </div>
                                </b-form-radio>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </b-form-group>

                    <h4 class="text-center">
                      <b-badge :variant="activeTur.Renk">{{
                        activeTur.Adi
                      }}</b-badge>
                      Opsiyonları
                    </h4>

                    <b-form-group class="mt-3">
                      <div class="row">
                        <div
                          class="col-sm-6"
                          v-for="opsiyon in turOpsiyonlari"
                          :key="opsiyon.Id"
                        >
                          <div class="card">
                            <div class="media p-20">
                              <div :class="'radio radio-' + activeTur.Renk">
                                <b-form-radio
                                  name="radio3"
                                  @change="opsiyonDegistir(opsiyon)"
                                  v-model="kayit.TurOpsiyonId"
                                  :value="opsiyon.Id"
                                >
                                  <div class="media-body">
                                    <h6 class="mt-0 mega-title-badge">
                                      {{ opsiyon.Adi }}
                                      <span
                                        :class="
                                          'badge badge-' +
                                          activeTur.Renk +
                                          ' pull-right digits'
                                        "
                                      >
                                        <c-money-cell
                                          :fiyat="opsiyon.Fiyat"
                                          simge="TL"
                                        ></c-money-cell>
                                      </span>
                                    </h6>
                                    <p v-html="opsiyon.Aciklama"></p>
                                  </div>
                                </b-form-radio>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </b-form-group>

                    <b-modal
                      id="opsiyonDetay"
                      size="lg"
                      centered
                      :title="activeOpsiyon.Adi"
                      ok-title="Tamam"
                      cancel-title="Kapat"
                      class="theme-modal"
                    >
                      <div
                        class="row"
                        v-if="activeOpsiyonDetay.RestoranAdi !== null"
                      >
                        <div class="col-md-12 mb-3">
                          <h5>
                            Restoran
                            <b-badge variant="warning">{{
                              activeOpsiyonDetay.RestoranAdi
                            }}</b-badge>
                          </h5>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <h3>Müzeler</h3>
                          <b-list-group>
                            <b-list-group-item
                              v-for="muze in activeOpsiyonDetay.Muzeler"
                              :key="muze.Id"
                              tag="a"
                              href="#"
                              class="flex-column align-items-start"
                            >
                              <div class="d-flex w-100 justify-content-between">
                                <h5 class="mb-1">{{ muze.Tanim }}</h5>
                              </div>
                              <p class="mb-1">
                                Giriş Ücreti :
                                <c-money-cell
                                  :fiyat="muze.GirisUcreti"
                                  simge="TL"
                                ></c-money-cell>
                              </p>
                            </b-list-group-item>
                          </b-list-group>
                        </div>
                        <div class="col-md-6">
                          <h3>Mekanlar</h3>
                          <b-list-group>
                            <b-list-group-item
                              v-for="mekan in activeOpsiyonDetay.Mekanlar"
                              :key="mekan.Id"
                              tag="a"
                              href="#"
                              class="flex-column align-items-start"
                            >
                              <div class="d-flex w-100 justify-content-between">
                                <h5 class="mb-1">{{ mekan.Adi }}</h5>
                              </div>
                              <p class="mb-1">
                                Komisyon Oranı : %{{ mekan.KomisyonOrani }}
                              </p>
                            </b-list-group-item>
                          </b-list-group>
                        </div>
                      </div>
                    </b-modal>
                  </div>
                </div>
              </div>
            </tab-content>
            <tab-content title="Detay">
              <div class="setup-content" id="step-2">
                <div class="row">
                  <div class="col-md-12">
                    <div class="form-row">
                      <div class="col-md-6 mb-3">
                        <label for="kayit_tarih">Tarih</label>
                        <datepicker
                          :language="tr"
                          input-class="datepicker-here form-control digits"
                          id="kayit_tarih"
                          v-model="kayit.Tarih"
                          :clear-button="true"
                          clear-button-icon="fa fa-times"
                          placeholder="Tur Tarihi"
                          :format="date_format"
                          :state="kayit_tarih_state"
                        ></datepicker>
                        <b-form-invalid-feedback :state="kayit_tarih_state"
                          >Tur tarihini giriniz</b-form-invalid-feedback
                        >
                      </div>
                      <div class="col-md-6 mb-3">
                        <label for="kayit_saat">Saat</label>
                        <b-form-timepicker
                          label-close-button="Kapat"
                          v-model="kayit.Saat"
                          id="kayit_saat"
                        ></b-form-timepicker>
                        <b-form-invalid-feedback :state="kayit_saat_state"
                          >Tur saatini giriniz</b-form-invalid-feedback
                        >
                      </div>
                    </div>
                    <div class="form-row">
                      <div class="col-md-12 mb-3">
                        <label for="kayit_aciklama">Açıklama</label>
                        <b-form-textarea
                          id="kayit_aciklama"
                          v-model="kayit.Aciklama"
                          placeholder="Açıklama"
                        ></b-form-textarea>
                      </div>
                    </div>
                    <div class="form-row">
                      <div class="col-md-6 mb-3">
                        <label for="kayit_oteladi">Otel Adı</label>
                        <b-form-input
                          type="text"
                          id="kayit_oteladi"
                          v-model="kayit.OtelAdi"
                          :state="kayit_oteladi_state"
                          placeholder="Otel Adı"
                        ></b-form-input>
                        <b-form-invalid-feedback :state="kayit_oteladi_state"
                          >Otel adını giriniz</b-form-invalid-feedback
                        >
                      </div>
                      <div class="col-md-6 mb-3">
                        <label for="kayit_dilid">Dil</label>
                        <b-form-select
                          id="kayit_dilid"
                          v-model="kayit.DilId"
                          :options="kayit_dil_options"
                          :state="kayit_dilid_state"
                        ></b-form-select>
                        <b-form-invalid-feedback :state="kayit_dilid_state"
                          >Turun dilini seçiniz</b-form-invalid-feedback
                        >
                      </div>
                    </div>
                    <div class="form-row">
                      <div class="col-md-12 mb-5">
                        <h4 class="f-w-600">
                          Misafirler
                          <b-badge variant="primary"
                            >{{ kayit.Misafirler.length }} kişi</b-badge
                          >
                        </h4>
                        <table class="table">
                          <thead>
                            <tr class="bg-warning">
                              <th>
                                <b-form-input
                                  type="text"
                                  v-model="misafirKayit.PasaportNo"
                                  placeholder="Pasaport No / T.C. Kimlik No"
                                ></b-form-input>
                              </th>
                              <th>
                                <b-form-input
                                  type="text"
                                  v-model="misafirKayit.Uyruk"
                                  placeholder="Uyruk"
                                ></b-form-input>
                              </th>
                              <th>
                                <b-form-input
                                  type="text"
                                  v-model="misafirKayit.AdSoyad"
                                  placeholder="Adı Soyadı"
                                ></b-form-input>
                              </th>
                              <th>
                                <b-form-input
                                  type="number"
                                  v-model="misafirKayit.Yas"
                                  placeholder="Yaş"
                                ></b-form-input>
                              </th>
                              <th>
                                <b-form-input
                                  type="text"
                                  v-model="misafirKayit.Telefon"
                                  placeholder="Telefon"
                                ></b-form-input>
                              </th>
                              <th>
                                <b-button
                                  type="button"
                                  @click="misafirEkle()"
                                  variant="info"
                                  >Ekle</b-button
                                >
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-if="kayit.Misafirler.length === 0">
                              <td colspan="6" class="text-center">
                                Bu Tur İçin Misafir Girilmemiş
                              </td>
                            </tr>
                            <tr
                              v-for="row in kayit.Misafirler"
                              v-bind:key="row.Id"
                            >
                              <td>
                                <b-form-input
                                  type="text"
                                  v-model="row.PasaportNo"
                                  placeholder="Pasaport No / T.C. Kimlik No"
                                ></b-form-input>
                              </td>
                              <td>
                                <b-form-input
                                  type="text"
                                  v-model="row.Uyruk"
                                  placeholder="Uyruk"
                                ></b-form-input>
                              </td>
                              <td>
                                <b-form-input
                                  type="text"
                                  v-model="row.AdSoyad"
                                  placeholder="Adı Soyadı"
                                ></b-form-input>
                              </td>
                              <td>
                                <b-form-input
                                  type="number"
                                  v-model="row.Yas"
                                  placeholder="Yaşı"
                                ></b-form-input>
                              </td>
                              <td>
                                <b-form-input
                                  type="text"
                                  v-model="row.Telefon"
                                  placeholder="Telefon"
                                ></b-form-input>
                              </td>
                              <td>
                                <b-button
                                  type="button"
                                  @click="misafirKaldir(row)"
                                  variant="danger"
                                  >Kaldır</b-button
                                >
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </tab-content>
            <tab-content title="Rehber">
              <div class="setup-content" id="step-3">
                <div class="row">
                  <div
                    class="col-md-3"
                    v-for="rehber in rehberler"
                    :key="rehber.Id"
                  >
                    <b-card
                      header-tag="div"
                      :bg-variant="
                        rehber.Id === kayit.RehberId ? 'dark' : 'light'
                      "
                      :header-bg-variant="
                        rehber.Id === kayit.RehberId ? 'primary' : 'light'
                      "
                      class="card-absolute"
                    >
                      <h5 slot="header">
                        <b-link
                          :class="
                            rehber.Id === kayit.RehberId
                              ? 'text-white'
                              : 'text-black'
                          "
                          @click="rehberSec(rehber)"
                        >
                          {{ rehber.AdSoyad }}
                        </b-link>
                      </h5>
                      <div class="product-box">
                        <div class="product-img">
                          <img class="img-fluid" :src="rehber.Resim" alt />
                        </div>
                        <div class="product-details">
                          <b-button
                            class="primary"
                            :disabled="rehber.Id === kayit.RehberId"
                            @click="rehberSec(rehber)"
                          >
                            Rehberi Seç
                          </b-button>
                        </div>
                      </div>
                    </b-card>
                  </div>
                </div>
              </div>
            </tab-content>
            <tab-content title="Araç">
              <div class="setup-content" id="step-4">
                <div class="row">
                  <div class="col-md-3" v-for="arac in araclar" :key="arac.Id">
                    <b-card
                      header-tag="div"
                      :bg-variant="arac.Id === kayit.AracId ? 'dark' : 'light'"
                      :header-bg-variant="
                        arac.Id === kayit.AracId ? 'primary' : 'light'
                      "
                      class="card-absolute"
                    >
                      <h5 slot="header">
                        <b-link
                          :class="
                            arac.Id === kayit.AracId
                              ? 'text-white'
                              : 'text-black'
                          "
                          @click="aracSec(arac)"
                        >
                          {{ arac.SoforAdi }}
                          <feather v-if="arac.isWifi" type="wifi"></feather>
                          <feather
                            v-if="arac.isAracTakip"
                            type="navigation"
                          ></feather>
                        </b-link>
                      </h5>
                      <div class="product-box">
                        <div class="product-img">
                          <img class="img-fluid" :src="arac.Resim" alt />
                        </div>
                        <div class="product-details">
                          <b-button
                            class="primary"
                            :disabled="arac.Id === kayit.AracId"
                            @click="aracSec(arac)"
                          >
                            Aracı Seç
                          </b-button>
                        </div>
                      </div>
                    </b-card>
                  </div>
                </div>
              </div>
            </tab-content>
          </form-wizard>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Datepicker from "vuejs-datepicker";
import { tr } from "vuejs-datepicker/dist/locale";
import { BFormTimepicker } from "bootstrap-vue";

export default {
  data() {
    return {
      id: this.$route.params.id,

      pageTitle: "Rezervasyon Düzelt",

      breadLinks: [
        {
          path: "/turRezervasyon/detay/" + this.$route.params.id,
          title: "Rezervasyon Detayı",
          icon: "chevron-left",
        },
      ],

      date_format: "dd.MM.yyyy",
      tr: tr,

      kayit: {
        TurId: "",
        EncTurId: "",
        TurOpsiyonId: "",
        EncTurOpsiyonId: "",
        Tarih: null,
        Saat: "",
        Aciklama: "",
        OtelAdi: "",
        DilId: "",
        RehberId: "",
        AracId: "",
        AracTipiId: "",
        KisiSayisi: 0,
        Misafirler: [],
      },

      activeTur: {},
      activeOpsiyon: {},
      activeOpsiyonDetay: {},

      turlar: [],
      turOpsiyonlari: [],

      kayit_dil_options: [{ value: "0", text: "Seçiniz" }],

      misafirKayit: {
        Id: 0,
        PasaportNo: "",
        Uyruk: "",
        AdSoyad: "",
        Yas: 0,
        Telefon: "",
      },

      rehberler: [],
      araclar: [],
    };
  },
  components: {
    Datepicker,
    BFormTimepicker,
  },
  mounted() {
    this.onDilList();
    this.onDetay();
  },
  computed: {
    kayit_turid_state() {
      if (this.kayit.TurId == "" || this.kayit.TurId == null) return false;
      else return true;
    },
    kayit_turopsiyonid_state() {
      if (this.kayit.TurOpsiyonId == "" || this.kayit.TurOpsiyonId == null)
        return false;
      else return true;
    },
    kayit_tarih_state() {
      if (this.kayit.Tarih == "" || this.kayit.Tarih == null) return false;
      else return true;
    },
    kayit_saat_state() {
      if (this.kayit.Saat == "" || this.kayit.Saat == null) return false;
      else return true;
    },
    kayit_oteladi_state() {
      if (this.kayit.OtelAdi == "" || this.kayit.OtelAdi == null) return false;
      else return true;
    },
    kayit_dilid_state() {
      if (this.kayit.DilId == "" || this.kayit.DilId == null) return false;
      else return true;
    },
    kayit_rehberid_state() {
      if (this.kayit.RehberId == "" || this.kayit.RehberId == null)
        return false;
      else return true;
    },
    kayit_aracid_state() {
      if (this.kayit.AracId == "" || this.kayit.AracId == null) return false;
      else return true;
    },
  },
  methods: {
    changetab(tab) {
      this.showtab = tab;
    },
    onDetay() {
      let vm = this;

      this.$connection.get(
        "/turRezervasyonlari/" + this.id,
        function (response) {
          if (response.Success) {
            vm.kayit.TurId = response.Data.TurId;
            vm.kayit.EncTurId = response.Data.EncTurId;
            vm.kayit.TurOpsiyonId = response.Data.TurOpsiyonId;
            vm.kayit.EncTurOpsiyonId = response.Data.EncTurOpsiyonId;
            vm.kayit.Tarih = response.Data.Tarih;
            vm.kayit.Saat = response.Data.Saat;
            vm.kayit.Aciklama = response.Data.Aciklama;
            vm.kayit.OtelAdi = response.Data.OtelAdi;
            vm.kayit.DilId = response.Data.DilId;
            vm.kayit.EncDilId = response.Data.EncDilId;
            vm.kayit.RehberId = response.Data.RehberId;
            vm.kayit.EncRehberId = response.Data.EncRehberId;
            vm.kayit.AracId = response.Data.AracId;
            vm.kayit.EncAracId = response.Data.EncAracId;
            vm.kayit.KisiSayisi = response.Data.KisiSayisi;
            vm.kayit.Misafirler = response.Data.Misafirler;

            vm.turList();
            vm.turOpsiyonList(vm.kayit.EncTurId);

            vm.onRehberlerList(vm.kayit.EncDilId, vm.kayit.Tarih);
            vm.onAraclarList(vm.kayit.Tarih);
          } else {
            vm.$alert.messageWithTitle("Tur Detay", response.Message, "error");
          }
        }
      );
    },
    turList() {
      let vm = this;
      this.$connection.get("/turlar/forSale/0", function (response) {
        vm.turlar = response;

        vm.activeTur = vm.turlar.filter((item) => {
          return item.Id === vm.kayit.TurId;
        })[0];
      });
    },
    turOpsiyonList(turId) {
      let vm = this;
      this.$connection.get(
        "/turlar/opsiyonlarForSale/" + turId,
        function (response) {
          vm.turOpsiyonlari = response;
        }
      );
    },
    turDegistir(tur) {
      this.activeTur = tur;

      this.kayit.TurOpsiyonId = "";

      this.turOpsiyonList(tur.EncId);
    },
    opsiyonDegistir(opsiyon) {
      let vm = this;
      this.$connection.get(
        "/turlar/opsiyonlarGetForSaleById/" + opsiyon.EncId,
        function (response) {
          if (response.Success) {
            vm.$bvModal.show("opsiyonDetay");
            vm.activeOpsiyon = opsiyon;
            vm.activeOpsiyonDetay = response.Data;
          } else {
            vm.$alert.messageWithTitle("Tur Detay", response.Message, "error");
          }
        }
      );
    },
    onDilList() {
      let vm = this;

      this.$connection.get("/diller/select", function (response) {
        vm.kayit_dil_options = vm.kayit_dil_options.concat(response);
      });
    },
    misafirEkle() {
      if (
        this.misafirKayit.PasaportNo === null ||
        this.misafirKayit.Uyruk === null ||
        this.misafirKayit.AdSoyad === null ||
        this.misafirKayit.Yas <= 0 ||
        this.misafirKayit.Telefon === null
      ) {
        this.$alert.messageWithTitle(
          "Eksik Alan",
          "Misafir bilgilerini eksiksiz doldurunuz.",
          "error"
        );
        return;
      }

      this.kayit.Misafirler.push(this.misafirKayit);
      this.misafirKayit = {
        Id: 0,
        PasaportNo: "",
        Uyruk: "",
        AdSoyad: "",
        Yas: 0,
        Telefon: "",
      };
    },
    misafirKaldir(misafir) {
      this.$listHelper.removeItem(this.kayit.Misafirler, misafir);
    },
    onRehberlerList(dilId, turTarihi) {
      let vm = this;

      this.$connection.get(
        "/rehberler/select/" + dilId + "/" + turTarihi,
        function (response) {
          vm.rehberler = response;
        }
      );
    },
    rehberSec(rehber) {
      this.kayit.RehberId = rehber.Id;
      this.kayit.EncRehberId = rehber.EncId;
    },
    onAraclarList(turTarihi) {
      let vm = this;

      this.$connection.get("/araclar/select/" + turTarihi, function (response) {
        vm.araclar = response;
      });
    },
    aracSec(arac) {
      this.kayit.AracId = arac.Id;
      this.kayit.EncAracId = arac.EncId;
    },
    onComplete() {
      let isValid =
        this.kayit_turid_state &&
        this.kayit_turopsiyonid_state &&
        this.kayit_tarih_state &&
        this.kayit_saat_state &&
        this.kayit_oteladi_state &&
        this.kayit_dilid_state &&
        this.kayit_rehberid_state &&
        this.kayit_aracid_state;

      if (!isValid) {
        this.$alert.messageWithTitle(
          "Eksik Bilgi",
          "Lütfen rezervasyon bilgilerinizi kontrol ediniz.",
          "error"
        );
      } else {
        let vm = this;

        let url = "/turRezervasyonlari/" + this.id;
        let method = "put";
        let messageTitle = "Rezervasyon Düzelt";

        vm.kayit.TurId = vm.kayit.EncTurId;
        vm.kayit.TurOpsiyonId = vm.kayit.EncTurOpsiyonId;
        vm.kayit.RehberId = vm.kayit.EncRehberId;
        vm.kayit.AracId = vm.kayit.EncAracId;
        vm.kayit.DilId = vm.kayit.EncDilId;
        vm.kayit.KisiSayisi = vm.kayit.Misafirler.length;

        vm.$connection.post(
          url,
          vm.kayit,
          method,
          function (response) {
            if (response.Success) {
              vm.$alert.messageWithFunction(
                messageTitle,
                response.Message,
                "success",
                function () {
                  vm.$router.push("/turRezervasyon/detay/" + vm.id);
                }
              );
            }
          },
          function (error) {
            vm.$alert.messageWithTitle(messageTitle, error.Message, "error");
          }
        );
      }
    },
  },
};
</script>
