<template>
  <!-- page-wrapper Start-->
  <div class="page-wrapper">
    <div class="container-fluid">
      <!-- sign up page start-->
      <div class="authentication-main">
        <div class="row">
          <div class="col-sm-12 p-0">
            <div class="auth-innerright">
              <div class="authentication-box rehber-basvuru-box">
                <div class="text-center">
                  <img alt="" src="../assets/images/logo.png" />
                </div>
                <div class="card mt-4 p-4">
                  <h4 class="text-center">REHBER KAYIT</h4>
                  <h6 class="text-center">
                    Rehber başvurunuzu buradan yapabilirsiniz
                  </h6>
                  <div class="alert alert-danger" v-if="rehber != null">
                    <span v-html="rehber.BasvuruGeriBildirim"></span>
                  </div>
                  <b-form @submit="onSubmit" class="theme-form">
                    <div class="form-group">
                      <label class="col-form-label">Ad Soyad</label>
                      <b-form-input
                        type="text"
                        v-model="kayit.AdSoyad"
                        :state="kayit_adsoyad_state"
                        placeholder="Ad Soyad"
                      ></b-form-input>
                      <b-form-invalid-feedback :state="kayit_adsoyad_state"
                        >Adınız ve soyadınızı giriniz</b-form-invalid-feedback
                      >
                    </div>
                    <div class="form-row">
                      <div class="col-md-6">
                        <div class="form-group">
                          <label class="col-form-label">Cep Telefonu</label>
                          <b-form-input
                            type="text"
                            id="kayit_email"
                            v-model="kayit.GSM"
                            v-mask="'5## ### ## ##'"
                            placeholder="Cep Telefonu"
                            :state="kayit_gsm_state"
                          ></b-form-input>
                          <b-form-invalid-feedback :state="kayit_gsm_state"
                            >Cep telefonunu giriniz</b-form-invalid-feedback
                          >
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group">
                          <label class="col-form-label">Telefon</label>
                          <b-form-input
                            type="text"
                            v-model="kayit.Telefon"
                            placeholder="Telefon"
                          ></b-form-input>
                        </div>
                      </div>
                    </div>
                    <div class="form-group">
                      <label class="col-form-label">Email</label>
                      <b-form-input
                        type="text"
                        id="kayit_email"
                        v-model="kayit.Email"
                        placeholder="Email"
                        :state="kayit_email_state"
                      ></b-form-input>
                      <b-form-invalid-feedback :state="kayit_email_state"
                        >Email adresini giriniz</b-form-invalid-feedback
                      >
                    </div>
                    <div class="form-group">
                      <label class="col-form-label">Adres</label>
                      <b-form-textarea
                        rows="3"
                        id="kayit_adres"
                        v-model="kayit.Adres"
                        placeholder="Açık adresiniz"
                        :state="kayit_adres_state"
                      ></b-form-textarea>
                      <b-form-invalid-feedback :state="kayit_adres_state"
                        >Açık adresinizi giriniz</b-form-invalid-feedback
                      >
                    </div>
                    <div class="form-group">
                      <label class="col-form-label">T.C. Kimlik No</label>
                      <b-form-input
                        type="text"
                        id="kayit_tckimlikno"
                        v-model="kayit.TCKimlikNo"
                        placeholder="T.C. Kimlik Numaranız"
                        :state="kayit_tckimlikno_state"
                      ></b-form-input>
                      <b-form-invalid-feedback :state="kayit_tckimlikno_state"
                        >11 haneli T.C. kimlik numaranızı
                        giriniz.</b-form-invalid-feedback
                      >
                    </div>
                    <div class="form-row">
                      <div class="col-md-6">
                        <div class="form-group">
                          <label class="col-form-label">Ruhsatname No</label>
                          <b-form-input
                            type="text"
                            id="kayit_ruhsatnameno"
                            v-model="kayit.RuhsatName"
                            placeholder="Ruhsatname No"
                            :state="kayit_ruhsatname_state"
                          ></b-form-input>
                          <b-form-invalid-feedback
                            :state="kayit_ruhsatname_state"
                            >Ruhsatname numaranızı
                            giriniz.</b-form-invalid-feedback
                          >
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group">
                          <label class="col-form-label">Çalışma Kartı No</label>
                          <b-form-input
                            type="text"
                            id="kayit_calismakartno"
                            v-model="kayit.CalismaKartiNo"
                            placeholder="Çalışma Kart No"
                            :state="kayit_calismakartino_state"
                          ></b-form-input>
                          <b-form-invalid-feedback
                            :state="kayit_calismakartino_state"
                            >Çalışma kart numaranızı
                            giriniz.</b-form-invalid-feedback
                          >
                        </div>
                      </div>
                    </div>
                    <div class="form-group">
                      <label class="col-form-label">Kokart Resminiz</label>
                      <KokartResim @onChange="onKokartChange" />
                    </div>
                    <div class="form-group">
                      <label class="col-form-label">Özgeçmiş</label>
                      <ckeditor
                        :editor="editor"
                        v-model="kayit.Ozgecmis"
                        :state="kayit_ozgecmis_state"
                      ></ckeditor>
                      <b-form-invalid-feedback :state="kayit_ozgecmis_state"
                        >En az 100 karakterden oluşan özgeçmişinizi
                        giriniz.</b-form-invalid-feedback
                      >
                    </div>
                    <div class="form-group">
                      <label class="col-form-label">Konuştuğunuz Diller</label>
                      <div class="checkbox-animated">
                        <label
                          v-for="dil in diller"
                          :key="dil.value"
                          class="col-xl-4 col-md-4 col-sm-6"
                          :for="'dilId' + dil.value"
                        >
                          <input
                            class="checkbox_animated"
                            v-model="selectedDiller"
                            :value="dil.value"
                            :id="'dilId' + dil.value"
                            type="checkbox"
                          />
                          {{ dil.text }}
                        </label>
                      </div>
                      <b-form-invalid-feedback :state="kayit_dil_state"
                        >En az bir dil seçiniz</b-form-invalid-feedback
                      >
                    </div>
                    <div class="form-group" v-if="rehber == null">
                      <label class="col-form-label">Kullanıcı Adı</label>
                      <b-form-input
                        type="text"
                        id="kayit_kullaniciadi"
                        v-model="kayit.KullaniciAdi"
                        placeholder="Kullanıcı Adı"
                        :state="kayit_kullaniciadi_state"
                      ></b-form-input>
                      <b-form-invalid-feedback :state="kayit_kullaniciadi_state"
                        >Ana mekan kullanıcısının kullanıcı adını
                        giriniz</b-form-invalid-feedback
                      >
                    </div>
                    <div class="form-group" v-if="rehber == null">
                      <label class="col-form-label">Şifre</label>
                      <b-form-input
                        type="text"
                        id="kayit_sifre"
                        v-model="kayit.Sifre"
                        placeholder="Şifre"
                        :state="kayit_sifre_state"
                      ></b-form-input>
                      <b-form-invalid-feedback :state="kayit_sifre_state"
                        >8 ile 20 karakter arasında rakam, küçük ve büyük harf
                        bulunan bir şifre giriniz</b-form-invalid-feedback
                      >
                    </div>
                    <div class="form-row">
                      <div class="col-sm-12 text-center mb-3">
                        <b-button variant="primary" type="submit">{{
                          rehber == null ? "Kayıt Ol" : "Güncelle"
                        }}</b-button>
                      </div>
                    </div>
                    <div class="form-row">
                      <div class="col-sm-12 text-center">
                        <router-link
                          :to="'/giris'"
                          class="btn-link text-capitalize"
                          >Giriş Yap</router-link
                        >
                      </div>
                    </div>
                  </b-form>

                  <b-modal
                    id="sozlesme"
                    size="lg"
                    @ok="sozlesmeSubmit"
                    centered
                    title="Hizmet Sözleşmesi"
                    ok-title="Okudum Onaylıyorum"
                    cancel-title="Kapat"
                    class="theme-modal"
                  >
                    <div class="row">
                      <div class="col-md-12">
                        <span v-html="sozlesmeMetni"></span>
                      </div>
                    </div>
                  </b-modal>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- sign up page ends-->
    </div>
  </div>
  <!-- page-wrapper Ends-->
</template>

<script>
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import KokartResim from "@/components/c_kokart_resim.vue";

export default {
  data() {
    return {
      id: this.$route.query.id,

      rehber: null,

      kayit: {
        Guncelleme: false,
        AdSoyad: "",
        Email: "",
        Telefon: "",
        GSM: "",
        Ozgecmis: "",
        ResimFl: null,
        Dil: "",
        Adres: "",
        TCKimlikNo: "",
        CalismaKartiNo: "",
        RuhsatName: "",

        KullaniciAdi: "",
        Sifre: "",
      },

      canvas: null,

      selectedDiller: [],

      diller: [],

      kayit_sehir_options: [{ value: "0", text: "Seçiniz" }],

      sozlesmeMetni: "",

      editor: ClassicEditor,
    };
  },
  mounted() {
    this.onDilList();
    this.onSehirList();
    this.kayitGetir();
  },
  components: {
    KokartResim,
  },
  computed: {
    kayit_adsoyad_state() {
      if (this.kayit.AdSoyad == null || this.kayit.AdSoyad == "") return false;
      else return true;
    },
    kayit_resim_state() {
      if (this.canvas == null) return false;
      else return true;
    },
    kayit_gsm_state() {
      if (!this.$validationHelper.validGsm(this.kayit.GSM)) return false;
      else return true;
    },
    kayit_email_state() {
      if (!this.$validationHelper.validEmail(this.kayit.Email)) return false;
      else return true;
    },
    kayit_dil_state() {
      if (this.selectedDiller.length === 0) return false;
      else return true;
    },
    kayit_adres_state() {
      if (this.kayit.Adres == null || this.kayit.Adres == "") return false;
      else return true;
    },
    kayit_tckimlikno_state() {
      if (
        this.kayit.TCKimlikNo == null ||
        this.kayit.TCKimlikNo == "" ||
        this.kayit.TCKimlikNo.length !== 11
      )
        return false;
      else return true;
    },
    kayit_ruhsatname_state() {
      if (this.kayit.RuhsatName == null || this.kayit.RuhsatName == "")
        return false;
      else return true;
    },
    kayit_calismakartino_state() {
      if (this.kayit.CalismaKartiNo == null || this.kayit.CalismaKartiNo == "")
        return false;
      else return true;
    },
    kayit_kullaniciadi_state() {
      if (
        this.id === undefined &&
        (this.kayit.KullaniciAdi === null || this.kayit.KullaniciAdi === "")
      )
        return false;
      else return true;
    },
    kayit_sifre_state() {
      if (
        this.id === undefined &&
        !this.$validationHelper.validPassword(this.kayit.Sifre)
      )
        return false;
      else return true;
    },
    kayit_ozgecmis_state() {
      if (
        this.kayit.Ozgecmis == null ||
        this.kayit.Ozgecmis == "" ||
        this.kayit.Ozgecmis.length < 100
      )
        return false;
      else return true;
    },
  },
  methods: {
    kayitGetir() {
      if (this.id !== undefined) {
        //Kayıt var formu doldur
        let vm = this;

        this.$connection.get("/rehberler/" + this.id, function (response) {
          if (response.Success) {
            vm.rehber = response.Data;

            vm.kayit = {
              AdSoyad: response.Data.AdSoyad,
              Email: response.Data.Email,
              Telefon: response.Data.Telefon,
              GSM: response.Data.GSM,
              Adres: response.Data.Adres,
              TCKimlikNo: response.Data.TCKimlikNo,
              CalismaKartiNo: response.Data.CalismaKartiNo,
              RuhsatName: response.Data.RuhsatName,
              Ozgecmis: response.Data.Ozgecmis,
              ResimFl: null,
              Dil: "",
              Adres: response.Data.Adres,
            };

            for (let index = 0; index < response.Data.Dilleri.length; index++) {
              const element = response.Data.Dilleri[index];
              vm.selectedDiller.push(element.DilId);
            }
          } else {
            vm.$alert.messageWithTitle("Rehber", response.Message, "error");
          }
        });
      }
    },
    onDilList() {
      let vm = this;

      this.$connection.get("/diller/select", function (response) {
        vm.diller = response;
      });
    },
    onSehirList() {
      let vm = this;

      this.$connection.get("/sehirler/select", function (response) {
        vm.kayit_sehir_options = vm.kayit_sehir_options.concat(response);
      });
    },
    gsmUpdate(payload) {
      this.is_gsm_valid = payload.isValid;
    },
    onKokartChange(canvas) {
      this.canvas = canvas;
    },
    onSubmit(evt) {
      evt.preventDefault();

      let isValid =
        this.kayit_adsoyad_state &&
        this.kayit_resim_state &&
        this.kayit_gsm_state &&
        this.kayit_email_state &&
        this.kayit_ozgecmis_state &&
        this.kayit_adres_state &&
        this.kayit_tckimlikno_state &&
        this.kayit_ruhsatname_state &&
        this.kayit_calismakartino_state &&
        this.kayit_dil_state &&
        this.kayit_kullaniciadi_state &&
        this.kayit_sifre_state;

      if (isValid) {
        let vm = this;

        this.$connection.get(
          "/parametreler/RehberSozlesmesi",
          function (response) {
            vm.sozlesmeMetni = response;
            vm.$bvModal.show("sozlesme");
          }
        );
      }
    },
    sozlesmeSubmit(event) {
      event.preventDefault();

      this.onKaydet();
    },
    onKaydet() {
      let isValid =
        this.kayit_adsoyad_state &&
        this.kayit_resim_state &&
        this.kayit_gsm_state &&
        this.kayit_email_state &&
        this.kayit_dil_state &&
        this.kayit_kullaniciadi_state &&
        this.kayit_sifre_state;

      if (isValid) {
        let vm = this;

        if (this.canvas) {
          this.canvas.toBlob((blob) => {
            let url = "/auth/rehberBasvur";
            let method = "post";
            let messageTitle = "Rehber Başvurusu";
            if (vm.rehber != null) {
              url = "/auth/RehberBasvuruGuncelle/" + vm.rehber.Id;
              vm.kayit.Guncelleme = true;
            }

            vm.kayit.Dil = "";
            for (let i = 0; i < vm.selectedDiller.length; i++) {
              const element = vm.selectedDiller[i];
              vm.kayit.Dil += element + ",";
            }
            if (vm.kayit.Dil !== "") {
              vm.kayit.Dil = vm.kayit.Dil.substring(0, vm.kayit.Dil.length - 1);
            }
            vm.kayit.ResimFl = blob;

            vm.$connection.postData(
              url,
              vm.kayit,
              method,
              function (response) {
                if (response.Success) {
                  vm.$alert.messageWithFunction(
                    messageTitle,
                    "Rehber başvurunuz alındı onaylandığında bilgilendireceğiz.",
                    "success",
                    function () {
                      vm.$router.push("/giris");
                    }
                  );
                }
              },
              function (error) {
                vm.$alert.messageWithTitle(
                  messageTitle,
                  error.Message,
                  "error"
                );
              }
            );
          }, "image/jpeg");
        }
      }
    },
  },
};
</script>
<style>
.rehber-basvuru-box {
  width: 50% !important;
}

@media only screen and (max-width: 991px) {
  .rehber-basvuru-box {
    width: 100% !important;
  }
}
</style>
