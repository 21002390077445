<template>
  <div class="misafirler">
    <b-badge
      variant="light"
      v-for="(misafir, index) in misafirList"
      :key="index"
      v-b-tooltip.hover
      :title="misafir"
      >{{ misafir }}</b-badge
    >
  </div>
</template>
<script>
export default {
  props: {
    misafirler: "",
  },
  data() {
    return {
      mutateMisafirler: this.misafirler,
      misafirList: [],
    };
  },
  mounted() {
    if (this.mutateMisafirler !== null) {
      this.misafirList = this.mutateMisafirler.split(",");
    }
  },
  methods: {
    kisalt(isim) {
      return isim.split(" ")[0];
    },
  },
};
</script>
