<template>
  <div>
    <Breadcrumbs main="Dashboard" title="Kontrol Paneli" />

    <div class="container-fluid">
      <div class="row" v-if="profilDurumu !== ''">
        <div class="col-xl-12">
          <b-alert show variant="danger">
            <h4 class="alert-heading">Profil Durumunuz</h4>
            <p>
              Profilinizde bazı eksiklikler bulduk. Bunları doldurduğunuzda
              rezervasyon almanız ve işlemleriniz daha kolaylaşacaktır.
            </p>
            <hr />
            <p v-html="profilDurumu" class="mb-3"></p>
            <p>
              <router-link to="/rehber/profil" class="btn btn-dark"
                >Güncelle</router-link
              >
            </p>
          </b-alert>
        </div>
      </div>
      <div class="row">
        <div class="col-xl-12">
          <TurRezervasyonList />
        </div>
      </div>
    </div>

    <DuyuruGosterimModal />
  </div>
</template>
<script>
import TurRezervasyonList from "@/components/c_tur_rezervasyon_dashboard_rehber.vue";
import DuyuruGosterimModal from "@/components/c_duyuru_gosterim";

export default {
  data() {
    return {
      profilDurumu: "",
    };
  },
  components: {
    TurRezervasyonList,
    DuyuruGosterimModal,
  },
  mounted() {
    let user = this.$user.getUser();
    if (user.Kullanici.RedirectUrl !== "/dashboard/rehber")
      this.$router.push(user.Kullanici.RedirectUrl);

    this.profilDurumLoad();
  },
  methods: {
    profilDurumLoad() {
      let vm = this;
      this.$connection.get("/rehberler/profilDurum", function (response) {
        vm.profilDurumu = response;
      });
    },
  },
};
</script>
