<template>
  <div>
    <Breadcrumbs main="Araç Detay" :title="pageTitle" />

    <div class="container-fluid tur-rezervasyon-detay">
      <div class="row">
        <div class="col-md-12">
          <ul
            class="nav nav-tabs border-tab tabs-scoial"
            id="top-tab"
            role="tablist"
          >
            <li class="nav-item" v-on:click="changetab('genel')">
              <a
                class="nav-link"
                :class="{ 'show active': showtab == 'genel' }"
                id="top-genel"
                data-toggle="tab"
                role="tab"
                aria-controls="genel"
                aria-selected="true"
                >Genel
                <b-badge variant="info"
                  >{{ arac.SoforAdi }} - {{ arac.Plaka }}</b-badge
                ></a
              >
            </li>
            <li class="nav-item" v-on:click="changetab('resim')">
              <a
                class="nav-link"
                :class="{ 'show active': showtab == 'resim' }"
                id="top-resim"
                data-toggle="tab"
                role="tab"
                aria-controls="resim"
                aria-selected="false"
                >Araç Resimleri</a
              >
            </li>
            <li class="nav-item" v-on:click="changetab('ucret')">
              <a
                class="nav-link"
                :class="{ 'show active': showtab == 'ucret' }"
                id="top-ucret"
                data-toggle="tab"
                role="tab"
                aria-controls="ucret"
                aria-selected="false"
                >Servis Ücretleri</a
              >
            </li>
            <li class="nav-item" v-on:click="changetab('takvim')">
              <a
                class="nav-link"
                :class="{ 'show active': showtab == 'takvim' }"
                id="top-takvim"
                data-toggle="tab"
                role="tab"
                aria-controls="takvim"
                aria-selected="false"
                >Müsaitlik Takvimi</a
              >
            </li>
            <li class="nav-item" v-on:click="changetab('folyo')">
              <a
                class="nav-link"
                :class="{ 'show active': showtab == 'folyo' }"
                id="top-folyo"
                data-toggle="tab"
                role="tab"
                aria-controls="folyo"
                aria-selected="false"
                >Folyo</a
              >
            </li>
          </ul>
        </div>
      </div>

      <div class="tab-content" id="top-tabContent">
        <div
          class="tab-pane fade"
          :class="{ 'show active': showtab == 'genel' }"
          id="genel"
          role="tabpanel"
          aria-labelledby="genel"
        >
          <div class="row">
            <div class="col-md-6">
              <b-card
                header-tag="div"
                header-bg-variant="primary"
                class="card-absolute"
              >
                <h5 slot="header">Bilgiler</h5>
                <b-card-body>
                  <table class="product-page-width">
                    <tbody>
                      <tr>
                        <th>Araç Firması</th>
                        <td>{{ arac.AracFirmaAdi }}</td>
                      </tr>
                      <tr>
                        <th>Şoför Adı</th>
                        <td>{{ arac.SoforAdi }}</td>
                      </tr>
                      <tr>
                        <th>Plaka</th>
                        <td>{{ arac.Plaka }}</td>
                      </tr>
                      <tr>
                        <th>T.C. Kimlik No</th>
                        <td>{{ arac.TcKimlikNo }}</td>
                      </tr>
                      <tr>
                        <th>Araç Tipi</th>
                        <td>{{ arac.AracTipiTanim }}</td>
                      </tr>
                      <tr>
                        <th>Marka</th>
                        <td>{{ arac.Marka }}</td>
                      </tr>
                      <tr>
                        <th>Model</th>
                        <td>{{ arac.Model }}</td>
                      </tr>
                      <tr>
                        <th>Yetki Belgesi Türü</th>
                        <td>{{ arac.YetkiBelgesiTuru }}</td>
                      </tr>
                      <tr>
                        <th>Yetki Belge No</th>
                        <td>{{ arac.YetkiBelgesiNo }}</td>
                      </tr>
                      <tr>
                        <th>Wifi</th>
                        <td>
                          <c-aktif-cell :isAktif="arac.isWifi"></c-aktif-cell>
                        </td>
                      </tr>
                      <tr>
                        <th>Araç Takip</th>
                        <td>
                          <c-aktif-cell
                            :isAktif="arac.isAracTakip"
                          ></c-aktif-cell>
                        </td>
                      </tr>
                      <tr>
                        <th>Takip Cihazı</th>
                        <td>
                          {{ arac.TakipCihazStr }}
                        </td>
                      </tr>
                      <tr>
                        <th>Entegrasyon</th>
                        <td>
                          {{ arac.EntegrasyonAyarlariJson }}
                        </td>
                      </tr>
                      <tr>
                        <th>Durum</th>
                        <td>{{ arac.DurumStr }}</td>
                      </tr>
                      <tr>
                        <th>Başvuru Düzenleme İsteği</th>
                        <td v-html="arac.BasvuruGeriBildirim"></td>
                      </tr>
                      <tr>
                        <th>Toplam Alacak</th>
                        <td>
                          <c-money-cell
                            :fiyat="arac.ToplamAlacak"
                            simge="₺"
                          ></c-money-cell>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </b-card-body>
              </b-card>

              <b-card
                header-tag="div"
                header-bg-variant="info"
                class="card-absolute"
              >
                <h5 slot="header">Kayıt Bilgileri</h5>
                <b-card-body>
                  <table class="product-page-width">
                    <tbody>
                      <tr>
                        <th>Kayıt Tarihi</th>
                        <td>
                          <c-tarih-cell
                            :kayitTarihi="arac.KayitTarihi"
                            :rowKey="arac.Id"
                          ></c-tarih-cell>
                        </td>
                      </tr>
                      <tr>
                        <th>Güncelleme Tarihi</th>
                        <td>
                          <c-tarih-cell
                            :kayitTarihi="arac.GuncelTarih"
                            :rowKey="arac.Id"
                          ></c-tarih-cell>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </b-card-body>
              </b-card>
            </div>
            <div class="col-md-6">
              <div class="row">
                <div class="col-md-12">
                  <b-list-group class="mb-3">
                    <b-list-group-item
                      tag="a"
                      :to="`/aracFirma/aracKayit/${arac.AracFirmaId}/${arac.Id}`"
                      router-component-name="router-link"
                    >
                      <feather type="edit-3"></feather> Güncelle
                    </b-list-group-item>
                    <b-list-group-item
                      v-b-modal.sifreForm
                      router-component-name="router-link"
                    >
                      <feather type="lock"></feather> Şifre Güncelle
                    </b-list-group-item>
                  </b-list-group>
                </div>
              </div>

              <div class="row">
                <div class="col-md-12 mb-3 text-center">
                  <b-btn-group>
                    <b-button
                      v-b-modal.duzenlemeIsteForm
                      variant="info"
                      v-if="arac.Durum == 0 || arac.Durum == 40"
                      >Düzenleme İste</b-button
                    >
                    <b-button
                      @click="onayla()"
                      variant="success"
                      v-if="arac.Durum != 30"
                      >Onayla</b-button
                    >
                    <b-button
                      @click="iptalEt()"
                      variant="warning"
                      v-if="arac.Durum != 100"
                      >İptal Et</b-button
                    >
                    <b-button @click="sil()" variant="danger">Sil</b-button>
                  </b-btn-group>
                </div>
              </div>

              <b-card
                header-tag="div"
                header-bg-variant="warning"
                class="card-absolute"
              >
                <h5 slot="header">İletişim Bilgileri</h5>
                <b-card-body>
                  <table class="product-page-width">
                    <tbody>
                      <tr>
                        <th>Cep Telefonu</th>
                        <td>{{ arac.GSM }}</td>
                      </tr>
                      <tr>
                        <th>Email</th>
                        <td>{{ arac.Email }}</td>
                      </tr>
                    </tbody>
                  </table>
                </b-card-body>
              </b-card>
            </div>
          </div>

          <b-modal
            id="sifreForm"
            centered
            title="Şifre Güncelle"
            @ok="sifreKaydetSubmit"
            ok-title="Güncelle"
            cancel-title="Kapat"
            class="theme-modal"
          >
            <div class="form-row">
              <div class="col-md-12 mb-3">
                <label for="kayit_kullaniciadi">Kullanıcı Adı</label>
                <b-form-input
                  id="kayit_kullaniciadi"
                  v-model="kayitSifre.KullaniciAdi"
                  :state="kayitsifre_kullaniciadi_state"
                ></b-form-input>
                <b-form-invalid-feedback :state="kayitsifre_kullaniciadi_state"
                  >Kullanıcı adınızı giriniz.</b-form-invalid-feedback
                >
              </div>
            </div>
            <div class="form-row">
              <div class="col-md-12 mb-3">
                <label for="kayit_sifre">Şifre</label>
                <b-form-input
                  id="kayit_sifre"
                  v-model="kayitSifre.SifreYeni"
                  :state="kayitsifre_sifre_state"
                  placeholder="Şifreniz boş ise güncellenmez."
                ></b-form-input>
                <b-form-invalid-feedback :state="kayitsifre_sifre_state"
                  >En az 8 ile 20 karakter arasında rakam, küçük ve büyük harf
                  bulunan bir şifre giriniz</b-form-invalid-feedback
                >
              </div>
            </div>
          </b-modal>

          <b-modal
            id="duzenlemeIsteForm"
            centered
            title="Düzenleme İsteği"
            @ok="duzenlemeIsteSubmit"
            ok-title="Gönder"
            cancel-title="Kapat"
            class="theme-modal"
          >
            <div class="form-row">
              <div class="col-md-12 mb-3">
                <div class="checkbox-animated">
                  <label
                    v-for="(item, index) in duzenleme_istek_geribildirimler"
                    :key="index"
                    class="d-block"
                    :for="'geribildirim' + index"
                  >
                    <input
                      class="checkbox_animated"
                      v-model="duzenlemeIstekForm.GeriBildirimler"
                      :value="item.value"
                      :id="'geribildirim' + index"
                      type="checkbox"
                    />
                    {{ item.text }}
                  </label>
                </div>
              </div>
            </div>
          </b-modal>
        </div>

        <div
          class="tab-pane fade"
          :class="{ 'show active': showtab == 'resim' }"
          id="resim"
          role="tabpanel"
          aria-labelledby="resim"
        >
          <div class="row">
            <div class="col-md-12">
              <AracResim :aracId="id" :noEdit="resimNoEdit" />
            </div>
          </div>
        </div>

        <div
          class="tab-pane fade"
          :class="{ 'show active': showtab == 'ucret' }"
          id="ucret"
          role="tabpanel"
          aria-labelledby="ucret"
        >
          <div class="row">
            <div class="col-md-12">
              <AracUcret :arac="arac" v-if="arac.Id != undefined" />
            </div>
          </div>
        </div>

        <div
          class="tab-pane fade"
          :class="{ 'show active': showtab == 'takvim' }"
          id="takvim"
          role="tabpanel"
          aria-labelledby="takvim"
        >
          <div class="row">
            <div class="col-md-12">
              <AracTakvim :aracId="id" />
            </div>
          </div>
        </div>

        <div
          class="tab-pane fade"
          :class="{ 'show active': showtab == 'folyo' }"
          id="folyo"
          role="tabpanel"
          aria-labelledby="folyo"
        >
          <div class="row">
            <div class="col-md-12">
              <b-card
                header-tag="div"
                header-bg-variant="primary"
                class="card-absolute"
              >
                <h5 slot="header">Folyo</h5>
                <b-card-body class="p-1">
                  <AracFolyo
                    :arac="arac"
                    v-if="isFolyoLoad"
                    :noEdit="false"
                    @onKayitEklendi="folyoKayitEklendi()"
                  ></AracFolyo>
                </b-card-body>
              </b-card>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import AracFolyo from "@/components/c_arac_folyo";
import AracResim from "@/components/c_arac_resim";
import AracTakvim from "@/components/c_arac_takvim";
import AracUcret from "@/components/c_arac_ucret";

export default {
  data() {
    return {
      id: this.$route.params.id,

      pageTitle: "Araç Detayı",

      showtab: "genel",

      resimNoEdit: true,

      arac: {},

      isFolyoLoad: false,

      kayitBilgi: {
        AdSoyad: "",
        Email: "",
        Telefon: "",
        GSM: "",
      },

      is_gsm_valid: false,

      kayitSifre: {
        KullaniciAdi: "",
        SifreYeni: "",
      },

      duzenlemeIstekForm: {
        GeriBildirimler: [],
        BasvuruGeriBildirim: "",
      },

      duzenleme_istek_geribildirimler: [
        {
          text: "Lütfen daha güzel araç resmi çekiniz.",
          value: "Lütfen daha güzel araç resmi çekiniz.",
        },
      ],

      image: {
        src: null,
        type: null,
      },

      selectedDiller: [],

      diller: [],

      isDilLoad: false,
    };
  },
  components: {
    AracFolyo,
    AracResim,
    AracTakvim,
    AracUcret,
  },
  computed: {
    kayitbilgi_adsoyad_state() {
      if (this.kayitBilgi.AdSoyad == null || this.kayitBilgi.AdSoyad == "")
        return false;
      else return true;
    },
    kayitbilgi_email_state() {
      if (!this.$validationHelper.validEmail(this.kayitBilgi.Email))
        return false;
      else return true;
    },
    kayit_gsm_state() {
      return this.is_gsm_valid;
    },
    kayitsifre_kullaniciadi_state() {
      if (
        this.kayitSifre.KullaniciAdi == null ||
        this.kayitSifre.KullaniciAdi == ""
      )
        return false;
      else return true;
    },
    kayitsifre_sifre_state() {
      if (
        this.kayitSifre.SifreYeni != null &&
        this.kayitSifre.SifreYeni != "" &&
        !this.$validationHelper.validPassword(this.kayitSifre.SifreYeni)
      )
        return false;
      else return true;
    },
    kayitozgecmis_ozgecmis_state() {
      if (
        this.kayitOzgecmis.Ozgecmis == null ||
        this.kayitOzgecmis.Ozgecmis == "" ||
        this.kayitOzgecmis.Ozgecmis.length < 50
      )
        return false;
      else return true;
    },
    kayitdil_dil_state() {
      if (this.selectedDiller.length === 0) return false;
      else return true;
    },
  },
  mounted() {
    if (this.$user.isYonetim()) {
      this.resimNoEdit = false;
    }

    this.onDetay();
  },
  methods: {
    changetab(tab) {
      this.showtab = tab;

      switch (tab) {
        case "folyo":
          if (this.isFolyoLoad === false) this.folyoList();
          break;

        case "resim":
          if (this.isDilLoad === false) this.isDilLoad = true;
          break;

        default:
          break;
      }
    },
    onDetay() {
      let vm = this;

      this.$connection.get("/araclar/" + this.id, function (response) {
        if (response.Success) {
          vm.arac = response.Data;

          vm.kayitBilgi = {
            AdSoyad: response.Data.AdSoyad,
            Email: response.Data.Email,
            Telefon: response.Data.Telefon,
            GSM: response.Data.GSM,
          };

          vm.kayitSifre = {
            KullaniciAdi: response.Data.Kullanici.KullaniciAdi,
            SifreYeni: "",
          };

          vm.is_gsm_valid = true;
        } else {
          vm.$alert.messageWithTitle("Araç", response.Message, "error");
        }
      });
    },
    folyoList() {
      this.isFolyoLoad = true;
    },
    folyoKayitEklendi(){
      this.onDetay();
    },
    sifreKaydetSubmit(target) {
      target.preventDefault();

      let isValid =
        this.kayitsifre_kullaniciadi_state && this.kayitsifre_sifre_state;

      if (isValid) {
        let vm = this;

        vm.$connection.post(
          "/kullanicilar/forArac/" + this.arac.Id,
          vm.kayitSifre,
          "put",
          function (response) {
            if (response.Success) {
              vm.$alert.messageWithFunction(
                "Bilgi Güncelle",
                response.Message,
                "success",
                function () {
                  vm.$bvModal.hide("sifreForm");
                }
              );
            }
          },
          function (error) {
            vm.$alert.messageWithTitle(
              "Bilgi Güncelle",
              error.Message,
              "error"
            );
          }
        );
      }
    },
    sil() {
      let vm = this;
      this.$alert.confirm(
        "Sil",
        "Araç kaydını silmek istediğinize emin misiniz?",
        "warning",
        function () {
          vm.$connection.delete("/araclar/" + vm.arac.Id, function (response) {
            vm.$alert.messageWithFunction(
              "Silindi",
              "Araç kaydı silindi.",
              "error",
              function () {
                vm.$router.push("/aracFirma/aracList/" + vm.arac.AracFirmaId);
              }
            );
          });
        }
      );
    },
    onayla() {
      let vm = this;
      this.$alert.confirm(
        "Onayla",
        "Aracı onaylamak istediğinize emin misiniz?",
        "warning",
        function () {
          vm.$connection.post(
            "/araclar/durumDegistir/" + vm.arac.Id + "/30",
            {},
            "put",
            function (response) {
              if (response.Success) {
                vm.$alert.messageWithFunction(
                  "Onaylandı",
                  "Araç onaylandı.",
                  "success",
                  function () {
                    vm.onDetay();
                  }
                );
              }
            }
          );
        }
      );
    },
    iptalEt() {
      let vm = this;
      this.$alert.confirm(
        "İptal Et",
        "Aracı aynı bilgilerle bir daha üye olamayacak. İptal etmek istedğinize emin misiniz?",
        "warning",
        function () {
          vm.$connection.post(
            "/araclar/durumDegistir/" + vm.arac.Id + "/100",
            {},
            "put",
            function (response) {
              if (response.Success) {
                vm.$alert.messageWithFunction(
                  "İptal Edildi",
                  "Araç iptal edildi.",
                  "success",
                  function () {
                    vm.onDetay();
                  }
                );
              }
            }
          );
        }
      );
    },
    duzenlemeIsteSubmit(target) {
      target.preventDefault();

      let isValid = this.duzenlemeIstekForm.GeriBildirimler.length > 0;

      if (isValid) {
        let vm = this;

        vm.duzenlemeIstekForm.BasvuruGeriBildirim = "";
        for (
          let index = 0;
          index < vm.duzenlemeIstekForm.GeriBildirimler.length;
          index++
        ) {
          const element = vm.duzenlemeIstekForm.GeriBildirimler[index];
          vm.duzenlemeIstekForm.BasvuruGeriBildirim += element + "<br />";
        }

        vm.$connection.post(
          "/araclar/duzenlemeIste/" + this.arac.Id,
          vm.duzenlemeIstekForm,
          "put",
          function (response) {
            if (response.Success) {
              vm.$alert.messageWithFunction(
                "Düzenleme İste",
                response.Message,
                "success",
                function () {
                  vm.$bvModal.hide("duzenlemeIsteForm");
                  vm.onDetay();
                }
              );
            }
          },
          function (error) {
            vm.$alert.messageWithTitle(
              "Düzenleme İste",
              error.Message,
              "error"
            );
          }
        );
      } else {
        vm.$alert.messageWithTitle(
          "Düzenleme İste",
          "En az bir geri bildirim seçeneği seçiniz.",
          "error"
        );
      }
    },
  },
};
</script>
