<template>
  <div>
    <Breadcrumbs main="Rehberler" :title="pageTitle" :links="breadLinks" />

    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <h5>Rehber Kaydı</h5>
              <span v-if="!isRehber"
                >Yeni rehber veya rehber kaydında değişiklikler için aşağıdaki
                formu kullanabilirsiniz</span
              >
            </div>
            <div class="card-body">
              <b-form @submit="onSubmit">
                <div class="form-row">
                  <div class="col-md-12 mb-3">
                    <label for="kayit_adsoyad">Ad Soyad</label>
                    <b-form-input
                      type="text"
                      id="kayit_adsoyad"
                      v-model="kayit.AdSoyad"
                      :state="kayit_adsoyad_state"
                      placeholder="Ad Soyad"
                    ></b-form-input>
                    <b-form-invalid-feedback :state="kayit_adsoyad_state"
                      >Rehber adını ve soyadını giriniz</b-form-invalid-feedback
                    >
                  </div>
                </div>
                <div class="form-row">
                  <div class="col-md-12 mb-3">
                    <label for="kayit_ozgecmis">Özgeçmiş</label>
                    <ckeditor
                      :editor="editor"
                      v-model="kayit.Ozgecmis"
                    ></ckeditor>
                  </div>
                </div>
                <div class="form-row">
                  <div class="col-md-6 mb-3">
                    <label for="kayit_gsm">Cep Telefonu</label>
                    <b-form-input
                      type="text"
                      id="kayit_gsm"
                      v-model="kayit.GSM"
                      v-mask="'5## ### ## ##'"
                      placeholder="Cep Telefonu"
                      :state="kayit_gsm_state"
                    ></b-form-input>
                    <b-form-invalid-feedback :state="kayit_gsm_state"
                      >Cep telefonunu giriniz</b-form-invalid-feedback
                    >
                  </div>
                  <div class="col-md-6 mb-3">
                    <label for="kayit_email">Email</label>
                    <b-form-input
                      type="text"
                      id="kayit_email"
                      v-model="kayit.Email"
                      placeholder="Email"
                      :state="kayit_email_state"
                    ></b-form-input>
                    <b-form-invalid-feedback :state="kayit_email_state"
                      >Email adresini giriniz</b-form-invalid-feedback
                    >
                  </div>
                </div>
                <div class="form-row">
                  <div class="col-md-6 mb-3">
                    <label for="kayit_telefon">2. Cep Telefonu</label>
                    <b-form-input
                      type="text"
                      id="kayit_telefon"
                      v-model="kayit.Telefon"
                      placeholder="2. Cep Telefonu"
                    ></b-form-input>
                  </div>
                  <div class="col-md-6 mb-3" v-if="isRehber === false">
                    <label for="kayit_durum">Durum</label>
                    <b-form-select
                      id="kayit_durum"
                      v-model="kayit.Durum"
                      :options="kayit_durum_options"
                    ></b-form-select>
                  </div>
                </div>
                <div class="form-row">
                  <div class="col-md-12 mb-3">
                    <label for="kayit_adres">Adres</label>
                    <b-form-textarea
                      rows="3"
                      id="kayit_adres"
                      v-model="kayit.Adres"
                      placeholder="Açık adresiniz"
                      :state="kayit_adres_state"
                    ></b-form-textarea>
                    <b-form-invalid-feedback :state="kayit_adres_state"
                      >Açık adresinizi giriniz</b-form-invalid-feedback
                    >
                  </div>
                </div>
                <div class="form-row">
                  <div class="col-md-12 mb-3">
                    <label for="kayit_tckimlikno">T.C. Kimlik No</label>
                    <b-form-input
                      type="text"
                      id="kayit_tckimlikno"
                      v-model="kayit.TCKimlikNo"
                      placeholder="T.C. Kimlik Numaranız"
                      :state="kayit_tckimlikno_state"
                    ></b-form-input>
                    <b-form-invalid-feedback :state="kayit_tckimlikno_state"
                      >11 haneli T.C. kimlik numaranızı
                      giriniz.</b-form-invalid-feedback
                    >
                  </div>
                </div>
                <div class="form-row">
                  <div class="col-md-6 mb-3">
                    <label for="kayit_ruhsatnameno">Ruhsatname No</label>
                    <b-form-input
                      type="text"
                      id="kayit_ruhsatnameno"
                      v-model="kayit.RuhsatName"
                      placeholder="Ruhsatname No"
                      :state="kayit_ruhsatname_state"
                    ></b-form-input>
                    <b-form-invalid-feedback :state="kayit_ruhsatname_state"
                      >Ruhsatname numaranızı giriniz.</b-form-invalid-feedback
                    >
                  </div>
                  <div class="col-md-6 mb-3">
                    <label for="kayit_calismakartno">Çalışma Kartı No</label>
                    <b-form-input
                      type="text"
                      id="kayit_calismakartno"
                      v-model="kayit.CalismaKartiNo"
                      placeholder="Çalışma Kart No"
                      :state="kayit_calismakartino_state"
                    ></b-form-input>
                    <b-form-invalid-feedback :state="kayit_calismakartino_state"
                      >Çalışma kart numaranızı giriniz.</b-form-invalid-feedback
                    >
                  </div>
                </div>
                <div class="form-row">
                  <div class="col-md-6 mb-3">
                    <label>Konuştuğu Diller</label>
                    <div class="checkbox-animated">
                      <label
                        v-for="dil in diller"
                        :key="dil.value"
                        class="d-block"
                        :for="'dilId' + dil.value"
                      >
                        <input
                          class="checkbox_animated"
                          v-model="selectedDiller"
                          :value="dil.value"
                          :id="'dilId' + dil.value"
                          type="checkbox"
                        />
                        {{ dil.text }}
                      </label>
                    </div>
                    <b-form-invalid-feedback :state="kayit_dil_state"
                      >En az bir dil seçiniz</b-form-invalid-feedback
                    >
                  </div>
                  <div class="col-md-6 mb-3">
                    <label for="kayit_sira">Sıra</label>
                    <b-form-input
                      type="text"
                      id="kayit_sira"
                      v-model="kayit.Sira"
                      placeholder="Listeleme Sırası"
                    ></b-form-input>
                    <p>En büyük değer en başta gözükür</p>
                  </div>
                </div>
                <div class="form-row" v-if="id === undefined">
                  <div class="col-md-6 mb-3">
                    <label for="kayit_kullaniciadi">Kullanıcı Adı</label>
                    <b-form-input
                      type="text"
                      id="kayit_kullaniciadi"
                      v-model="kayit.KullaniciAdi"
                      placeholder="Kullanıcı Adı"
                      :state="kayit_kullaniciadi_state"
                    ></b-form-input>
                    <b-form-invalid-feedback :state="kayit_kullaniciadi_state"
                      >Ana mekan kullanıcısının kullanıcı adını
                      giriniz</b-form-invalid-feedback
                    >
                  </div>
                  <div class="col-md-6 mb-3">
                    <label for="kayit_sifre">Şifre</label>
                    <b-form-input
                      type="text"
                      id="kayit_sifre"
                      v-model="kayit.Sifre"
                      placeholder="Şifre"
                      :state="kayit_sifre_state"
                    ></b-form-input>
                    <b-form-invalid-feedback :state="kayit_sifre_state"
                      >En az 8 ile 20 karakter arasında rakam, küçük ve büyük
                      harf bulunan bir şifre giriniz</b-form-invalid-feedback
                    >
                  </div>
                </div>
                <router-link
                  :to="'/rehber/list'"
                  class="btn btn-light pull-left"
                  >Geri Dön</router-link
                >
                <b-button type="submit" class="pull-right" variant="primary"
                  >Kaydet</b-button
                >
              </b-form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import ImageCrop from "../../components/c_image_crop";

export default {
  data() {
    return {
      id: this.$route.params.id,

      pageTitle: "Yeni Rehber",

      breadLinks: this.$user.isRehber()
        ? []
        : [{ path: "/Rehber/list", title: "Rehberler", icon: "chevron-left" }],

      kayit: {
        AdSoyad: "",
        Ozgecmis: "",
        Telefon: "",
        GSM: "",
        Email: "",
        Durum: 30,
        Dil: [],
        Adres: "",
        TCKimlikNo: "",
        CalismaKartiNo: "",
        RuhsatName: "",
        Sira: 0,
        KullaniciAdi: "",
        Sifre: "",
      },

      selectedDiller: [],

      diller: [],

      kayit_durum_options: [
        { value: "0", text: "Yeni" },
        { value: "20", text: "Pasif" },
        { value: "30", text: "Onaylı" },
        { value: "100", text: "İptal" },
      ],

      isRehber: this.$user.isRehber(),

      editor: ClassicEditor,
    };
  },
  components: {
    ImageCrop,
  },
  mounted() {
    this.onDilList();

    let vm = this;

    if (this.id !== undefined) {
      if (vm.isRehber) vm.pageTitle = "Profil Güncelle";
      else vm.pageTitle = "Rehber Düzelt";

      this.$connection.get("/Rehberler/" + this.id, function (response) {
        if (response.Success) {
          vm.kayit = response.Data;

          for (let i = 0; i < vm.kayit.Dilleri.length; i++) {
            const element = vm.kayit.Dilleri[i];
            vm.selectedDiller.push(element.DilId);
          }
        } else {
          vm.$alert.messageWithTitle("Yeni Rehber", response.Message, "error");
        }
      });
    }
  },
  computed: {
    kayit_adsoyad_state() {
      if (this.kayit.AdSoyad == null || this.kayit.AdSoyad == "") return false;
      else return true;
    },
    kayit_gsm_state() {
      if (!this.$validationHelper.validGsm(this.kayit.GSM)) return false;
      else return true;
    },
    kayit_email_state() {
      if (!this.$validationHelper.validEmail(this.kayit.Email)) return false;
      else return true;
    },
    kayit_dil_state() {
      if (this.selectedDiller.length === 0) return false;
      else return true;
    },
    kayit_adres_state() {
      if (this.kayit.Adres == null || this.kayit.Adres == "") return false;
      else return true;
    },
    kayit_tckimlikno_state() {
      if (
        this.kayit.TCKimlikNo == null ||
        this.kayit.TCKimlikNo == "" ||
        this.kayit.TCKimlikNo.length !== 11
      )
        return false;
      else return true;
    },
    kayit_ruhsatname_state() {
      if (this.kayit.RuhsatName == null || this.kayit.RuhsatName == "")
        return false;
      else return true;
    },
    kayit_calismakartino_state() {
      if (this.kayit.CalismaKartiNo == null || this.kayit.CalismaKartiNo == "")
        return false;
      else return true;
    },
    kayit_kullaniciadi_state() {
      if (
        this.id === undefined &&
        (this.kayit.KullaniciAdi === null || this.kayit.KullaniciAdi === "")
      )
        return false;
      else return true;
    },
    kayit_sifre_state() {
      if (
        this.id === undefined &&
        !this.$validationHelper.validPassword(this.kayit.Sifre)
      )
        return false;
      else return true;
    },
  },
  methods: {
    onDilList() {
      let vm = this;

      this.$connection.get("/diller/select", function (response) {
        vm.diller = response;
      });
    },
    onSubmit(evt) {
      evt.preventDefault();

      let isValid =
        this.kayit_adsoyad_state &&
        this.kayit_gsm_state &&
        this.kayit_email_state &&
        this.kayit_adres_state &&
        this.kayit_tckimlikno_state &&
        this.kayit_ruhsatname_state &&
        this.kayit_calismakartino_state &&
        this.kayit_dil_state &&
        this.kayit_kullaniciadi_state &&
        this.kayit_sifre_state;

      if (isValid) {
        let vm = this;

        let url = "/Rehberler";
        let method = "post";
        let messageTitle = "Yeni Rehber";
        let yeniKayit = vm.id === undefined;
        if (!yeniKayit) {
          url += "/" + vm.id;
          method = "put";
          messageTitle = "Rehber Düzelt";
        }

        vm.kayit.Dil = "";
        for (let i = 0; i < vm.selectedDiller.length; i++) {
          const element = vm.selectedDiller[i];
          vm.kayit.Dil += element + ",";
        }
        if (vm.kayit.Dil !== "") {
          vm.kayit.Dil = vm.kayit.Dil.substring(0, vm.kayit.Dil.length - 1);
        }

        vm.$connection.post(
          url,
          vm.kayit,
          method,
          function (response) {
            if (response.Success) {
              vm.$alert.messageWithFunction(
                messageTitle,
                response.Message,
                "success",
                function () {
                  if (vm.isRehber) vm.$router.push("/rehber/profil");
                  else vm.$router.push("/rehber/list");
                }
              );
            }
          },
          function (error) {
            vm.$alert.messageWithTitle(messageTitle, error.Message, "error");
          }
        );
      }
    },
  },
};
</script>
