<template>
  <div>
    <Breadcrumbs main="Müzeler" :title="pageTitle" :links="breadLinks" />

    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <h5>Müze Kaydı</h5>
              <span
                >Yeni müze veya müze kaydında değişiklikler için aşağıdaki formu
                kullanabilirsiniz</span
              >
            </div>
            <div class="card-body">
              <b-form @submit="onCustomStyleSubmit">
                <div class="form-row">
                  <div class="col-md-12 mb-3">
                    <label for="kayit_adi">Müze Adı</label>
                    <b-form-input
                      type="text"
                      id="kayit_tanim"
                      v-model="kayit.Tanim"
                      :state="kayit_tanim_state"
                      placeholder="Müze Adı"
                    ></b-form-input>
                    <b-form-invalid-feedback :state="kayit_tanim_state"
                      >Müze adını giriniz</b-form-invalid-feedback
                    >
                  </div>
                </div>
                <div class="form-row">
                  <div class="col-md-12 mb-3">
                    <label for="kayit_aciklama">Detay</label>
                    <ckeditor
                      :editor="editor"
                      v-model="kayit.Detay"
                      :state="kayit_detay_state"
                    ></ckeditor>
                    <b-form-invalid-feedback :state="kayit_detay_state"
                      >Müze açıklamasını giriniz</b-form-invalid-feedback
                    >
                  </div>
                </div>
                <div class="form-row">
                  <div class="col-md-6 mb-3">
                    <label for="kayit_resim">Resim</label>
                    <div class="row">
                      <div class="col-md-9">
                        <b-form-file
                          class="form-control"
                          v-model="kayit.ResimFl"
                          plain
                        ></b-form-file>
                      </div>
                      <div class="col-md-3">
                        <div
                          class="avatar"
                          v-if="kayit.Resim !== '' && kayit.Resim !== undefined"
                        >
                          <img
                            class="img-40 rounded-circle"
                            :src="kayit.Resim"
                            alt="#"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6 mb-3">
                    <label></label>
                    <div class="checkbox checkbox-dark">
                      <b-form-checkbox
                        name="Aktif"
                        v-model="kayit.Aktif"
                        :state="kayit.Aktif"
                        >Aktif</b-form-checkbox
                      >
                    </div>
                  </div>
                </div>
                <div class="form-row">
                  <div class="col-md-6 mb-3">
                    <label for="kayit_tipi">Giriş Ücreti</label>
                    <b-form-input
                      type="text"
                      id="kayit_girisucreti"
                      v-model="kayit.GirisUcreti"
                      :state="kayit_girisucreti_state"
                      placeholder="Müze Adı"
                    ></b-form-input>
                    <b-form-invalid-feedback :state="kayit_girisucreti_state"
                      >Giriş ücretini giriniz</b-form-invalid-feedback
                    >
                  </div>
                  <div class="col-md-6 mb-3">
                    <label for="kayit_parabirimiid">Para Birimi</label>
                    <b-form-select
                      id="kayit_parabirimiid"
                      v-model="kayit.ParaBirimiId"
                      :options="kayit_parabirimi_options"
                      :state="kayit_parabirimiid_state"
                    ></b-form-select>
                    <b-form-invalid-feedback :state="kayit_parabirimiid_state"
                      >Parabirimini seçiniz</b-form-invalid-feedback
                    >
                  </div>
                </div>
                <div class="form-row">
                  <div class="col-md-12 mb-5">
                    <h4 class="f-w-600">
                      Yaş İndirim Oranları
                      <b-button
                        @click="defaultIndirimOranlariAta()"
                        class="pull-right"
                        variant="success"
                        >Standart İndirim Oranlarını Ata</b-button
                      >
                    </h4>
                    <table class="table">
                      <thead>
                        <tr class="bg-warning">
                          <th>
                            <b-form-select
                              v-model="indirimOrani.Yas"
                              :options="yas_options"
                            ></b-form-select>
                          </th>
                          <th>
                            <b-form-input
                              type="text"
                              v-model="indirimOrani.Oran"
                              placeholder="İndirim Oranı"
                            ></b-form-input>
                          </th>
                          <th>
                            <b-button
                              type="button"
                              @click="indirimOraniEkle()"
                              variant="info"
                              >Ekle</b-button
                            >
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-if="kayit.Indirimler.length === 0">
                          <td colspan="3" class="text-center">
                            Bu Müze İçin Henüz Yaş İndirimi Tanımlanmamıştır
                          </td>
                        </tr>
                        <tr
                          v-for="row in kayit.Indirimler"
                          v-bind:key="row.YasMin"
                        >
                          <td>
                            <b-form-select
                              v-model="row.Yas"
                              :options="yas_options"
                            ></b-form-select>
                          </td>
                          <td>
                            <b-form-input
                              type="text"
                              v-model="row.Oran"
                              placeholder="İndirim Oranı"
                            ></b-form-input>
                          </td>
                          <td>
                            <b-button
                              type="button"
                              @click="indirimOraniKaldir(row)"
                              variant="danger"
                              >Kaldır</b-button
                            >
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div class="form-row">
                  <div class="col-md-12 mb-5">
                    <h4 class="f-w-600">
                      Çalışma Saatleri
                      <b-button
                        @click="defaultCalismaSaatleriAta()"
                        class="pull-right"
                        variant="success"
                        >Standart Çalışma Saatleri Ata</b-button
                      >
                    </h4>
                    <table class="table">
                      <thead>
                        <tr class="bg-info">
                          <th>
                            <b-form-input
                              type="text"
                              v-model="calismaSaati.GunIsmi"
                              placeholder="Gün"
                            ></b-form-input>
                          </th>
                          <th>
                            <b-form-input
                              type="text"
                              v-model="calismaSaati.BaslangicSaat"
                              placeholder="Başlangıç Saati"
                            ></b-form-input>
                          </th>
                          <th>
                            <b-form-input
                              type="text"
                              v-model="calismaSaati.BitisSaat"
                              placeholder="Bitiş Saati"
                            ></b-form-input>
                          </th>
                          <th>
                            <div class="checkbox">
                              <b-form-checkbox v-model="calismaSaati.Acik"
                                >Açık</b-form-checkbox
                              >
                            </div>
                          </th>
                          <th>
                            <b-button
                              type="button"
                              @click="calismaSaatiEkle()"
                              variant="warning"
                              >Ekle</b-button
                            >
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-if="kayit.CalismaSaatleri.length === 0">
                          <td colspan="3" class="text-center">
                            Bu Müze İçin Çalışma Saati Tanımlanmamıştır
                          </td>
                        </tr>
                        <tr
                          v-for="row in kayit.CalismaSaatleri"
                          v-bind:key="row.GunIsmi"
                        >
                          <td>
                            <b-form-input
                              type="text"
                              v-model="row.GunIsmi"
                              placeholder="Gün İsmi"
                            ></b-form-input>
                          </td>
                          <td>
                            <b-form-input
                              type="text"
                              v-model="row.BaslangicSaat"
                              placeholder="Başlangıç Saati"
                            ></b-form-input>
                          </td>
                          <td>
                            <b-form-input
                              type="text"
                              v-model="row.BitisSaat"
                              placeholder="Bitiş Saati"
                            ></b-form-input>
                          </td>
                          <td>
                            <div class="checkbox checkbox-dark">
                              <b-form-checkbox
                                v-model="row.Acik"
                                :checked="row.Acik"
                                >Açık</b-form-checkbox
                              >
                            </div>
                          </td>
                          <td>
                            <b-button
                              type="button"
                              @click="calismaSaatiKaldir(row)"
                              variant="danger"
                              >Kaldır</b-button
                            >
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <router-link :to="'/muze/list'" class="btn btn-light pull-left"
                  >Geri Dön</router-link
                >
                <b-button type="submit" class="pull-right" variant="primary"
                  >Kaydet</b-button
                >
              </b-form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

export default {
  data() {
    return {
      id: this.$route.params.id,

      pageTitle: "Yeni Müze",

      breadLinks: [
        { path: "/muze/list", title: "Müzeler", icon: "chevron-left" },
      ],

      kayit: {
        Tanim: "",
        Detay: "",
        ResimFl: null,
        Aktif: true,
        GirisUcreti: 0,
        ParaBirimiId: 0,
        Indirimler: [],
        CalismaSaatleri: [],
      },

      kayit_parabirimi_options: [{ value: "0", text: "Seçiniz" }],

      indirimOrani: {
        Yas: 0,
        Oran: null,
      },

      yas_options: [],

      defaulIndirimOranlari: [
        {
          Yas: 0,
          Oran: 50,
        },
        {
          Yas: 1,
          Oran: 25,
        },
      ],

      calismaSaati: {
        GunIsmi: null,
        BaslangicSaat: null,
        BitisSaat: null,
        Acik: true,
      },

      defaulCalismaSaatleri: [
        {
          GunIsmi: "Pazartesi",
          BaslangicSaat: "08:00",
          BitisSaat: "17:00",
          Acik: false,
        },
        {
          GunIsmi: "Salı",
          BaslangicSaat: "08:00",
          BitisSaat: "17:00",
          Acik: true,
        },
        {
          GunIsmi: "Çarşamba",
          BaslangicSaat: "08:00",
          BitisSaat: "17:00",
          Acik: true,
        },
        {
          GunIsmi: "Perşembe",
          BaslangicSaat: "08:00",
          BitisSaat: "17:00",
          Acik: true,
        },
        {
          GunIsmi: "Cuma",
          BaslangicSaat: "08:00",
          BitisSaat: "17:00",
          Acik: true,
        },
        {
          GunIsmi: "Cumartesi",
          BaslangicSaat: "08:00",
          BitisSaat: "17:00",
          Acik: true,
        },
        {
          GunIsmi: "Pazar",
          BaslangicSaat: "08:00",
          BitisSaat: "17:00",
          Acik: true,
        },
      ],

      editor: ClassicEditor,
    };
  },
  components: {},
  mounted() {
    this.onYasList();
    this.onParaBirimiList();

    let vm = this;

    if (this.id !== undefined) {
      vm.pageTitle = "Müze Düzelt";

      this.$connection.get("/muzeler/" + this.id, function (response) {
        if (response.Success) {
          vm.kayit = response.Data;
        } else {
          vm.$alert.messageWithTitle("Yeni Müze", response.Message, "error");
        }
      });
    }
  },
  computed: {
    kayit_tanim_state() {
      if (this.kayit.Tanim == null || this.kayit.Tanim == "") return false;
      else return true;
    },
    kayit_detay_state() {
      if (this.kayit.Detay == null || this.kayit.Detay == "") return false;
      else return true;
    },
    kayit_girisucreti_state() {
      if (this.kayit.GirisUcreti == "" || this.kayit.GirisUcreti == "0")
        return false;
      else return true;
    },
    kayit_parabirimiid_state() {
      if (this.kayit.ParaBirimiId == "0") return false;
      else return true;
    },
  },
  methods: {
    onYasList() {
      let vm = this;

      this.$connection.get("/parametreler/yaslar", function (response) {
        vm.yas_options = response;
      });
    },
    onParaBirimiList() {
      let vm = this;

      this.$connection.get("/parabirimleri/select", function (response) {
        vm.kayit_parabirimi_options =
          vm.kayit_parabirimi_options.concat(response);
      });
    },
    defaultIndirimOranlariAta() {
      this.kayit.Indirimler = structuredClone(this.defaulIndirimOranlari);
    },
    indirimOraniEkle() {
      if (this.indirimOrani.Yas === null || this.indirimOrani.Oran === null) {
        this.$alert.messageWithTitle(
          "Eksik Alan",
          "Yaş ve indirim oranı alanlarını doldurunuz.",
          "error"
        );
        return;
      }

      this.indirimOrani.Oran = parseInt(this.indirimOrani.Oran);
      this.kayit.Indirimler.push(this.indirimOrani);
      this.indirimOrani = {
        Yas: 0,
        Oran: null,
      };
    },
    indirimOraniKaldir(indirim) {
      this.$listHelper.removeItem(this.kayit.Indirimler, indirim);
    },
    defaultCalismaSaatleriAta() {
      this.kayit.CalismaSaatleri = structuredClone(this.defaulCalismaSaatleri);
    },
    calismaSaatiEkle() {
      if (
        this.calismaSaati.GunIsmi === null ||
        this.calismaSaati.BaslangicSaat === null ||
        this.calismaSaati.BitisSaat === null
      ) {
        this.$alert.messageWithTitle(
          "Eksik Alan",
          "Gün ismi, başlangıç ve bitiş saati alanlarını doldurunuz.",
          "error"
        );
        return;
      }

      this.kayit.CalismaSaatleri.push(this.calismaSaati);
      this.calismaSaati = {
        GunIsmi: null,
        BaslangicSaat: null,
        BitisSaat: null,
        Acik: true,
      };
    },
    calismaSaatiKaldir(calismaSaati) {
      this.$listHelper.removeItem(this.kayit.CalismaSaatleri, calismaSaati);
    },
    onCustomStyleSubmit(evt) {
      evt.preventDefault();

      let isValid =
        this.kayit_tanim_state &&
        this.kayit_detay_state &&
        this.kayit_girisucreti_state &&
        this.kayit_parabirimiid_state;

      if (isValid) {
        let vm = this;

        let url = "/muzeler";
        let method = "post";
        let messageTitle = "Yeni Müze";
        let yeniKayit = vm.id === undefined;
        if (!yeniKayit) {
          url += "/" + vm.id;
          method = "put";
          messageTitle = "Müze Düzelt";
        }

        for (let i = 0; i < vm.kayit.Indirimler.length; i++) {
          const element = vm.kayit.Indirimler[i];
          element.Oran = parseInt(element.Oran);
        }

        vm.$connection.postData(
          url,
          vm.kayit,
          method,
          function (response) {
            if (response.Success) {
              vm.$alert.messageWithFunction(
                messageTitle,
                response.Message,
                "success",
                function () {
                  vm.$router.push("/muze/list");
                }
              );
            }
          },
          function (error) {
            vm.$alert.messageWithTitle(messageTitle, error.Message, "error");
          }
        );
      }
    },
  },
};
</script>
