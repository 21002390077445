var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('Breadcrumbs',{attrs:{"main":"Acente","title":"Kontrol Paneli"}}),_c('div',{staticClass:"container-fluid"},[(_vm.profilDurumu !== '')?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xl-12"},[_c('b-alert',{attrs:{"show":"","variant":"danger"}},[_c('h4',{staticClass:"alert-heading"},[_vm._v("Profil Durumunuz")]),_c('p',[_vm._v(" Profilinizde bazı eksiklikler bulduk. Bunları doldurduğunuzda rezervasyon işlemlerinizi kolaylaştıracaktır. ")]),_c('hr'),_c('p',{staticClass:"mb-3",domProps:{"innerHTML":_vm._s(_vm.profilDurumu)}}),_c('p',[_c('router-link',{staticClass:"btn btn-dark",attrs:{"to":"/acente/profil"}},[_vm._v("Güncelle")]),_vm._v(" Profil sayfanızdan giriş yapıp Düzelt butonuna basarak gerekli güncellemeleri yapabilirsiniz. ")],1)])],1)]):_vm._e(),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('ul',{staticClass:"nav nav-tabs border-tab tabs-scoial",attrs:{"id":"top-tab","role":"tablist"}},[_c('li',{staticClass:"nav-item",on:{"click":function($event){return _vm.changetab('rezervasyon')}}},[_c('a',{staticClass:"nav-link",class:[
                { 'show active': _vm.showtab == 'rezervasyon' },
                'shadow-lg p-25 shadow-showcase',
              ],attrs:{"id":"top-rezervasyon","data-toggle":"tab","role":"tab","aria-controls":"rezervasyon","aria-selected":"true"}},[_vm._v("Yeni Tur Rezervasyonu")])]),_c('li',{staticClass:"nav-item",on:{"click":function($event){return _vm.changetab('dashboard')}}},[_c('a',{staticClass:"nav-link",class:[
                { 'show active': _vm.showtab == 'dashboard' },
                'shadow-lg p-25 shadow-showcase',
              ],attrs:{"id":"top-dashboard","data-toggle":"tab","role":"tab","aria-controls":"dashboard","aria-selected":"false"}},[_vm._v("Kontrol Paneli "),_c('b-badge',{attrs:{"variant":"primary"}},[_vm._v("Önümüzdeki 7 Gün "+_vm._s(_vm.turRezervasyonSayi)+" Rezervasyon Var")])],1)])])])]),_c('div',{staticClass:"tab-content",attrs:{"id":"top-tabContent"}},[_c('div',{staticClass:"tab-pane fade",class:{ 'show active': _vm.showtab == 'rezervasyon' },attrs:{"id":"rezervasyon","role":"tabpanel","aria-labelledby":"rezervasyon"}},[_c('TurRezervasyonForm',{attrs:{"acenteId":_vm.acenteId},on:{"onComplete":_vm.onComplete}})],1),_c('div',{staticClass:"tab-pane fade",class:{ 'show active': _vm.showtab == 'dashboard' },attrs:{"id":"dashboard","role":"tabpanel","aria-labelledby":"dashboard"}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xl-12"},[_c('TurRezervasyonList')],1)])])])]),_c('DuyuruGosterimModal')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }