<template>
  <div>
    <Breadcrumbs
      main="Rehberler"
      title="Müsaitlik Takvimi"
      :links="breadLinks"
    />

    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <b-card
            header-tag="div"
            header-bg-variant="primary"
            class="card-absolute"
          >
            <h5 slot="header">Müsaitlik Takvimi</h5>
            <b-card-body class="p-1">
              <RehberTakvim
                :rehberId="rehber.Id"
                v-if="rehber.Id !== undefined"
              />
            </b-card-body>
          </b-card>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import RehberTakvim from "@/components/c_rehber_takvim.vue";

export default {
  data() {
    return {
      breadLinks: [],

      rehber: {},
    };
  },
  mounted() {
    this.onDetay();
  },
  components: {
    RehberTakvim,
  },
  methods: {
    onDetay() {
      let vm = this;

      this.$connection.get("/rehberler/profil", function (response) {
        if (response.Success) {
          vm.rehber = response.Data;
        } else {
          vm.$alert.messageWithTitle("Rehber", response.Message, "error");
        }
      });
    },
  },
};
</script>
