<template>
  <div>
    <b-modal
      id="duyuruGosterim"
      centered
      :title="duyuru.Baslik"
      @ok="kapatSubmit"
      :hide-header-close="true"
      :ok-only="true"
      :scrollable="true"
      :no-close-on-esc="true"
      :no-close-on-backdrop="true"
      ok-title="Kapat"
      class="theme-modal"
    >
      <p class="duyuru-popup" v-html="duyuru.Detay"></p>
    </b-modal>
  </div>
</template>
<script>
export default {
  data() {
    return {
      duyuru: {
        Baslik: "",
        Detay: "",
        Id: "",
      },
    };
  },
  mounted() {
    this.load();
  },
  methods: {
    load() {
      let vm = this;

      this.$connection.get(
        "/duyurular/gosterim",
        function (response) {
          if (response.Success) {
            vm.duyuru = response.Data;
            vm.$bvModal.show("duyuruGosterim");
          }
        },
        function (response) {
          if (!response.Success) {
            //Bir şey yapma
          }
        }
      );
    },
    kapatSubmit() {
      let vm = this;
      this.$connection.post(
        "/duyurular/okundu/" + this.duyuru.Id,
        {},
        "put",
        function (response) {
          //vm.$bvModal.hide('duyuruGosterim');
        }
      );
    },
  },
};
</script>
