<template>
  <div>
    <div class="row">
      <div class="col-md-6">
        <b-form @submit="filtreSubmit" inline class="mb-3">
          <label class="sr-only" for="filtre_tip">Tip</label>
          <b-form-select id="filtre_tip" v-model="filtre.Tipi" :options="tipler"
            class="mb-2 mr-sm-2 mb-sm-0"></b-form-select>

          <label class="sr-only" for="filtre_baslangictarihi">Başlangıç Tarihi</label>
          <datepicker :language="tr" input-class="datepicker-here form-control digits mb-2 mr-sm-2 mb-sm-0"
            id="filtre_baslangictarihi" v-model="filtre.BaslangicTarihi" :clear-button="true"
            clear-button-icon="fa fa-times" placeholder="Başlangıç Tarihi" :format="date_format"></datepicker>

          <label class="sr-only" for="filtre_bitistarihi">Bitiş Tarihi</label>
          <datepicker :language="tr" input-class="datepicker-here form-control digits mb-2 mr-sm-2 mb-sm-0"
            id="filtre_bitistarihi" v-model="filtre.BitisTarihi" :clear-button="true" clear-button-icon="fa fa-times"
            placeholder="Bitiş Tarihi" :format="date_format"></datepicker>

          <b-button type="submit" variant="primary">Listele</b-button>
        </b-form>
      </div>
      <div class="col-md-3">
        <h5>Toplam Borç :
          <b-badge variant="info"><c-money-cell :fiyat="acente.ToplamBorc" simge="TL"></c-money-cell></b-badge>
        </h5>
      </div>
      <div class="col-md-3">
        <b-button-group class="pull-right">
          <b-button variant="info" @click="excel()">
            <i class="fa fa-file-excel-o"></i>
            Excele Çıkart
          </b-button>
          <b-button v-b-modal.folyoEkle variant="success" v-if="noEdit == false">
            <div class="media">
              <feather type="plus"></feather>
              <div class="media-body align-self-center">Ekle</div>
            </div>
          </b-button>
        </b-button-group>
      </div>
    </div>

    <b-table striped hover :fields="folyoFields" :items="folyolar">
      <template #cell(Islem)="row">
        <b-btn-group v-if="noEdit == false">
          <b-button size="xs" @click="duzelt(row.item)" variant="info">Düzelt</b-button>
          <b-button size="xs" @click="sil(row.item)" variant="danger">Sil</b-button>
        </b-btn-group>
      </template>
      <template #cell(Tutar)="row">
        <c-money-cell :fiyat="row.item.Tutar" :simge="row.item.ParaBirimi"></c-money-cell>
      </template>
      <template #cell(TurRezervasyonKodu)="row">
        <a :href="'/turRezervasyon/detay/' + row.item.TurRezervasyonId" target="_blank">{{ row.item.TurRezervasyonKodu
          }}</a>
      </template>
      <template #cell(Tarih)="row">
        <c-tarih-cell :kayitTarihi="row.item.Tarih" :guncelTarih="row.item.GuncelTarih"
          :rowKey="row.item.Id"></c-tarih-cell>
      </template>
    </b-table>

    <b-modal id="folyoEkle" centered title="Folyo Kaydı" @ok="kaydetSubmit" ok-title="Kaydet" cancel-title="Kapat"
      class="theme-modal" v-if="noEdit == false">
      <b-form @submit="kaydetSubmit">
        <div class="form-row">
          <div class="col-md-12 mb-3">
            <label for="kayit_tip">Tipi</label>
            <b-form-select id="kayit_tip" v-model="kayit.Tipi" :options="tipler"
              :state="kayit_tipi_state"></b-form-select>
            <b-form-invalid-feedback :state="kayit_tipi_state">Tipi seçiniz.</b-form-invalid-feedback>
          </div>
        </div>
        <div class="form-row">
          <div class="col-md-12 mb-3">
            <label for="kayit_tarih">Tarih</label>
            <datepicker :language="tr" input-class="datepicker-here form-control digits" id="kayit_tarih"
              v-model="kayit.Tarih" placeholder="Tarih" :format="date_format" :state="kayit_tarih_state">
            </datepicker>
            <b-form-invalid-feedback :state="kayit_tarih_state">Tarih giriniz.</b-form-invalid-feedback>
          </div>
        </div>
        <div class="form-row">
          <div class="col-md-12 mb-3">
            <label for="kayit_tutar">Tutar</label>
            <b-form-input id="kayit_tutar" v-model="kayit.Tutar" :state="kayit_tutar_state"></b-form-input>
            <b-form-invalid-feedback :state="kayit_tutar_state">Tutarı giriniz.</b-form-invalid-feedback>
          </div>
        </div>
        <div class="form-row">
          <div class="col-md-12 mb-3">
            <label for="kayit_parabirimi">Parabirimi</label>
            <b-form-select id="kayit_parabirimi" v-model="kayit.ParaBirimiId" :options="parabirimleri"
              :state="kayit_parabirimi_state"></b-form-select>
            <b-form-invalid-feedback :state="kayit_parabirimi_state">Parabirimini seçiniz.</b-form-invalid-feedback>
          </div>
        </div>
        <div class="form-row">
          <div class="col-md-12 mb-3">
            <label for="kayit_aciklama">Açıklama</label>
            <b-form-textarea id="kayit_aciklama" v-model="kayit.Aciklama"
              :state="kayit_aciklama_state"></b-form-textarea>
            <b-form-invalid-feedback :state="kayit_aciklama_state">Açıklama giriniz.</b-form-invalid-feedback>
          </div>
        </div>
      </b-form>
    </b-modal>
  </div>
</template>
<script>
import c_money_cell from "./c_money_cell.vue";
import Datepicker from "vuejs-datepicker";
import { tr } from "vuejs-datepicker/dist/locale";

export default {
  components: { c_money_cell },
  props: {
    acente: {},
    noEdit: false,
  },
  data() {
    return {
      folyoFields: this.noEdit
        ? [
          { key: "Tarih", label: "Tarih", sortable: false },
          { key: "TipiStr", label: "Tipi", sortable: false },
          { key: "Tutar", label: "Tutar", sortable: false },
          { key: "Aciklama", label: "Aciklama", sortable: false },
          {
            key: "TurRezervasyonKodu",
            label: "Tur. Rez. Kodu",
            sortable: false,
          },
        ]
        : [
          { key: "Islem", label: "İşlemler", sortable: false },
          { key: "Tarih", label: "Tarih", sortable: false },
          { key: "TipiStr", label: "Tipi", sortable: false },
          { key: "Tutar", label: "Tutar", sortable: false },
          { key: "Aciklama", label: "Aciklama", sortable: false },
          {
            key: "TurRezervasyonKodu",
            label: "Tur. Rez. Kodu",
            sortable: false,
          },
          { key: "KullaniciAdi", label: "Kullanıcı", sortable: false },
        ],

      date_format: "dd.MM.yyyy",
      tr: tr,

      filtre: {
        Tipi: null,
        BaslangicTarihi: null,
        BitisTarihi: null,
      },

      tipler: [
        { value: null, text: "Tipler" },
        { value: "0", text: "Genel" },
        { value: "20", text: "Ödeme" },
      ],

      folyolar: [],

      kayit: {
        Id: 0,
        AcenteId: 0,
        Tipi: null,
        ParaBirimiId: null,
        Tutar: null,
        Aciklama: "",
        Tarih: null,
      },

      parabirimleri: [{ value: null, text: "Seçiniz" }],
    };
  },
  components: {
    Datepicker,
  },
  mounted() {
    let baslangicTarihi = new Date();
    let bitisTarihi = new Date();
    baslangicTarihi.setDate(bitisTarihi.getDate() - 15);
    bitisTarihi.setDate(bitisTarihi.getDate() + 15);
    this.filtre.BaslangicTarihi = baslangicTarihi;
    this.filtre.BitisTarihi = bitisTarihi;

    this.paraBirimiList();
    this.list();
  },
  computed: {
    kayit_tipi_state() {
      if (this.kayit.Tipi == null) return false;
      else return true;
    },
    kayit_parabirimi_state() {
      if (this.kayit.ParaBirimiId == null || this.kayit.ParaBirimiId == "")
        return false;
      else return true;
    },
    kayit_tarih_state() {
      if (this.kayit.Tarih == null || this.kayit.Tarih == "") return false;
      else return true;
    },
    kayit_tutar_state() {
      if (this.kayit.Tutar == null) return false;
      else return true;
    },
    kayit_aciklama_state() {
      if (this.kayit.Aciklama == null || this.kayit.Aciklama == "")
        return false;
      else return true;
    },
  },
  methods: {
    filtreSubmit(event) {
      event.preventDefault();
      this.list();
    },
    paraBirimiList() {
      let vm = this;

      this.$connection.get("/paraBirimleri/select", function (response) {
        vm.parabirimleri = vm.parabirimleri.concat(response);
      });
    },
    list() {
      let vm = this;

      this.$connection.post(
        "/acenteler/folyo/filtre/" + this.acente.Id,
        vm.filtre,
        "post",
        function (response) {
          vm.folyolar = response;
        }
      );
    },
    sil(folyo) {
      let vm = this;
      this.$alert.confirm(
        "Folyo Sil",
        "Kayıdı silmek istediğinize emin misiniz?",
        "error",
        function () {
          vm.$connection.delete("/acenteler/folyo/" + folyo.Id, function () {
            vm.$alert.messageWithFunction(
              "Folyo Sil",
              "Kayıt silindi.",
              "success",
              function () {
                vm.$listHelper.removeItem(vm.folyolar, folyo);
              }
            );
          });
        }
      );
    },
    duzelt(folyo) {
      this.kayit = {
        Id: folyo.Id,
        AcenteId: this.acente.Id,
        Tipi: folyo.Tipi,
        ParaBirimiId: folyo.ParaBirimiId,
        Tutar: folyo.Tutar,
        Aciklama: folyo.Aciklama,
        Tarih: folyo.Tarih,
      };
      this.$bvModal.show("folyoEkle");
    },
    kaydetSubmit(event) {
      event.preventDefault();

      let isValid =
        this.kayit_tipi_state &&
        this.kayit_parabirimi_state &&
        this.kayit_tarih_state &&
        this.kayit_tutar_state &&
        this.kayit_aciklama_state;

      if (isValid) {
        let vm = this;

        let url = "/acenteler/folyo";
        let method = "post";
        let messageTitle = "Yeni Folyo";
        let yeniKayit = vm.kayit.Id === 0;
        if (!yeniKayit) {
          url += "/" + vm.kayit.Id;
          method = "put";
          messageTitle = "Folyo Düzelt";
        }

        vm.kayit.AcenteId = vm.acente.Id;

        vm.$connection.post(
          url,
          vm.kayit,
          method,
          function (response) {
            if (response.Success) {
              vm.$alert.messageWithFunction(
                messageTitle,
                response.Message,
                "success",
                function () {
                  vm.list();

                  vm.kayit = {
                    Id: 0,
                    AcenteId: 0,
                    Tipi: null,
                    ParaBirimiId: null,
                    Tutar: null,
                    Aciklama: "",
                    Tarih: null,
                  };

                  vm.$emit("onKayitEklendi");
                }
              );
            }
          },
          function (error) {
            vm.$alert.messageWithTitle(messageTitle, error.Message, "error");
          }
        );
      }
    },
    excel() {
      let vm = this;

      this.$connection.postGetFile(
        "/acenteler/folyo/excel/" + this.acente.Id,
        vm.filtre,
        "post",
        function (response) {
          const url = window.URL.createObjectURL(new Blob([response]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "folyo.xlsx");
          document.body.appendChild(link);
          link.click();
        }
      );
    },
  },
};
</script>
