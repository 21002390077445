<template>
  <div>
    <Breadcrumbs
      main="Tur Rezervasyonu"
      :title="pageTitle"
      :links="breadLinks"
    />

    <div class="container-fluid tur-rezervasyon-duzelt">
      <div class="row">
        <div class="col-md-4 mb-3">
          <b-input-group size="lg" prepend="Arama">
            <b-form-input
              v-model="filtre"
              @input="(event) => aracFiltrele(event)"
            ></b-form-input>
          </b-input-group>
        </div>
      </div>

      <div class="row tur-rez-form-arac">
        <div
          class="col-md-2 col-md-2-special"
          :if="araclar !== null"
          v-for="arac in araclar"
          :key="arac.Id"
        >
          <b-card
            header-tag="div"
            header-class="p-3"
            :bg-variant="arac.Id === kayit.AracId ? 'dark' : 'light'"
            :header-bg-variant="arac.Id === kayit.AracId ? 'primary' : 'light'"
            body-class="p-1"
          >
            <h5 slot="header">
              <b-link
                :class="arac.Id === kayit.AracId ? 'text-white' : 'text-dark'"
                @click="aracSec(arac)"
              >
                {{ arac.SoforAdi }}
              </b-link>
              <div class="pull-right">
                <b-badge v-if="arac.isWifi" variant="warning">
                  <i
                    class="fa fa-wifi"
                    aria-hidden="true"
                    style="font-size: 14px"
                  ></i>
                </b-badge>
                <b-badge v-if="arac.isAracTakip" variant="warning">
                  <i
                    class="fa fa-location-arrow"
                    aria-hidden="true"
                    style="font-size: 14px"
                  ></i>
                </b-badge>
              </div>
            </h5>
            <div class="product-box">
              <div class="product-img">
                <b-carousel
                  :id="'carousel-' + arac.Id"
                  :interval="4000"
                  controls
                  indicators
                  background="#ababab"
                  img-width="1024"
                  img-height="480"
                  style="text-shadow: 1px 1px 2px #333"
                >
                  <b-carousel-slide
                    v-for="(resim, index) in arac.Resimler"
                    :key="index"
                    :img-src="resim"
                  ></b-carousel-slide>
                </b-carousel>
              </div>
              <div class="product-details">
                <b-button
                  class="primary"
                  :disabled="arac.Id === kayit.AracId"
                  @click="aracSec(arac)"
                >
                  Aracı Seç
                </b-button>
              </div>
            </div>
          </b-card>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      id: this.$route.params.id,

      pageTitle: "Rezervasyon Araç Değiştir",

      breadLinks: [
        {
          path: "/turRezervasyon/detay/" + this.$route.params.id,
          title: "Rezervasyon Detayı",
          icon: "chevron-left",
        },
      ],

      kayit: {},

      coreAraclar: [],
      araclar: [],
      activeArac: {},

      acenteId: "",

      filtre: "",
    };
  },
  mounted() {
    this.onDetay();
  },
  components: {},
  methods: {
    aracFiltrele(event) {
      let kelime = event.toUpperCase().replaceAll("I", "İ");
      console.log(kelime);
      var sonuc = this.coreAraclar.filter((item) => {
        return (
          item.Marka.toUpperCase().replaceAll("I", "İ").indexOf(kelime) > -1 ||
          item.Model.toUpperCase().replaceAll("I", "İ").indexOf(kelime) > -1 ||
          item.Plaka.toUpperCase().replaceAll("I", "İ").indexOf(kelime) > -1 ||
          item.SoforAdi.toUpperCase().replaceAll("I", "İ").indexOf(kelime) > -1
        );
      });

      this.araclar = sonuc;
    },
    onDetay() {
      let vm = this;

      this.$connection.get(
        "/turRezervasyonlari/" + this.id,
        function (response) {
          if (response.Success) {
            vm.kayit = response.Data;
            vm.onAraclarList(vm.formatTarih(vm.kayit.Tarih));
          } else {
            vm.$alert.messageWithTitle("Tur Detay", response.Message, "error");
          }
        }
      );
    },
    formatTarih(tarih) {
      return this.$moment(String(tarih)).format("YYYY-MM-DD");
    },
    onAraclarList(turTarihi) {
      let vm = this;

      let url =
        "/araclar/select/" +
        turTarihi +
        "/" +
        this.kayit.AcenteId +
        "?aracId=" +
        this.kayit.AracId;
      this.$connection.get(url, function (response) {
        vm.coreAraclar = response;
        vm.araclar = response;

        let aracs = vm.araclar.filter((item) => {
          return item.Id === vm.kayit.AracId;
        });
        if (aracs.length > 0) vm.activeArac = aracs[0];
      });
    },
    aracSec(arac) {
      let vm = this;
      this.$alert.confirm(
        "Araç Değiştir",
        "Rezervasyonun aracı değiştirilecektir. Emin misiniz?",
        "warning",
        function () {
          vm.$connection.post(
            "/turRezervasyonlari/" + vm.kayit.Id + "/arac/" + arac.EncId,
            {},
            "put",
            function () {
              vm.kayit.AracId = arac.Id;
              vm.kayit.EncAracId = arac.EncId;
              vm.activeArac = arac;

              vm.$alert.messageWithFunction(
                "Araç Değiştir",
                "Rezervasyon aracı değiştirildi.",
                "success",
                function () {
                  vm.$router.push("/turRezervasyon/detay/" + vm.kayit.Id);
                }
              );
            }
          );
        }
      );
    },
  },
};
</script>
