<template>
  <div>
    <Breadcrumbs main="Dashboard" title="Kontrol Paneli" />
    <div class="container-fluid">
      <div class="row">
        <div class="col-xl-12">
          <TurRezervasyonList />
        </div>
      </div>
      <div class="row">
        <div class="col-xl-4">
          <AcenteOnayBekleyenList />
        </div>
        <div class="col-xl-4">
          <RehberOnayBekleyenList />
        </div>
        <div class="col-xl-4">
          <AracOnayBekleyenList />
        </div>
      </div>
      <div class="row">
        <div class="col-xl-4">
          <AracResimOnayBekleyenList />
        </div>
      </div>
    </div>

    <DuyuruGosterimModal />
  </div>
</template>
<script>
import TurRezervasyonList from "@/components/c_tur_rezervasyon_dashboard_yonetim.vue";
import AcenteOnayBekleyenList from "@/components/c_dashboard_acente_onay_bekleyenler.vue";
import RehberOnayBekleyenList from "@/components/c_dashboard_rehber_onay_bekleyenler.vue";
import AracOnayBekleyenList from "@/components/c_dashboard_arac_onay_bekleyenler.vue";
import AracResimOnayBekleyenList from "@/components/c_dashboard_aracResim_onay_bekleyenler.vue";
import DuyuruGosterimModal from "@/components/c_duyuru_gosterim";

export default {
  data() {
    return {};
  },
  components: {
    TurRezervasyonList,
    AcenteOnayBekleyenList,
    RehberOnayBekleyenList,
    AracOnayBekleyenList,
    AracResimOnayBekleyenList,
    DuyuruGosterimModal,
  },
  mounted() {
    let user = this.$user.getUser();
    if (user.Kullanici.RedirectUrl !== "/dashboard/default")
      this.$router.push(user.Kullanici.RedirectUrl);
  },
};
</script>
